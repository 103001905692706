import React from 'react'
import { Link } from 'react-router-dom'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutube,
  FaInstagram,
} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='footer p-10 bg-[#F2F2F2] text-base-content w-full mt-2 '>
      <div className='flex flex-col items-start justify-start w-52'>
        <span className='footer-title'>Brief Detail of Company</span>
        <p className='mb-0'>
          Online & in-store, we deliver quality products tailored to your needs.
          Shop now for timely service.
        </p>
        <ul className='flex flex-row'>
          <li>
            <a href='https://www.facebook.com/exploretcmbd/' target='_blank'>
              <FaFacebookSquare size={30} />
            </a>
          </li>
          <li className='ml-2'>
            <a
              href='https://www.youtube.com/channel/UCP7OOO7jOgQgl16zbmvFpfg'
              target='_blank'
            >
              <FaYoutube size={30} />
            </a>
          </li>
          <li className='ml-2'>
            <a
              href='https://www.instagram.com/thecommunitymagasin/'
              target='_blank'
            >
              <FaInstagram size={30} />
            </a>
          </li>
        </ul>
      </div>
      <div>
        <span className='footer-title'>Company</span>
        <a className='link link-hover'>About us</a>
        <Link to={'/contact-us'} target='_top'>
          <a className='link link-hover'>Contact Us</a>
        </Link>

        <Link to={'/career'} target='_top'>
          <a className='link link-hover'>Career</a>
        </Link>
        {/* <a className='link link-hover'>Press kit</a> */}
      </div>
      <div>
        <span className='footer-title'>Legal</span>
        <Link to={'/terms-of-use'} target='_top'>
          <a className='link link-hover'>Terms of use</a>
        </Link>
        <Link to={'/privacy-policy'} target='_top'>
          <a className='link link-hover'>Privacy policy</a>
        </Link>
        <Link to={'/cookie-policy'} target='_top'>
          <a className='link link-hover'>Cookie policy</a>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
