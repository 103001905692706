import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Input } from 'react-daisyui'
import { Form, Link } from 'react-router-dom'
import { useCustomerByIdEcomQuery } from '../../../services/customerApi'
import BASE_URL from '../../../Utlity/Base_url'
import PHOTO_URL from '../../../Utlity/Photo_url'
import ChangePassword from '../Modal/ChangePassword/ChangePassword'

function UserDashboardSidebar({ customer }) {
  const [fileName, setFileName] = useState('Upload Category Photo')
  const [file, setFile] = useState({})
  const [uploadFile, setUploadedFile] = useState({})
  const [customerPhoto, setCustomerPhoto] = useState(
    'https://randomuser.me/api/portraits/men/88.jpg'
  )
  const { data, isSuccess, refetch } = useCustomerByIdEcomQuery({
    id: customer?.userId,
  })
  // console.log(data)
  useEffect(() => {
    if (data?.photo) {
      if (data?.photo !== '') {
        // setCustomerPhoto(`${PHOTO_URL}${uploadFile.filePath}`)
        setCustomerPhoto(`${PHOTO_URL}${data?.photo}`)
        // setCustomerPhoto(`${BASE_URL}${data?.photo}`)
      }
    }
  }, [data, isSuccess])
  // console.log('customerPhoto', customerPhoto)
  // console.log('customerPhoto', data)
  const handleProductPhoto = (e) => {
    // console.log(e)
    // console.log(e.target)
    // console.log(e.target.files[0])
    // console.log(e.target.files[0].name)
    setFile(e.target.files[0])
    setFileName(e.target.files[0].name)
    // handleProductPhotoUpload()
  }
  const handleProductPhotoUpload = async (e) => {
    // e.preventDefault();
    // console.log(customer)
    // console.log(file)
    const formData = new FormData()
    formData.append('file', file)
    // console.log(file)
    // console.log(formData)
    try {
      const res = await axios.post(
        `${BASE_URL}ecom/upload/customer/${customer?.userId}`,
        formData,
        {
          'Content-type': 'multipart/form-data',
        }
      )

      const { fileName, filePath } = res.data
      setUploadedFile({ fileName, filePath })
      refetch()
    } catch (err) {
      if (err.response.status === 500) {
        // console.log(
        //   'There was an problem with the server',
        //   err.response.data.message
        // )
      } else {
        console.log(err.response.data.message)
      }
    }
  }
  const handleImageError = () => {
    setCustomerPhoto('https://randomuser.me/api/portraits/men/88.jpg')
  }
  return (
    <div>
      <div className='bg-white shadow rounded-lg p-4 border-b-4 border-red-500'>
        <div className='p-4'>
          <img
            className='w-24 h-24 rounded-full mx-auto mb-4'
            onError={handleImageError}
            src={customerPhoto}
            alt='User profile'
          />
          {/* <button className="btn btn-ghost">+</button> */}
          <h2 className='text-lg font-medium text-gray-800 text-center'>
            <Link to='/your-profile'>{customer.name}</Link>
          </h2>
        </div>
        <div className='p-4 flex flex-col'>
          <input
            type='file'
            placeholder='Profile Photo'
            className='mx-3 w-full max-w-xs'
            onChange={(e) => handleProductPhoto(e)}
          // value={projectData?.workOrder}
          />

          <button
            className='btn btn-ghost'
            onClick={() => handleProductPhotoUpload()}
          >
            Upload
          </button>
        </div>
        <hr />
        <ul className='p-4'>
          <li className='py-2'>
            <Link to='/order' className='my-2'>
              My Order
            </Link>
          </li>
          <li className='py-2'>
            <Link to='/payment-history' className='my-2'>
              Payment History
            </Link>
          </li>
          <li className='py-2'>
            <label htmlFor='my-modal-6'>
              <h1 className='cursor-pointer my-2'>Change Password</h1>
            </label>
          </li>
        </ul>
      </div>
      <ChangePassword />
    </div>
  )
}

export default UserDashboardSidebar
