import React, { useState } from "react";
import { useSelector } from "react-redux";

export const CheckCustomerLogin = () => {
  const [login, setLogin] = useState(false);
  const userData = useSelector((state) => state.auth);
  if (userData?.isLogin === true) {
    setLogin(true);
  }

  return login;
};
