import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useUpdateCustomerPasswordMutation } from '../../../../services/customerApi'

const ChangePassword = () => {
  const customer = useSelector((state) => state.customer)
  const newPasswordRef = useRef()
  const retypeNewPasswordRef = useRef()
  const [isPassWordReset] = useUpdateCustomerPasswordMutation()

  const handlePassWordSet = async () => {
    // console.log(newPasswordRef.current.value)
    // console.log(retypeNewPasswordRef.current.value)
    const newPassword = newPasswordRef.current.value.trim()
    const retypeNewPassword = retypeNewPasswordRef.current.value.trim()
    // console.log(newPassword, retypeNewPassword)
    if (newPassword == retypeNewPassword) {
      // console.log('matched')
      const phone = customer?.phone
      // console.log('matched', phone)
      const update = { phone, password: newPassword }
      // console.log('update', update)
      await isPassWordReset(update)
        .then((res) => {
          // console.log(res)
          toast.success('Password Reset Successfully', {
            position: toast.POSITION.BOTTOM_RIGHT, // customize the position
            autoClose: 1000,
          })
        })
        .catch((err) => {
          console.log(err)
          toast.error('Please Check Your Password', {
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_RIGHT, // customize the position
          })
        })
    } else {
      toast.error('Please Check Your Password', {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_RIGHT, // customize the position
      })
    }
  }
  return (
    <>
      <input type='checkbox' id='my-modal-6' className='modal-toggle' />
      <label
        htmlFor='my-modal-6'
        className='modal cursor-pointer overflow-y-auto '
      >
        <label
          className='bg-white rounded-md p-0 w-4/5 md:w-2/5 max-h-max m-auto'
          htmlFor=''
        >
          <div className='w-full'>
            <div className='modal-action'>
              <label
                htmlFor='my-modal-6'
                className='btn btn-sm btn-circle mr-2 md:mr-10 cursor-pointer md:text-bold'
              >
                ✕
              </label>
            </div>
            <div className='flex flex-col justify-center items-center w-full m-auto'>
              {/* <div className='flex flex-col justify-center items-start w-11/12 mx-auto mb-2'>
                <h1 className='text-left'>Old Password</h1>
                <input
                  type='text'
                  placeholder=''
                  className='input input-bordered w-full'
                />
              </div> */}
              <div className='flex flex-col justify-center items-start w-11/12 m-auto mb-2'>
                <h1>New Password</h1>
                <input
                  ref={newPasswordRef}
                  type='password'
                  placeholder=''
                  className='input input-bordered w-full'
                />
              </div>
              <div className='flex flex-col justify-center items-start w-11/12 m-auto mb-2'>
                <h1>Retype New Password</h1>
                <input
                  ref={retypeNewPasswordRef}
                  type='password'
                  placeholder=''
                  className='input input-bordered w-full'
                />
              </div>
              <div className='mb-2'>
                <button
                  onClick={() => {
                    handlePassWordSet()
                  }}
                  className='w-full rounded-md border border-primary py-3 px-5 bg-primary text-white cursor-pointer hover:bg-opacity-90 transition'
                >
                  Confirm Password
                </button>
              </div>
            </div>
          </div>
        </label>
      </label>
    </>
  )
}

export default ChangePassword
