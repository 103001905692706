import React, { useEffect, useState } from "react";
import category from "../../../Assets/category.jpg"; // Import fallback image
import axios from "axios";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import BASE_URL from "../../../Utlity/Base_url";
import PHOTO_URL from "../../../Utlity/Photo_url";

const TopCategory = () => {
  const [cat, setCat] = useState([]);
  const [loading, setLoading] = useState(true);

  // console.log("UpdatedCat", cat);

  useEffect(() => {
    getCat();
  }, []);

  const getCat = async () => {
    try {
      const response = await axios.get(`${BASE_URL}category/master`);
      setCat(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  if (loading) {
    return (
      <div className=" grid xl:grid-cols-4 grid-cols-2 mx-40 mt-10 gap-5">
      
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={300}
        height={100}
        viewBox="0 0 300 100"
        backgroundColor="#fff"
        foregroundColor="#ecebeb"
        className=" border border-gray-10 rounded-2xl p-2"
      >
        {/* Placeholder rectangles for loading */}
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
      </div>
    );
  }

  return (
    <div className="py-7 z-0">
      <h1 className="text-3xl text-center pb-5">Our Products Category</h1>
      <div className="container mx-auto px-4 pb-6">
        <div className="grid xl:grid-cols-4 grid-cols-2 xl:mx-40  gap-2">
          {cat?.slice(0, 8).map((item) => (
            
            <Link
              to={`./category/${item._id}`}
              className="hover:text-tomato " // Corrected class name for hover color
              key={item._id}
            >
              <div className="p-3 max-w-lg w-full mx-auto bg-white rounded-xl hover:shadow-md shadow-lg flex items-center space-x-2">
                <div className="shrink-0">
                  <img
                    className=" rounded-full w-14 h-14 float-left no-repeat object-cover"
                    src={item.photo ? `${PHOTO_URL}/${item.photo}` : category} 
                    alt={item.name || "Category Image"}
                  />
                </div>
                <div>
                  <div className="md:text-md text-sm font-medium text-tomato">
                    {item.name}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopCategory;
