import React from 'react'

const ChangeProfilePictureModal = ({}) => {
  return (
    <>
      <div className=''>
        <input type='checkbox' id='my-modal-5' className='modal-toggle' />
        <label
          htmlFor='my-modal-5'
          className='modal cursor-pointer overflow-y-auto '
        >
          <label
            className='bg-white rounded-md p-0 w-2/5 max-h-max m-auto'
            htmlFor=''
          >
            <div className='w-full'>
              <div className='modal-action'>
                <label
                  htmlFor='my-modal-5'
                  className='btn btn-sm btn-circle mr-10 cursor-pointer md:text-bold'
                >
                  ✕
                </label>
              </div>
              <div className='flex justify-center items-center w-full h-40 -translate-y-4'>
                <div>
                  <input
                    type='file'
                    className='file-input file-input-bordered file-input-error  w-full max-w-xs'
                  />
                </div>

                <button className='btn'>Upload</button>
              </div>
            </div>
          </label>
        </label>
      </div>
    </>
  )
}

export default ChangeProfilePictureModal
