import React from "react";
import Skeleton from "react-loading-skeleton";

function ProductLoopLoader({ cards }) {
  return Array(10)
    .fill(0)
    .map((item) => {
      return (
        <div className="card-skeleton flex flex-col justify-center align-middle">
          <Skeleton height={150} />
          <Skeleton />
          <Skeleton width="50%" />
          {/* <Skeleton width="50%" /> */}
          <Skeleton />
        </div>
      );
    });
}

export default ProductLoopLoader;
