import React from 'react';
import Hero from '../../Common/Hero/Hero';
import FoodCat from './FoodCat/FoodCat';

const Food = ({featured}) => {
  return (
    <>
        <Hero/>
        <FoodCat featured={featured}/>
        
    </>
  )
}

export default Food