import React, { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsPlus, BsDash, BsBag, BsFillCartFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import photo from "../../../Assets/product.jpg";
import CartHook from "../../../hooks/useCartHook";
import WishListHook from "../../../hooks/useWishListHook";
import PHOTO_URL from "../../../Utlity/Photo_url";

function ProductCarouselLoop({ item }) {
  const cartData = useSelector((state) => state.order.products);
  const wishListData = useSelector((state) => state.customer.wishList);
  const { addToCart, removeQuantity, addQuantity } = CartHook();
  const { addToWishList, removeWishListFromDb } = WishListHook();
  const [isCart, setIsCart] = useState(false);
  const [isWished, setIsWished] = useState(false);
  const [matched, setMatched] = useState(false);
  const [dataPhoto, setDataPhoto] = useState(photo);

  useEffect(() => {
    if (item?.photo !== undefined) {
      if (item?.photo !== "") {
        if (item?.photo !== "photo.jpg") {
          setDataPhoto(`${PHOTO_URL}${item.photo}`);
        }
      }
    }
  }, [item]);

  useEffect(() => {
    const matched = cartData?.find((pro) => pro.id === item._id);
    const wishMatched = wishListData?.find((pro) => pro.id === item._id);
    if (matched) {
      setMatched(matched);
      setIsCart(true);
    } else {
      setIsCart(false);
    }
    if (wishMatched) {
      setIsWished(true);
    } else {
      setIsWished(false);
    }
  }, [item, cartData, wishListData]);
  const handleImageError = () => {
    setDataPhoto(photo);
  };

  return (
    <div className="card product-loop mx-2 card-compact w-full bg-white shadow-xl">
      <figure>
        <img onError={handleImageError} src={dataPhoto} alt={item.name} />
      </figure>
      <div className="card-body">
        <div className="tooltip" data-tip={item.name}>
          <Link to={`/product/${item._id}`} target="_top">
            <h5 className="card-title text-left h-8 text-sm">
              {item.name.substr(0, 25)}
            </h5>
          </Link>
        </div>
        <div className="card-actions  bg-white">
          <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
            <div className="row-span-1">
              <h3 className="font-bold text-start text-lg">
                {item.priceList[0]?.mrp}
                <span>
                  {" "}
                  ৳<small className="text-sm font-normal"> / {item.unit}</small>
                </span>
              </h3>
            </div>
            <div className="row-span-1">
              <div className="flex justify-end items-center">
                {isWished ? (
                  <button
                    className="p-1 mr-2"
                    onClick={() => removeWishListFromDb(item._id)}
                  >
                    <AiFillHeart
                      className=""
                      color="red"
                      size={25}
                    ></AiFillHeart>
                  </button>
                ) : (
                  <button
                    className="p-1 mr-2"
                    onClick={() => addToWishList(item)}
                  >
                    <AiOutlineHeart className="" size={25}></AiOutlineHeart>
                  </button>
                )}
                {isCart ? (
                  <div className="float-right">
                    <div className="input-group h-8">
                      <button
                        style={{ background: "tomato" }}
                        className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                        onClick={() => removeQuantity(item._id)}
                      >
                        <BsDash className="" size={25} />
                      </button>
                      <input
                        type="text"
                        // onChange={(e) => customQuantity(e.target.value)}
                        value={matched.qty}
                        className="text-base h-6 text-center input w-6 p-0 w-1/6"
                      />
                      <button
                        style={{ background: "tomato" }}
                        className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                        onClick={() => addQuantity(item._id)}
                      >
                        <BsPlus className="" size={25} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    style={{ background: "tomato" }}
                    className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                    onClick={() => addToCart(item)}
                  >
                    <BsFillCartFill size={18} />
                    {/* <BsPlus className="" size={25}></BsPlus> */}
                  </button>
                )}

                {/* </div> */}
              </div>
            </div>
          </div>

          {/* AiOutlineHeart */}
          {/* <AiFillHeart></AiFillHeart> */}
          {/* <span className="float-right"> */}

          {/* </span> */}
          {/* <button className="btn btn-primary">Buy Now</button> */}
        </div>
      </div>
    </div>
  );
}

export default ProductCarouselLoop;
