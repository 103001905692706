import { createSlice } from "@reduxjs/toolkit";
import { type } from "@testing-library/user-event/dist/type";
const initialState = {
  userId: "",
  name: "",
  email: "",
  username: "",
  membership: "",
  address: "",
  type: "",
  point: "",
  phone: "",
  wishList: [],
};
export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    selectCustomer: (state, action) => {
      const { name, email, username, membership, address, type, point, phone, userId } =
        action.payload;
      return {
        ...state,
        userId: userId,
        name: name,
        email: email,
        username: username,
        membership: membership,
        address: address,
        type: type,
        point: point,
        phone: phone,
      };
    },
    selectWishList: (state, action) => {
      return {
        ...state,
        wishList: action.payload,
      };
    },
    selectPoint: (state, action) => {
      state.point = action.payload;
    },
    selectCustomerName: (state, action) => {
      state.name = action.payload;
    },
    selectCustomerEmail: (state, action) => {
      state.email = action.payload;
    },
    selectCustomerUserName: (state, action) => {
      state.username = action.payload;
    },
    selectCustomerMember: (state, action) => {
      state.membership = action.payload;
    },
    selectCustomerAddress: (state, action) => {
      state.address = action.payload;
    },
    selectCustomerType: (state, action) => {
      state.type = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const { selectCustomer, selectWishList, selectPoint, selectCustomerName, selectCustomerEmail, selectCustomerUserName, selectCustomerMember, selectCustomerAddress, selectCustomerType } = customerSlice.actions;

export default customerSlice.reducer;
