import { isWithinInterval, parseISO } from "date-fns";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const PromoPriceHook = (data) => {
    const [isPromo, setIsPromo] = useState(false);
    const [newPrice, setNewPrice] = useState(0);
    useEffect(() => {
        const activePriceList = data?.priceList?.filter(pr => pr.status === "active")
        if (data?.promo_price) {
            if (data?.promo_start && data?.promo_end) {
                const isBetween = isWithinInterval(new Date(), { start: parseISO(data?.promo_start), end: parseISO(data?.promo_end) });
                if (isBetween) {
                    setIsPromo(true)
                    // console.log("promo price ase")
                    // console.log("promo price ase", activePriceList)
                    if (data.promo_type === true) {
                        const pPrice = activePriceList[0].mrp - (activePriceList[0].mrp * (data?.promo_price / 100))
                        setNewPrice(pPrice)
                    } else {
                        const pPrice = activePriceList[0].mrp - data?.promo_price;
                        setNewPrice(pPrice)
                    }

                }
                // console.log("date:", isBetween)

            } else {
                setIsPromo(false)
                // console.log("promo price date er vitor nai")
            }
        } else {
            setIsPromo(false)
            // console.log("promo price nai")
        }
    }, [data])
    return { isPromo, newPrice }
};
export default PromoPriceHook;