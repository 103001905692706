import React from "react";
import ProductLoop2 from "../../../Common/Loop/ProductLoop2";

const FoodCat = ({ featured }) => {
  return (
    <div className="py-7">
      <div className="container mx-auto px-4 pb-6 px-[-15]">
        <h1 className="text-3xl text-start mb-5">Food</h1>
        <div className="grid lg:grid-cols-6 md:grid-cols-5 grid-cols-2 gap-4">
          {/* {
                        featured.length > 1 ?
                            featured.map(product => <ProductLoop2 key={product.article_code} fp={product} />)
                            :
                            <p className='text-center'>Loading...</p>
                    } */}
        </div>
      </div>
    </div>
  );
};

export default FoodCat;
