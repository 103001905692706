import React, { useEffect, useState } from "react";
import photo from "../../../Assets/product.jpg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsPlus, BsDash, BsFillCartFill } from "react-icons/bs";
import "./ProductLoop.css";
import { Link } from "react-router-dom";
import CartHook from "../../../hooks/useCartHook";
import WishListHook from "../../../hooks/useWishListHook";
import { useSelector } from "react-redux";
import PromoPriceHook from "../../../hooks/usePromoPriceCheck";
import PHOTO_URL from "../../../Utlity/Photo_url";

const ProductLoop = ({ fp }) => {
  const cartData = useSelector((state) => state.order.products);
  const wishListData = useSelector((state) => state.customer.wishList);
  const { addToCart, removeQuantity, addQuantity } = CartHook();
  const { addToWishList, removeWishListFromDb } = WishListHook();
  const { isPromo, newPrice } = PromoPriceHook(fp);
  const [isWished, setIsWished] = useState(false);
  const [matched, setMatched] = useState(false);
  const [dataPhoto, setDataPhoto] = useState(photo);

  // console.log("fp", fp);
  useEffect(() => {
    if (fp?.photo && fp.photo !== "photo.jpg") {
      setDataPhoto(`${PHOTO_URL}${fp.photo}`);
    } else {
      setDataPhoto(photo);
    }
    // console.log('fp:', fp);
    // console.log("fp.photo:", fp?.photo);
  }, [fp]);

  useEffect(() => {
    const matchedProduct = cartData?.find((pro) => pro.id === fp._id);
    setIsWished(!!wishListData?.find((pro) => pro.id === fp._id));
    setMatched(matchedProduct);

    // console.log("cartData:", cartData);
    // console.log("wishListData:", wishListData);
    // console.log("matchedProduct:", matchedProduct);
    // console.log("isWished:", !!wishListData?.find((pro) => pro.id === fp._id));
  }, [fp, cartData, wishListData]);

  const handleImageError = () => {
    setDataPhoto(photo);
  };

  return (
    <div className="card product-loop card-compact w-full bg-white  shadow-xl">
      <figure>
        <img src={dataPhoto} onError={handleImageError} alt={fp.article_code} />
      </figure>
      <div className="card-body">
        <div className="tooltip" data-tip={fp.name}>
          <Link to={`/product/${fp._id}#productWrapper`} target="_top">
            <h5 className="card-title text-left text-sm">
              {fp.name.substr(0, 25)}
            </h5>
          </Link>
          <p className="m-0 p-0 text-left text-xs">
            Code:{" "}
            <span className="text-red-400 font-semibold">
              {fp?.article_code}
            </span>
          </p>
        </div>
        <div className="card-actions">
          <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
            <div className="row-span-1">
              {isPromo ? (
                <div className="flex flex-row text-red">
                  <h3 className="font-bold text-start text-lg">
                    <span className="dark:text-white">{newPrice}</span>
                    <span>
                      {" "}
                      ৳
                      <small className="text-sm font-normal">
                        {" "}
                        / {fp?.unit}
                      </small>
                    </span>
                  </h3>
                  <h3 className="font-bold text-start text-lg line-through">
                    {fp?.mrp ? (
                      <>{fp?.mrp ? fp?.mrp.toFixed(2) : "No Price"}</>
                    ) : (
                      <>
                        {fp?.priceList[0]?.mrp
                          ? fp.priceList[0].mrp.toFixed(2)
                          : "No Price"}
                      </>
                    )}
                    <span> ৳ </span>
                  </h3>
                </div>
              ) : (
                <h3 className="font-bold text-start text-lg">
                  {fp?.mrp
                    ? fp?.mrp
                      ? fp?.mrp.toFixed(2)
                      : "No Price"
                    : fp?.priceList[0]?.mrp
                    ? fp.priceList[0].mrp.toFixed(2)
                    : "No Price"}
                  <span>
                    {" "}
                    ৳
                    <small className="text-sm font-normal"> / {fp?.unit}</small>
                  </span>
                </h3>
              )}
            </div>
            <div className="row-span-1">
              <div className="flex justify-end items-center max-[640px]:justify-start max-[640px]:items-start">
                {isWished ? (
                  <button
                    className="p-1 mr-2"
                    onClick={() => removeWishListFromDb(fp._id)}
                  >
                    <AiFillHeart className="" color="red" size={25} />
                  </button>
                ) : (
                  <button
                    className="p-1 mr-2"
                    onClick={() => addToWishList(fp)}
                  >
                    <AiOutlineHeart className="" size={25} />
                  </button>
                )}
                {matched ? (
                  <div className="float-right transition ease-in-out delay-150">
                    <div className="input-group h-8">
                      <button
                        style={{ background: "tomato" }}
                        className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                        onClick={() => removeQuantity(fp._id)}
                      >
                        <BsDash className="" size={25} />
                      </button>
                      <input
                        type="text"
                        value={matched.qty}
                        className="text-base h-6 text-center input p-0 w-1/6"
                        readOnly
                      />
                      <button
                        style={{ background: "tomato" }}
                        className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                        onClick={() => addQuantity(fp._id)}
                      >
                        <BsPlus className="" size={25} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    style={{ background: "tomato" }}
                    className="shadow-md hover:shadow transition ease-in-out delay-150 btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                    onClick={() => addToCart(fp)}
                  >
                    <BsFillCartFill size={18} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ProductLoop;
