
import './App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './Component/Pages/Home/Home'
import NotFound from './Component/Pages/NotFound/NotFound'
import Layout from './Component/Layout/Layout'
import LayoutSidBarLeft from './Component/Layout/LayoutSidBarLeft'
import { SkeletonTheme } from 'react-loading-skeleton'
import BASE_URL from './Utlity/Base_url'
import PHOTO_URL from './Utlity/Photo_url'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  // console.log(BASE_URL)
  // console.log(PHOTO_URL)

  return (
    <div className='App'>
      <SkeletonTheme baseColor='#f3a595' highlightColor='#f4f6fc'>
        {/* <Layout></Layout> */}

        <LayoutSidBarLeft />
      </SkeletonTheme>
      <ToastContainer />
    </div>
  )
}

export default App;

