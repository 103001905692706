import React from "react";
import { Button } from "react-daisyui";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UserDashboardSidebar from "../../Common/SideBar/UserDashboardSidebar";
import { useCustomerSalesQuery } from "../../../services/customerApi";
import { useState } from "react";
import { useEffect } from "react";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import OrderDetailsModal from "../../Common/Modal/OrderDetailsModal";
import { BiCart } from "react-icons/bi";
const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [onShow, setOnShow] = useState(false);
  const customer = useSelector((state) => state.customer);
  // console.log(customer.userId);
  const { data, isSuccess, refetch } = useCustomerSalesQuery({
    cId: customer.userId,
  });
  // console.log(data);
  const handleClose = () => setOnShow(false);
  useEffect(() => {
    if (data && data?.length > 0) {
      setOrders(data);
    }
  }, [isSuccess, data]);
  const handleOrderModal = (item) => {
    setOnShow(true);
    setProducts(item?.products);
  };
  // console.log("order", data);
  return (
    <>
      <div className="container mx-auto">
        <div className="flex">
          <div className="w-1/4 p-4">
            <UserDashboardSidebar customer={customer} />
          </div>
          <div className="w-3/4 pt-4">
            <h1 className="flex items-center gap-3 text-2xl p-6 pl-6 md:text-left text-center  font-semibold  bg-red-500 rounded-lg text-white">
              <BiCart size={40} /> My Orders
            </h1>
            <div className="w-full ">
              {orders.length > 0 ? (
                orders.map((item, index) => (
                  <div
                    key={index}
                    className="bg-white flex justify-around items-center p-4 px-3 mt-3 mx-auto mb-2 rounded-lg shadow-md"
                  >
                    <div className="flex flex-col justify-start items-start w-2/6 ">
                      <h3 className="text-left text-gray-800 text-[10px] font-bold uppercase">
                        Order No
                      </h3>
                      <h1 className="text-left  text-md font-semibold text-gray-600 ">
                        {item?.invoiceId}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-start items-start w-1/6 ">
                      <h3 className="text-left text-gray-800 text-[10px] font-bold uppercase">
                        Date
                      </h3>
                      <h1 className="text-left text-md font-semibold text-gray-600 ">
                        {format(new Date(item?.createdAt), "MM/dd/yyyy")}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-start items-start w-1/6 ">
                      <h3 className="text-left text-gray-800 text-[10px] font-bold uppercase">
                        Amount
                      </h3>
                      <h1 className="text-left text-md font-semibold text-gray-600  text-bold">
                        {item.grossTotalRound} BDT
                      </h1>
                    </div>
                    <div className="flex flex-col justify-start items-start w-1/6">
                      <h3 className="text-left text-gray-800 text-[10px] font-bold uppercase">
                        Quantity
                      </h3>
                      <h1 className="text-center text-md font-semibold text-gray-600 ">
                        {item?.totalItem}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-start items-start w-1/6 ">
                      <h3 className="text-left text-gray-800 text-[10px] font-bold uppercase ">
                        Status
                      </h3>
                      <h1
                        className={`text-left text-sm font-semibold ${
                          item?.status === "pending"
                            ? "text-gray-600"
                            : item?.status === "cancel"
                            ? "text-red-600"
                            : item?.status === "confirm"
                            ? "text-green-600"
                            : item?.status === "order"
                            ? "text-yellow-500"
                            : item?.status === "complete"
                            ? "text-blue-500"
                            : ""
                        }`}
                      >
                        {item?.status}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-end items-center w-1/6">
                      <h1 className="text-left  text-md">
                        <Link to={`/order-details/${item?._id}`}>
                          <Button className="btn-sm font-medium w-32 capitalize" >
                            { item?.status === "cancel"
                              ? "Order Details"
                              : item?.status === "confirm"
                              ? "Order Details"
                              : item?.status === "complete"
                              ? "Order Details"
                              : item?.status === "order"
                              ? "Order process"
                              : ""}
                          </Button>
                        </Link>
                      </h1>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="flex items-center justify-center text-gray-500 font-bold text-3xl">
                  Oops! You have not ordered anything yet
                </h1>
              )}
            </div>
          </div>
          {products.length > 0 && (
            <OrderDetailsModal
              title={"Order Details"}
              onShow={onShow}
              handleClose={handleClose}
              products={products}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MyOrder;
