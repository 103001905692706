import React, { useState } from "react";
import LoadingModal from "../../Common/Modal/LoadingModal";
import ProductLoop from "../../Common/Loop/ProductLoop";
import { useComboProductQuery } from "../../../services/productApi";
import Pagination from "../../Common/Pagination/Pagination"; // Assuming you have a Pagination component

const Offers = () => {
  const [activePageIndex, setActivePageIndex] = useState(0); // State to manage active page index
  const itemsPerPage = 20; // Number of products per page
  const { data, isLoading, error } = useComboProductQuery({
    page: activePageIndex + 1, // Adjust to 1-based index for backend
    size: itemsPerPage,
    q: "" // Replace with actual search query if needed
  });

  const handlePageClick = (pageIndex) => {
    setActivePageIndex(pageIndex);
  };

  return (
    <div className=" mt-5">
      <div className="grid max-[640px]:grid-cols-2 lg:grid-cols-5 gap-4 place-content-center p-7">
        {isLoading ? (
          <LoadingModal
            title={"Please Wait"}
            onShow={true}
            handleClose={() => {}}
          />
        ) : error ? (
          <p>Error fetching data</p>
        ) : data?.products?.length > 0 ? (
          data?.products?.map((item) => (
            <ProductLoop key={item._id} fp={item} />
          ))
        ) : (
          <p>No Products to show</p>
        )}
      </div>

      {data?.totalPages > 1 && (
        <Pagination
          activePageIndex={activePageIndex}
          pages={Array.from({ length: data?.totalPages }, (_, i) => i + 1)}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  );
};

export default Offers;
