import React from 'react'
import {
  AiFillHeart,
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillShopping,
  AiOutlineHeart,
  AiOutlineMail,
} from 'react-icons/ai'
import { FcInTransit, FcMoneyTransfer, FcCellPhone } from 'react-icons/fc'
import {
  FaCcMastercard,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutube,
  FaInstagram,
} from 'react-icons/fa'
import { RiVisaFill } from 'react-icons/ri'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import BASE_URL from '../../../Utlity/Base_url'
import { useEffect } from 'react'
import Photo from '../../../Assets/product.jpg'
import Download from '../../../Assets/appDownload.png'
import { BsPlus, BsDash, BsBag, BsCart, BsFillCartFill } from 'react-icons/bs'
import {
  useProductByIdQuery,
  useProductBySimilarQuery,
} from '../../../services/productApi'
import CartHook from '../../../hooks/useCartHook'
import { useSelector } from 'react-redux'
import WishListHook from '../../../hooks/useWishListHook'
import { isWithinInterval, parseISO } from 'date-fns'
import PromoPriceHook from '../../../hooks/usePromoPriceCheck'
import PHOTO_URL from '../../../Utlity/Photo_url'
import Slider from 'react-slick'
import FeaturedProduct from '../../Common/FeaturedProduct/FeaturedProduct'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ProductCarousel from '../../Common/Carousel/ProductCarousel'
import SimilarCarousel from '../../Common/Carousel/SimilarCarousel'

const Product = () => {
  const { id } = useParams()
  const cartData = useSelector((state) => state.order.products)
  const wishListData = useSelector((state) => state.customer.wishList)

  const productDetail = 
    'Online & in-store, we deliver quality products tailored to your needs. Shop now for timely service.'

  const [qty, setQty] = useState(1)
  const [isCart, setIsCart] = useState(false)
  const [activePrice, setActivePrice] = useState(0)
  const { data, isSuccess, isLoading, refetch } = useProductByIdQuery({ id })
  const {
    data: similar,
    isSuccess: similarIsSuccess, 
    isLoading: similarIsLoading, 
    refetch: similaeRefetch, 
  } = useProductBySimilarQuery({ id })
  const [isWished, setIsWished] = useState(false)
  const [matched, setMatched] = useState(false)
  const [sm, setSm] = useState([])
  const { addToCart, removeQuantity, addQuantity } = CartHook()
  const { isPromo, newPrice } = PromoPriceHook(data)
  const { addToWishList, removeWishListFromDb } = WishListHook()

  const [dataPhoto, setDataPhoto] = useState(Photo)
  // console.log(data)
  // console.log(data?.length)
  // console.log(similar)
  // console.log(similar?.length)

  useEffect(() => {
    if (similar?.length > 0) {
      setSm(similar)
    }
  }, [similar])
  useEffect(() => {
    // console.log('dataPhoto2')
    if (data?.photo !== undefined) {
      if (data?.photo !== '') {
        if (data?.photo !== 'photo.jpg') {
          setDataPhoto(`${PHOTO_URL}${data.photo}`)
          // console.log(data?.photo)
        }
      }
    }
  }, [data])

  // console.log('dataPhoto', dataPhoto)

  useEffect(() => {
    const activePriceList = data?.priceList?.filter(
      (pr) => pr.status === 'active'
    )
    setActivePrice(activePriceList)
    const matched = cartData?.find((pro) => pro.id === data?._id)
    const wishMatched = wishListData?.find((pro) => pro.id === data?._id)
    if (matched) {
      setMatched(matched)
      setIsCart(true)
    } else {
      setIsCart(false)
    }
    if (wishMatched) {
      setIsWished(true)
    } else {
      setIsWished(false)
    }
  }, [data, cartData, wishListData])

  useEffect(() => {
    refetch()
  }, [id])

  window.onload = function () {
    window.scrollTo(0, 0)
  }

  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "black", borderRadius: "20px" }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "black", borderRadius: "20px" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   lazyLoad: true,
  //   autoplay: false,
  //   autoplaySpeed: 3000,
  //   slidesToShow: 8,
  //   slidesToScroll: 3,
  //   arrows: true,
  //   prevArrow: <SamplePrevArrow />
  //   ,
  //   nextArrow: <SampleNextArrow />
  //   ,
  //   beforeChange: (current, next) => {
  //     console.log(next);
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 900,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //   ],
  // };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  // console.log(data)

  return (
    <>
      <div className='flex  bg-white-900 flex-col md:flex-row items-center justify-center flex-wrap w-full'>
        <div
          id='productWrapper'
          className=' md:w-1/2 flex items-center justify-center'
        >
          <img src={dataPhoto} alt={data?.name} className='w-4/5 object-cover' />
        </div>
        <div className='flex flex-col justify-start items-left md:justify-items-start md:w-1/2 p-5'>  
          <h5 className='card-title text-left md:text-2xl font-normal md:text-left mb-2'>
            {data?.name}
            {/* {console.log(data?.photo)} */}
          </h5>
          <div className='card-actions'>
            <div className='flex justify-start items-center flex-col md:items-start w-full'>
              {isPromo === true ? (
                <div className='flex flex-row'>
                  <h3 className='font-bold text-left text-blue-500 text-4xl mb-2'>
                    {newPrice}
                    <span>
                      {' '}
                      ৳
                      <small className='text-sm text-left font-normal'>
                        {' '}
                        / {data?.unit}
                      </small>
                    </span>
                  </h3>
                  <h3 className='font-bold text-left text-red-500 text-4xl mb-2 line-through'>
                    {activePrice?.length > 0 ? activePrice[0]?.mrp : 'No price'}
                    {/* {data?.priceList?.length > 0
                      ? data?.priceList[0]?.mrp
                      : "No price"} */}
                    <span>
                      {' '}
                      ৳
                      <small className='text-sm text-left font-normal'>
                        {' '}
                        / {data?.unit}
                      </small>
                    </span>
                  </h3>
                </div>
              ) : (
                <>
                  {' '}
                  <h3 className='font-bold text-left text-red-500 text-4xl mb-2'>
                    {activePrice?.length > 0 ? activePrice[0]?.mrp : 'No price'}

                    <span>
                      {' '}
                      ৳
                      <small className='text-sm text-left font-normal'>
                        {' '}
                        / {data?.unit}
                      </small>
                    </span>
                  </h3>
                </>
              )}
            </div>
            <div className='flex flex-row justify-start  items-center w-full'>
              {isWished ? (
                <button
                  className='p-1 mr-2'
                  onClick={() => removeWishListFromDb(data?._id)}
                >
                  <AiFillHeart className='' color='red' size={30}></AiFillHeart>
                </button>
              ) : (
                <button
                  className='p-1 mr-2'
                  onClick={() => addToWishList(data)}
                >
                  <AiOutlineHeart className='' size={30}></AiOutlineHeart>
                </button>
              )}
              {isCart ? (
                <div className='float-right transition ease-in-out delay-150'>
                  <div className='input-group'>
                    <button
                      style={{ background: 'tomato' }}
                      className='shadow-md hover:shadow btn float-right w-5 h-5 add-to-cart border-0 p-0 btn-circle'
                      onClick={() => removeQuantity(data?._id)}
                    >
                      <BsDash className='' size={25} />
                    </button>
                    <input
                      type='text'
                      // onChange={(e) => customQuantity(e.target.value)}
                      value={matched?.qty}
                      className='text-base text-center input p-0 w-2/6'
                    />
                    <button
                      style={{ background: 'tomato' }}
                      className='shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle'
                      onClick={() => addQuantity(data?._id)}
                    >
                      <BsPlus className='' size={30} />
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  style={{ background: 'tomato' }}
                  className='shadow-md hover:shadow transition ease-in-out delay-150 btn float-right lg:w-1/12 sm:w-1/6 h-7 add-to-cart border-0 p-0 btn-circle'
                  onClick={() => addToCart(data)}
                >
                  <BsFillCartFill size={18} />
                  {/* <BsPlus className="" size={25}></BsPlus> */}
                </button>
              )}
            </div>
          </div>
          <div className='w-full mt-3'>
            <p className='text-justify'>
              Category:{' '}
              <Link to={`/category/${data?.category?._id}`}>
                {data?.category?.name}{' '}
              </Link>{' '}
              | Code: {data?.article_code}
            </p>
            <p className='text-justify'>
              {productDetail ? productDetail : 'Contact us for more info'}
            </p>
          </div>
        </div>

        <div className='flex bg-red-500  w-full m-0 p-5'>
          <div className='flex items-center justify-start w-1/2'>
            <div className='flex items-center w-1/2'>
              <FcInTransit size={35} />
              <h3 className='ml-1 text-xl uppercase  text-white font-bold '>
                30 Minutes Delivery
              </h3>
            </div>
            <div className='flex items-center w-1/2'>
              <FcMoneyTransfer size={35} />
              <h3 className='ml-1 text-xl uppercase  text-white font-bold '>
                Cash On Delivery
              </h3>
            </div>
          </div>
          <div className='flex justify-end items-center w-1/2 text-white'>
            <h3>Pay with: </h3>
            <ul className='flex ml-2'>
              <li>
                <RiVisaFill size={30} />
              </li>
              <li className='ml-1'>
                <FaCcMastercard size={30} />
              </li>
            </ul>
          </div>
        </div>
        <div className='flex flex-col w-full justify-start items-left md:justify-items-start p-5'>
          {' '}
          {sm?.length > 0 ? (
            <>
              <div className='py-7'>
                <h1 className='text-3xl text-center mb-5'>Similar Products </h1>
                <SimilarCarousel data={sm} />
              </div>
            </>
          ) : (
            'Loading'
          )}
        </div>
        <div className='flex flex-col justify-center items-center md:flex-row bg-stone-100 w-full overflow-x-hidden'>
          <div className='w-full flex-2 p-5'>
            <h1 className='text-3xl my-1'>TCM Shopping App</h1>
            <p className='text-justify'>
              At TCM, we're dedicated to delivering quality products to our
              customers both online and in our physical shop. We take pride in
              meeting every single requirement of our customers and ensuring
              timely delivery. Shop with us for a hassle-free and personalized
              shopping experience.
            </p>
          </div>
          <div className='flex flex-1 flex-col p-5 justify-center items-start mx-auto w-full md:justify-center md:items-start md:w-2/6 bg-white ml-2 md:rounded-lg'>
            <img className='mr-5' src={Download} alt='' />
            <div className='flex justify-start items-center mt-6'>
              <FcCellPhone size={60} />
              <a href='tel:+01316842636' className='text-xl md:text-2xl'>
                01316842636
              </a>
            </div>
            <div className='flex justify-start items-center m-2 md:mt-6'>
              <h1 className='mr-1'>
                Email us:{' '}
                <a href='mailto:info@tcm-bd.com' className='italic font-bold'>
                  info@tcm-bd.com
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Product
