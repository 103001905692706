import React from 'react'
import LoginBg from '../../../Assets/bg-br.png'
import UserDashboardSidebar from '../../Common/SideBar/UserDashboardSidebar'
import { useSelector } from 'react-redux'

function ChangePass() {
  const customer = useSelector((state) => state.customer)

  return (
    <>
      <h1 className='text-3xl mt-3 md:text-left text-center md:pl-8'>
        Change Password
      </h1>
      <div className='container h-screen mx-auto'>
        <div className='flex'>
          <div className='w-1/4 p-4'>
            <UserDashboardSidebar customer={customer} />
          </div>
          <div className='w-3/4 p-4'>
            <div className='bg-white flex justify-center items-center rounded-lg shadow border-b-4 border-red-500'>
              <div className='w-11/12 h-100 pt-8 '>
                <h1 className='text-3xl font-bold text-red-500 mb-5 text-center block tracking-wider'>
                  Change Password
                </h1>
                <form>
                  <div className='form-control mb-3'>
                    <input
                      type='password'
                      placeholder='Enter Current Password'
                      className='input input-bordered'
                    />
                  </div>
                  <div className='form-control mb-3'>
                    <input
                      type='password'
                      placeholder='Re-enter New Password'
                      className='input input-bordered'
                    />
                  </div>
                  <div className='form-control mb-3'>
                    <input
                      type='password'
                      placeholder='Re-enter New Password'
                      className='input input-bordered'
                    />
                  </div>
                  <div className='mb-10'>
                    {/* <Link to={'/authentication'}> */}
                    <input
                      type='submit'
                      value='Save'
                      className='btn w-full text-xl tracking-wide'
                    />
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePass
