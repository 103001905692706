import React, { useEffect, useState, useMemo } from "react";
import Hero from "../../Common/Hero/Hero";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../Utlity/Base_url";
import CategoryLoop from "../../Common/Loop/CategoryLoop";
import McCategoryLoop from "../../Common/Loop/McCategoryLoop";
import { useCategoryByGroupQuery } from "../../../services/categoryApi";

const Group = () => {
  const { group } = useParams();
  const { data, isSuccess } = useCategoryByGroupQuery({ group });

  console.table("GROUP WISE CATEGORY",data);
  return (
    <>
      <Hero />
      <div className="container mt-5">
        <div className="grid max-[640px]:grid-cols-2 max-[800px]:grid-cols-4 grid-cols-3 lg:grid-cols-6 gap-2 place-content-center p-7">
          {data?.map((item) => {
            return <McCategoryLoop category={item} key={item._id} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Group;
