import React, { useState, useEffect, useRef } from "react";
import { useFeaturedProductQuery } from "../../../services/productApi";
import ProductCarouselLoop from "./../Loop/ProductCarouselLoop";

const ProductCarousel = () => {
  const { data } = useFeaturedProductQuery();
  const scrollRef = useRef(null);

  // console.log("Fearured Product Carousel",data)

  // Auto-scroll settings
  const scrollInterval = 1500; // Adjust the interval (in milliseconds) as needed
  const scrollAmount = 250; // Adjust the scroll amount as needed

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });

        // Reset scroll position to the start if at the end
        if (
          scrollRef.current.scrollLeft + scrollRef.current.clientWidth >=
          scrollRef.current.scrollWidth
        ) {
          scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
        }
      }
    }, scrollInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ul
        ref={scrollRef}
        style={{
          display: "flex",
          overflowX: "hidden",
          scrollSnapType: "x mandatory",
          userSelect: "none",
          // Hide scrollbar for different browsers
          msOverflowStyle: "none", // Internet Explorer 10+
          scrollbarWidth: "none", // Firefox
        }}
        className="no-scrollbar px-2 mx-5"
      >
        {data?.length > 0 ? (
          data.map((item, i) => (
            <li
              key={i} // Ensure each child has a unique key
              style={{
                fontSize: "50px",
                width: "250px",
                height: "60vh",
                flexShrink: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProductCarouselLoop item={item} />
            </li>
          ))
        ) : (
          <p>Fetured Product is Loading ..</p>
        )}
      </ul>
    </>
  );
};

export default ProductCarousel;
