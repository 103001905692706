import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { getUser } from "../hooks/useCustomerHook";

const AuthCheck = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth);
  const { from } = location.state || { from: { pathname: "/" } };
  // const getData = useSelector();

  // console.log("userData", userData);

  if (userData?.isLogin === true) {
    // console.log(location);
    return navigate(from);
  }

  return children;
};

export default AuthCheck;
