import React from 'react';
import { usePromoProductQuery } from '../../../services/productApi';
import ProductLoop from '../../Common/Loop/ProductLoop';
import LoadingModal from '../../Common/Modal/LoadingModal';

const FlashSale = () => {
  const { data, isSuccess, isLoading,isError } = usePromoProductQuery();
  const result = usePromoProductQuery();

  const [loader, setLoader] = React.useState(true);
  const handleLoaderClose = () => setLoader(false);
// console.log('data',data, result)
  return (
    <div className='container mt-5'>
      <div className='grid max-[640px]:grid-cols-2 lg:grid-cols-5 gap-4 place-content-center p-7'>
        {isLoading ? (
          <LoadingModal
            title={'Please Wait'}
            onShow={loader}
            handleClose={handleLoaderClose}
          />
        ) : isSuccess && data?.products?.length > 0 ? (
          data.products.map((item) => (
            <ProductLoop fp={item} key={item._id} />
          ))
        ) : isSuccess && data?.products?.length === 0 ? (
          <p>No Products to show</p>
        ) : (
          <p className=' text-2xl font-medium text-gray-500'>Coming soon...</p>
        )}
      </div>
    </div>
  );
};

export default FlashSale;
