import React, { useEffect, useState } from "react";
// import Carousel from '../../Common/Carousel/Carousel';
import FeaturedProduct from "../../Common/FeaturedProduct/FeaturedProduct";
import NavBar from "../../Common/Header/NavBar";
import Hero from "../../Common/Hero/Hero";
import MenuDrawer from "../../Common/MenuDrawer";
import PopularProducts from "../../Common/PopularProducts/PopularProducts";
import TopCategory from "../../Common/TopCategory/TopCategory";

const Home = () => {
  // console.log('featured', featured)
  console.log('E-Commerce version : 0.1.1 @techsoul')
  return (
    <div>
      <Hero />
      <TopCategory/>
       <FeaturedProduct />
      <PopularProducts/>
    </div>
  );

};

export default Home;
