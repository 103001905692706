import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectWishList } from "../features/customerSlice";
import CartHook from "./useCartHook";

function WishListHook() {
  const { addToCart } = CartHook();
  const wishList = useSelector((state) => state.wishList);
  const cartData = useSelector((state) => state.order.products);
  const dispatch = useDispatch();

  // GET STORE DATA
  const getStoredCart = () => {
    const storedCart = JSON.parse(localStorage.getItem("wish_list"));
    return storedCart;
  };

  // ADD TO CART FROM WISHLIST
  const addToCartFromWishlist = (item) => {
    // console.log('wish', item);

    let data = {
      _id: item.id,
      priceList: [
        {
          _id: item.priceId,
          mrp: item.mrp || item?.priceList[0]?.mrp,
          tp: item.tp,
          supplier: item.supplier,
        },
      ],
      name: item.name,
      article_code: item.article_code,
      ean: item?.ean,
      photo: item?.photo,
      qty: item.qty,
      vat: item.vat,
      unit: item.unit,
      order: cartData !== null ? cartData.length + 1 : 1,
    };

    addToCart(data);
    removeWishListFromDb(item.id);
  };

  // ADD TO WishList
  const addToWishList = (data) => {
    // console.log("🚀 ~ data", data);

    let product = [];
    const cartData = getStoredCart();
    if (cartData !== null) {
      let matched = cartData?.find((pro) => pro.id === data._id);
      let rest = cartData?.filter((pro) => pro.id !== data._id);

      if (matched !== undefined) {
        product = [
          ...rest,
          {
            ...matched,
            qty: matched.qty + 1,
          },
        ];
      } else {
        const activePriceList = data?.priceList?.filter(
          (pr) => pr.status === "active"
        );
        let newProduct = {
          id: data?._id,
          priceId: activePriceList[0]?._id,
          name: data.name,
          article_code: data.article_code,
          ean: data?.ean,
          photo: data?.photo,
          mrp: activePriceList[0]?.mrp || data?.mrp,
          qty: 1,
          tp: activePriceList[0]?.tp,
          vat: data?.vat ? data?.vat : 0,
          unit: data.unit,
          supplier: activePriceList[0]?.supplier,
          order: cartData?.length + 1,
        };

        product = [...cartData, newProduct];
      }
    } else {
      const activePriceList = data?.priceList?.filter(
        (pr) => pr.status === "active"
      );
      let newProduct = {
        id: data?._id,
        priceId: activePriceList[0]?._id,
        name: data.name,
        article_code: data.article_code,
        ean: data?.ean,
        photo: data?.photo,
        mrp: activePriceList[0]?.mrp || data?.mrp,
        qty: 1,
        tp: activePriceList[0]?.tp,
        vat: data?.vat ? data?.vat : 0,
        unit: data.unit,
        supplier: activePriceList[0]?.supplier,
        order: 1,
      };

      product = [newProduct];
    }

    dispatch(selectWishList(product));
    return localStorage.setItem("wish_list", JSON.stringify(product));
  };

  const removeWishListFromDb = (id) => {
    const storedCart = getStoredCart();
    if (storedCart) {
      const newCart = storedCart?.filter((p) => p.id !== id);

      dispatch(selectWishList(newCart));
      return localStorage.setItem("wish_list", JSON.stringify(newCart));
    }
  };

  const deleteWishList = () => {
    dispatch(selectWishList([]));
    return localStorage.setItem("wish_list", null);
  };

  return {
    addToWishList,
    removeWishListFromDb,
    addToCartFromWishlist,
    deleteWishList,
  };
}

export default WishListHook;
