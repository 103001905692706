import React, { useState } from 'react'
import HeroInfoPage from '../../Common/Hero/HeroInfoPage'

const ContactUs = () => {
  const [person, setPerson] = useState({
    name: '',
    phone: '',
    email: '',
    msg: '',
  })
  const [people, setPeople] = useState([])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setPerson({ ...person, [name]: value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (person.name && person.phone && person.msg) {
      const newPerson = { ...person, id: new Date().getTime().toString() }
      setPeople([...people, newPerson])
      setPerson({ name: '', phone: '', email: '', msg: '' })
    }
  }
  return (
    <>
      <HeroInfoPage />

      <section className='flex flex-col md:flex-row w-11/12 my-4'>
        <div className='flex flex-col items-center justify-start w-full md:w-3/5 '>
          <form className='form w-full px-5 pl-10'>
            <div className='flex justify-start items-center w-full py-2'>
              <label htmlFor='name' className='w-1/5'>
                Name:
              </label>
              <input
                type='text'
                id='name'
                name='name'
                className='input input-bordered ml-3 w-4/5 px-2 py-1 focus:outline-none focus:border-red-500'
                value={person.name}
                onChange={handleChange}
              />
            </div>
            <div className='flex justify-between items-center w-full py-2'>
              <label htmlFor='phone' className='w-1/5'>
                phone:
              </label>
              <input
                type='number'
                id='phone'
                name='phone'
                className='input input-bordered ml-3 w-4/5 px-2 py-1 focus:outline-none focus:border-red-500'
                value={person.phone}
                onChange={handleChange}
              />
            </div>
            <div className='flex justify-between items-center w-full py-2'>
              <label htmlFor='email' className='w-1/5'>
                Email:
              </label>
              <input
                type='email'
                id='email'
                name='email'
                className='input input-bordered ml-3 w-4/5 px-2 py-1 focus:outline-none focus:border-red-500'
                value={person.email}
                onChange={handleChange}
              />
            </div>
            <div className='flex justify-between items-center w-full py-2'>
              <label htmlFor='msg' className='w-1/5'>
                Message:
              </label>
              <input
                type='textarea'
                id='msg'
                name='msg'
                className='input input-bordered ml-3 w-4/5 px-2 py-1 focus:outline-none focus:border-red-500'
                value={person.msg}
                onChange={handleChange}
              />
            </div>
            <div className='flex items-center justify-center'>
              <button
                type='submit'
                className='btn btn-error mt-4'
                onClick={handleSubmit}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
        <div className='w-full md:w-2/5 mt-2'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.488021225668!2d90.39466681397535!3d23.872307490027442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c54a95da95c3%3A0xf24e2d6089cf03c3!2sTCM!5e0!3m2!1sen!2sbd!4v1674457407743!5m2!1sen!2sbd'
            width='500'
            height='300'
            className='md:rounded-lg'
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </section>
    </>
  )
}

export default ContactUs
