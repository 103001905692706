import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import BASE_URL from "../Utlity/Base_url";

// console.log(BASE_URL);

export const CustomerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    CustomerById: builder.query({
      query: ({ id }) => `customer/${id}`,
      providesTags: ["Customer"],
    }),
    CustomerByIdEcom: builder.query({
      query: ({ id }) => `ecom/customer/${id}`,
      providesTags: ["Customer"],
    }),
    CustomerSales: builder.query({
      query: ({ cId }) => `ecom/sale/${cId}`,
      providesTags: ["Customer"],
    }),
    addCustomer: builder.mutation({
      query: (Customer) => ({
        url: "ecom/customer",
        method: "POST",
        body: Customer,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomer: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `customer/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateEcomCustomer: builder.mutation({
      query: ({ cId, ...rest }) => ({
        url: `ecom/customer/${cId}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomerPassword: builder.mutation({
      query: ({ phone, ...rest }) => ({
        url: `ecom/customer/password/${phone}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomerAddress: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `ecom/customer/address/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomerPoint: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `ecom/customer/point/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    customerIsPhone: builder.mutation({
      query: (Customer) => ({
        url: "ecom/customer/isPhone",
        method: "POST",
        body: Customer,
      }),
    }),
    customerLogin: builder.mutation({
      query: (Customer) => ({
        url: "ecom/customer/login",
        method: "POST",
        body: Customer,
      }),
    }),
    customerRegister: builder.mutation({
      query: (Customer) => ({
        url: "ecom/customer/register",
        method: "POST",
        body: Customer,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
});

export const {
  useCustomersQuery,
  useCustomerByIdEcomQuery,
  useCustomersExportQuery,
  useCustomerSalesQuery,
  useCustomerQuery,
  // useCustomerPointQuery,
  useCustomerDWQuery,
  useAddCustomerMutation,
  useCustomerCountQuery,
  useCustomerPagenationQuery,
  useUpdateCustomerMutation,
  useUpdateEcomCustomerMutation,
  useUpdateCustomerPasswordMutation,
  useUpdateCustomerAddressMutation,
  useUpdateCustomerPointMutation,
  useCustomerIsPhoneMutation,
  useCustomerLoginMutation,
  useCustomerRegisterMutation,
  useDeleteCustomerMutation,
} = CustomerApi;

export default CustomerApi;
