import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import NavBar from '../Common/Header/NavBar'
import Home from '../Pages/Home/Home'
import Shop from '../Pages/Shop/Shop'
import NotFound from '../Pages/NotFound/NotFound'
import Footer from '../Common/Footer/Footer'
import Category from '../template/Category/Category'

const Layout = () => {
  const [notification, setNotification] = useState(true)
  const [chat, setChat] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [wishList, setWishList] = useState(false)
  const [cart, setCart] = useState(false)
  const [products, setProducts] = useState([])
  const [featured, setFeatured] = useState([])

  useEffect(() => {
    fetch('products.json')
      .then((res) => res.json())
      .then((data) => setProducts(data))
  }, [])
  useEffect(() => {
    let fp = []
    fp = products.filter((product) => {
      return product.featured.toString().toLowerCase() === 'true'
    })
    setFeatured(fp)
  }, [products])

  return (
    <>
      <div className='drawer'>
        <input id='my-drawer' type='checkbox' className='drawer-toggle' />
        <div className='drawer-content'>
          {/* <!-- Page content here --> */}

          {/* Navbar goes here */}
          <NavBar
            notification={notification}
            chat={chat}
            loggedIn={loggedIn}
            cart={cart}
            wishList={wishList}
          />
          <Routes>
            <Route
              exact
              path='/'
              element={<Home  />}
            />
            <Route path='/category/:id' element={<Category />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer></Footer>
          {/* Navbar goes here */}
        </div>
        <div className='drawer-side'>
          <label or='my-drawer' className='drawer-overlay'></label>
          <ul className='menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content'>
            {/* <!-- Sidebar content here --> */}
            <li>
              <a>Sidebar Item 1</a>
            </li>
            <li>
              <a>Sidebar Item 2</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Layout
