import React, { useState } from "react";
import { usePopularProductQuery } from "../../../services/productApi";
import ProductLoop from "../../Common/Loop/ProductLoop";
import LoadingModal from "../../Common/Modal/LoadingModal";
import Pagination from "../../Common/Pagination/Pagination"; // Assuming this is your Pagination component

const PopularProduct = () => {
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const [activePageIndex, setActivePageIndex] = useState(0); // State to manage active page index
  const itemsPerPage = 20; // Number of products per page

  const { data, isLoading, isError } = usePopularProductQuery({
    page: activePageIndex + 1, // Adjust to 1-based index for backend
    size: itemsPerPage,
  });

  // console.log('data', data);

  const handlePageClick = (pageIndex) => {
    setActivePageIndex(pageIndex);
  };

  return (
    <div className=" mt-5">
      <div className="grid max-[640px] grid-cols-2 lg:grid-cols-5 gap-4 place-content-center p-7">
        {isLoading ? (
          <LoadingModal
            title={"Please Wait"}
            onShow={loader}
            handleClose={handleLoaderClose}
          />
        ) : isError ? (
          <p>Error loading products.</p>
        ) : (
          <>
            {data?.products?.length > 0 ? (
              data.products.map((item) => (
                <ProductLoop key={item._id} fp={item} />
              ))
            ) : (
              <p>No Products to show</p>
            )}
          </>
        )}
      </div>

      {/* Pagination component */}
      <Pagination
        activePageIndex={activePageIndex}
        pages={Array.from({ length: Math.ceil(data?.totalCount / itemsPerPage) }, (_, i) => i)}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default PopularProduct;
