import React, { useEffect, useRef } from "react";
import { useSnapCarousel } from "react-snap-carousel";
import ProductCarouselLoop from "../Loop/ProductCarouselLoop";

const SimilarCarousel = ({ data }) => {
  const { scrollRef } = useSnapCarousel();
  const speed = 1; // Adjust the speed as needed

  useEffect(() => {
    let animationId = null;

    const scrollStep = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += speed;

        // Reset scroll position to the start if at the end
        if (
          scrollRef.current.scrollLeft >=
          scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        ) {
          scrollRef.current.scrollTo({ left: 0 });
        }
      }
      animationId = requestAnimationFrame(scrollStep);
    };

    animationId = requestAnimationFrame(scrollStep);

    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
    };
  }, [scrollRef]);

  return (
    <>
      <ul
        ref={scrollRef}
        style={{
          display: "flex",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          userSelect: "none",
          msOverflowStyle: "none", // Internet Explorer 10+
          scrollbarWidth: "none", // Firefox
        }}
        className="no-scrollbar"
      >
        {data?.length > 0 ? (
          data.slice(0, 10).map((item, i) => (
            <li
              key={i}
              style={{
                fontSize: "50px",
                width: "250px",
                height: "60vh",
                flexShrink: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProductCarouselLoop item={item} />
            </li>
          ))
        ) : (
          <p>Loading ..</p>
        )}
      </ul>
    </>
  );
};

export default SimilarCarousel;

