import React from 'react'
import LoginBg from '../../../Assets/bg-br.png'

function ResetPass() {
  return (
    <section
      className='h-full bg-no-repeat bg-right-bottom'
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <div>
        <div className='flex justify-center items-center'>
          <div className='w-80 h-100'>
            <div className='h-20'></div>
            <h1 className='text-3xl font-bold text-red-500  mb-5 text-center block '>
              Reset Password
            </h1>
            <form>
              <div className='form-control mb-3'>
                <input
                  type='password'
                  placeholder='Enter New Password'
                  className='input input-bordered'
                />
              </div>
              <div className='form-control mb-3'>
                <input
                  type='password'
                  placeholder='Re-enter New Password'
                  className='input input-bordered'
                />
              </div>
              <div className='mb-10'>
                {/* <Link to={'/authentication'}> */}
                <input
                  type='submit'
                  value='Send Reset Code'
                  className='btn w-full'
                />
                {/* </Link> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResetPass
