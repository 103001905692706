// import toast, { Toaster } from "react-hot-toast";

import { toast, Toaster } from 'react-hot-toast'

const notify = (note, type) => {
  // console.log('tost', type, note)
  switch (type) {
    case 'success':
      // console.log('success')
      toast.success(`${note}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
      })
      break
    case 'loading':
      // console.log('loading')
      toast.loading(`${note}`)
      break
    case 'error':
      console.log('error')
      toast.error(`${note}`)
      break

    default:
      toast(note)
  }
  // console.log('tost2', type, note)
  // return <Toaster position='bottom-right' />
}

export { notify }
