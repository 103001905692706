import React, { useEffect } from 'react'
import ChangeProfilePictureModal from '../../Common/Modal/ChangeProfilePicture/ChangeProfilePictureModal'
import ChangePassword from '../../Common/Modal/ChangePassword/ChangePassword'
import { Routes, Route, Link } from 'react-router-dom'
import { AiFillEdit, AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { useState } from 'react'
import ChangeInfo from '../../Common/Modal/ChangeInfo/ChangeInfo'
import ChangeAddress from '../../Common/Modal/ChangeAddress/ChangeAddress'
import { useDispatch, useSelector, useStore } from 'react-redux'
import MyOrder from '../MyOrder/MyOrder'
import UserDashboardSidebar from '../../Common/SideBar/UserDashboardSidebar'
import {
  useCustomerByIdEcomQuery,
  useUpdateCustomerAddressMutation,
} from '../../../services/customerApi'
import { startOfToday, endOfToday, format, formatDistance } from 'date-fns'

const BasicInfo = ({ customer, addressChange }) => {
  // console.log('customer', customer?.userId)
  const [cId, setCId] = useState('')
  const [customerInfo, setCustomerInfo] = useState({})
  const [updateCustomerAddress] = useUpdateCustomerAddressMutation()
  useEffect(() => {
    if (customer) {
      setCId(customer?.userId)
    }
  }, [customer])
  useEffect(() => {
    if (customer?.userId) {
      setCId(customer?.userId)
    }
  }, [customer])
  const { data, isSuccess, refetch } = useCustomerByIdEcomQuery({ id: cId })
  useEffect(() => {
    if (data) {
      setCustomerInfo(data)
    }
  }, [data])

  // console.log(data)
  const handleAddressRemove = async (address) => {
    // console.log(address)
    // console.log(data?.address)
    const newAddress = data?.address.filter((add) => add.id !== address.id)
    // console.log(newAddress)
    const confirm = window.confirm('Are you Sure? Delete this Address?')
    if (confirm) {
      await updateCustomerAddress({
        _id: customer?.userId,
        address: newAddress,
      })
        .then((res) => {
          // console.log(res)
          if (res.data) {
            // setAddchange(false);
            refetch()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  return (
    <div className='info'>
      {/* User basic information */}
      <div className='w-full p-4 bg-white shadow rounded-lg mb-4 border-b-4  border-red-500'>
        <div className='flex items-center justify-between mb-4'>
          <h3 className='text-xl font-medium text-gray-800'>
            Basic Information
          </h3>
          <button className='text-gray-500 hover:text-gray-700 cursor-pointer'>
            <label
              htmlFor='my-modal-9'
              className='flex items-center cursor-pointer text-red-500'
            >
              <AiFillEdit size={20} className='mr-2' /> Edit
            </label>
          </button>
        </div>
        <div className='grid grid-cols-2 p-5 gap-4'>
          <div className='col-span-1'>
            <p className='text-gray-800 mb-4'>
              <strong>Name:</strong> {customerInfo.name}
            </p>
            <p className='text-gray-800 mb-4'>
              <strong>Phone:</strong> {customerInfo.phone}
            </p>
            <p className='text-gray-800 mb-4'>
              <strong>Date Of Birth:</strong>{' '}
              {customerInfo?.dob
                ? format(new Date(customerInfo?.dob), 'MM/dd/yyyy')
                : '01/01/1990'}
            </p>
          </div>
          <div className='col-span-1'>
            <p className='text-gray-800 mb-4'>
              <strong>Email:</strong> {customerInfo.email}
            </p>
            <p className='text-gray-800 mb-4'>
              <strong>Gender:</strong>{' '}
              {customerInfo?.gender ? customerInfo?.gender : 'Male'}
            </p>
          </div>
        </div>
      </div>

      {/* User delivery address */}
      <div className='w-full p-4 bg-white shadow rounded-lg  border-b-4  border-red-500'>
        <div className='flex items-center justify-between mb-4'>
          <h3 className='text-xl font-medium text-gray-800'>
            Delivery Address
          </h3>
          <button
            onClick={addressChange}
            className='text-gray-500 hover:text-gray-700'
          >
            <label
              htmlFor='my-modal-8'
              className='flex items-center cursor-pointer'
            >
              <AiFillPlusCircle size={30} color='red' className='mr-2' /> Add
              Address
            </label>
          </button>
        </div>
        <div className='grid max-[640px]:grid-cols-1 max-[1280px]:grid-cols-2 grid-cols-3 gap-2 place-content-center -mx-4'>
          {/* <div className="flex flex-row flex-wrap justify-start -mx-4"> */}
          {/* Address card */}
          {data?.address?.map((add) => (
            <>
              <div className='w-full px-4 mb-4'>
                <div className='bg-red-50 rounded-lg p-4 border-dark border border-red-500 relative'>
                  <button
                    onClick={() => {
                      handleAddressRemove(add)
                    }}
                    className='absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700'
                  >
                    Remove
                  </button>
                  <p className='text-gray-800 mb-2'>
                    <strong>{add?.type}</strong>
                  </p>
                  <p className='text-gray-800 mb-2'>
                    <strong>House No:</strong>
                    {add?.holdingNo}
                  </p>
                  <p className='text-gray-800 mb-2'>
                    <strong>House No:</strong>
                    {add?.holdingNo}
                  </p>
                  <p className='text-gray-800 mb-2'>
                    <strong>Road No:</strong> {add?.street}
                  </p>
                  <p className='text-gray-800 mb-2'>
                    <strong>Sector:</strong> {add?.sector}
                  </p>
                  <p className='text-gray-800 mb-2'>
                    <strong>City:</strong> {add?.town}
                  </p>
                </div>
              </div>
            </>
          ))}

          {/* Add more address cards here */}
        </div>
      </div>
      <ChangeInfo />
    </div>
  )
}

const YourProfile = () => {
  const [userName, setUserName] = useState('Numan Talukder')
  const customer = useSelector((state) => state.customer)
  const [addchange, setAddchange] = useState(false)
  // console.log("Customer Redux:", customer);
  const changeProfile = () => {}
  const addressChange = () => {
    setAddchange(true)
  }

  const addressDel = () => {
    setAddchange(!addchange)
  }

  const dispatch = useDispatch()

  return (
    <>
      {/* <h1 className='text-3xl mt-3 md:text-left text-center md:pl-8'>
        Your Profile
      </h1> */}
      <div className='w-full md:h-screen mx-auto'>
        <div className='flex flex-col md:flex-row w-full'>
          <div className='md:w-1/4 w-full p-4'>
            <UserDashboardSidebar customer={customer} />
          </div>
          <div className='md:w-3/4 w-full p-4'>
            <BasicInfo customer={customer} addressChange={addressChange} />
          </div>
        </div>
      </div>

      <ChangeProfilePictureModal />

      <ChangeInfo />
      <ChangeAddress />

    </>
  )
}

export default YourProfile
