import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import HeroInfoPage from '../../Common/Hero/HeroInfoPage'
// import { setHeaderTitle } from '../../Redux/headerSlicer'

const TermsOfUse = () => {
  return (
    <>
      <HeroInfoPage />

      <section className='m-1 p-2 px-8 pl-12'>
        <h1 className='font-bold text-xl mb-5'>1. Terms of Use </h1>
        <p className='text-justify mb-5'>
          1.1 Welcome to TCM-bd.com. TCM Limited (“TCM”) provides access to the
          TCM-bd.com website or the mobile application/app or any other media
          ("Website") to you subject to the terms and conditions (“Terms”) set
          out on this page. By using the Website, you, a registered or guest
          user in terms of the eligibility criteria set out herein (“User”)
          agree to be bound by the Terms. Please read them carefully as your
          continued usage of the Website, you signify your agreement to be bound
          by these Terms. If you do not want to be bound by the Terms, you must
          not subscribe to or use the Website or our services.
        </p>
        <p className='text-justify mb-5'>
          1.2 By impliedly or expressly accepting these Terms, you also accept
          and agree to be bound by TCM Policies (including but not limited to
          Privacy Policy available at https://TCM-bd.com/privacy-policy) as
          amended from time to time.
        </p>
        <p className='text-justify mb-5'>
          1.3 In these Terms references to "you", and "User" shall mean the
          end-user/customer accessing the Website, its contents, and using the
          Services offered through the Website. References to the “Website”,
          "TCM", “TCM-bd.com”, "we", "us", and "our" shall mean the Website
          and/or TCM Limited.
        </p>
        <p className='text-justify mb-5'>
          1.4 The contents set out herein form an electronic record in terms of
          তথ্য ও যোগাযোগ প্রযুক্তি আইন, ২০০৬ (Information & Communication
          Technology Act, 2006) and rules there under as applicable and as
          amended from time to time. As such, this document does not require any
          physical or digital signatures and forms a valid and binding agreement
          between the Website and the User.
        </p>
        <p className='text-justify mb-5'>
          1.5 The Website is operated by TCM Limited., a company incorporated
          under the laws of Bangladesh having its registered office at 165 DIT
          Extension Road, Fakirapool, Dhaka, Bangladesh. All references to the
          Website in these Terms shall deem to refer to the aforesaid entity in
          inclusion of the online portal.
        </p>
        <p className='text-justify mb-5'>
          1.6 This Website may also contain links to other websites, which are
          not operated by TCM, and TCM has no control over the linked sites and
          accepts no responsibility for them or for any loss or damage that may
          arise from your use of them. Your use of the linked sites will be
          subject to the terms of use and service contained within each such
          site.
        </p>
        <p className='text-justify mb-5'>
          1.7 We reserve the right to change these Terms at any time. Such
          changes will be effective when posted on the Website. By continuing to
          use the Website after we post any such changes, you accept the Terms
          as modified.
        </p>
        <p className='text-justify mb-5'>
          1.8 These Terms will continue to apply until terminated by either You
          or TCM in accordance with the terms set out below:
        </p>
        <p className='text-justify mb-5'>
          1.9 The agreement with TCM can be terminated by (i) not accessing the
          Website; or (ii) closing Your Account, if such an option has been made
          available to You.
        </p>
        <p className='text-justify mb-5'>
          1.10 Notwithstanding the foregoing, these provisions set out in these
          Terms which by their very nature survive are meant to survive
          termination, shall survive the termination/expiry of this agreement.
        </p>

        <h1 className='font-bold text-xl mt-5 mb-5'>2. Eligibility </h1>
        <p className='text-justify mb-5'>
          2.1 Use of the Website is available only to such persons who can
          legally contract under the Contract Act, 1872.
        </p>
        <p className='text-justify mb-5'>
          2.2 If you are a minor i.e. under the age of 18 years, you shall not
          register as a user of TCM-bd.com and shall not transact on or use the
          Website. As a minor, if you wish to use or transact on a website, such
          use or transaction may be made by your legal guardian or parents on
          the Website. TCM reserves the right to terminate your membership
          and/or refuse to provide you with access to the website if it is
          brought to TCM’s notice or if it is discovered that you are under the
          age of 18 years.
        </p>
        <p className='text-justify mb-5'>
          2.3 By accepting the Terms or using or transacting on the Website, the
          User irrevocably declares and undertakes that he/she is of legal age
          i.e. 18 years or older, and capable of entering into a binding
          contract and such usage shall be deemed to form a contract between the
          Website and such User to the extent permissible under applicable laws.
        </p>
        <p className='text-justify mb-5'>
          2.4 The usage of the Services offered through the Website is only
          available if the address of the User to which the product(s) is/are to
          be delivered is within Dhaka City, Bangladesh; TCM is serving with 1
          Hour Delivery Service, to Uttara, Tongi, Mirpur, Cantonment, Banani,
          Banani DOHS, Gulshan, Baridhara, Baridhara DOHS, Badda, Mohakhali,
          Mohakhali DOHS, Khilgaon, Paltan, Motijheel, Banasree, Moghbazar,
          Mailbag, Razarbag, Kallyanpur, Shyamoli, Paikpara, Pallabi, and
          Mohammedpur. For the rest of Dhaka city, TCM is currently delivering
          within a 4-7 hours timeframe. Subject to the above, if the user
          specifies a convenient time and TCM will send the products during that
          time.
        </p>

        <h1 className='font-bold text-xl mt-5 mb-5'>3. Communication </h1>
        <p className='text-justify mb-5'>
          3.1 When you use TCM-bd.com or contact us via phone or email, you
          consent to receive communication from us. You authorize us to
          communicate with you by e-mail, SMS, phone call, or by posting notices
          on the website or by any other mode of communication. For contractual
          purposes, you consent to receive communications (including
          transactional, promotional, and/or commercial messages), from us with
          respect to your use of the website and/or your order placed on the
          Website. Additionally, any disclosures posted on the site or sent to
          you by email fulfill the legal obligation of communication made in
          writing.
        </p>

        <h1 className='font-bold text-xl mt-5 mb-5'>
          4. Your Account and Responsibilities
        </h1>
        <p className='text-justify mb-5'>
          4.1 Any person may access the Website either by registering to the
          Website or using the Website as a guest. However, a guest user may not
          have access to all sections of the Website including certain
          benefits/promotional offers, which shall be reserved only for the
          purpose of registered Users, and which may change from time to time at
          the sole discretion of the Website.
        </p>
        <p className='text-justify mb-5'>
          4.2 If you wish to register your account with the Website, you shall
          be required to create an account by registering with a valid
          Bangladeshi mobile number and/or with your Facebook account or your
          email address or by filling in the details prescribed on the Website
          registration form. You will then receive a password or one-time PIN
          with which you can log in to the website to place orders.
        </p>
        <p className='text-justify mb-5'>
          4.3 If you use the website, you will be responsible for maintaining
          the confidentiality of your username and password and you will be
          responsible for all activities that occur under your username and you
          will be under the obligation to restrict access to your computer or
          mobile phone to prevent unauthorized access to your account. You
          should inform us immediately if you have any reason to believe that
          your password has become known to anyone else, or if the password is
          being, or is likely to be, used in an unauthorized manner. You agree
          that if you provide any information that is untrue, inaccurate, not
          current, or incomplete or we have reasonable grounds to suspect that
          such information is untrue, inaccurate, not current or incomplete, or
          not in accordance with this Terms of Use, we have the right to suspend
          or terminate your membership on the website.
        </p>
        <p className='text-justify mb-5'>
          4.4 The Website may be inaccessible for such purposes as it may, at
          its sole discretions deem necessary, including but not limited to
          regular maintenance. However, under no circumstances will TCM be held
          liable for any losses or claims arising out of such inaccessibility to
          the Users and the Users expressly waive any claims against TCM in this
          regard
        </p>
        <p className='text-justify mb-5'>
          4.5 TCM as a platform does not support or encourage multiple accounts
          owned by a single user even with different mobile numbers & reserves
          the right to restrict, deactivate or disable any account that might
          fall under any sort of abuse of the service.
        </p>
      </section>
    </>
  )
}

export default TermsOfUse
