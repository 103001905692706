import { CheckCircle } from '@emotion-icons/heroicons-outline'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { selectCustomerEmail, selectCustomerMember, selectCustomerName, selectCustomerUserName } from '../../../../features/customerSlice'
import { useCustomerByIdEcomQuery, useUpdateEcomCustomerMutation } from '../../../../services/customerApi'

const ChangeInfo = () => {
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm();
  // console.log("customer", customer?.userId)
  const [cId, setCId] = useState("")

  const [fistName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [updateCustomerInfo] = useUpdateEcomCustomerMutation()
  useEffect(() => {
    if (customer) {
      setCId(customer?.userId)
    }
  }, [customer])
  useEffect(() => {
    if (customer?.userId) {
      setCId(customer?.userId)
    }
  }, [customer])
  const { data, isSuccess } = useCustomerByIdEcomQuery({ id: cId })
  // console.log(data)
  useEffect(() => {
    if (data) {
      const names = data.name.split(' ');
      // console.log("inputArray", names)
      setFirstName(names[0])
      setLastName(names[1])

    }
  }, [data])

  const onSubmit = async (subData) => {
    // console.log("before save", subData)
    // console.log(subData?.firstName)
    const fname = subData?.fistName ? subData?.fistName : fistName
    const lname = subData?.lastName ? subData?.lastName : lastName
    const nameFull = fname + " " + lname
    // console.log("nameFull", nameFull)
    const customerData = {
      // phone: subData?.phone ? subData.phone : data?.phone,
      name: nameFull,
      // lastName: subData?.lastName ? subData?.lastName : lastName,
      email: subData?.email ? subData?.email : data?.email,
      gender: subData?.gender ? subData?.gender : data?.gender,
      dob: subData?.dob ? subData?.dob : data?.dob,
    }
    const customerUpdate = { cId, customerData }
    // console.log("customerData", customerData)
    // console.log("customerUpdate", customerUpdate)
    await updateCustomerInfo(customerUpdate)
      .then(res => {
        // console.log("res", res)
        dispatch(selectCustomerName(res?.data?.name))
        dispatch(selectCustomerEmail(res?.data?.email))
        dispatch(selectCustomerUserName(res?.data?.username))
        // dispatch(selectCustomerMember(res?.data?.membership))
        toast.success('User Info update Successfully', {
          position: toast.POSITION.TOP_RIGHT,// customize the position
          autoClose: 1000,
        });
      })
      .catch(err => {
        console.log(err)
        toast.error('Please Give Correct Information', {
          position: toast.POSITION.TOP_RIGHT,// customize the position
          autoClose: 1000,
        });
      })

  }
  return (
    <>
      <div className=''>
        <input type='checkbox' id='my-modal-9' className='modal-toggle' />
        <label
          htmlFor='my-modal-9'
          className='modal cursor-pointer overflow-y-auto '
        >
          <label
            className='bg-white rounded-md p-0 w-3/5 max-h-max m-auto'
            htmlFor=''
          >
            <div className='w-full'>
              <div className='modal-action'>
                <label
                  htmlFor='my-modal-9'
                  className='btn btn-sm btn-circle mr-10 cursor-pointer md:text-bold'
                >
                  ✕
                </label>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col justify-center items-center w-full m-auto'>
                  <div className='flex items-center justify-center w-11/12'>
                    <div className='flex flex-col justify-center items-start w-1/2 mx-auto mr-1 mb-2'>
                      <h1 className='text-left'>First Name</h1>
                      <input
                        {...register("fistName")}
                        type='text'
                        placeholder=''
                        className='input input-bordered w-full'
                        defaultValue={fistName}
                      />
                    </div>
                    <div className='flex flex-col justify-center items-start w-1/2 mx-auto mb-2'>
                      <h1 className='text-left'>Last Name</h1>
                      <input
                        {...register("lastName")}
                        type='text'
                        placeholder=''
                        className='input input-bordered w-full'
                        defaultValue={lastName}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col justify-center items-start w-11/12 m-auto mb-2'>
                    <h1>Email</h1>
                    <input
                      {...register("email")}
                      type='text'
                      placeholder=''
                      className='input input-bordered w-full'
                      defaultValue={data?.email}
                    />
                  </div>
                  <div className='flex flex-col justify-center items-start w-11/12 m-auto mb-2'>
                    <h1>Phone Number</h1>
                    <input
                      {...register("phone")}
                      type='text'
                      placeholder=''
                      className='input input-bordered w-full'
                      defaultValue={data?.phone}
                      disabled
                    />
                  </div>
                  <div className='flex items-center justify-center w-11/12'>
                    <div className='flex flex-col justify-center items-start w-1/2 mx-auto mr-1 mb-2'>
                      <h1 className='text-left'>Gender</h1>
                      <select
                        {...register("gender")}
                        defaultValue={data?.gender}
                        className='select select-bordered w-full'>
                        <option disabled>
                          Select One
                        </option>
                        <option selected value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className='flex flex-col justify-center items-start w-1/2 mx-auto mb-2'>
                      <h1 className='text-left'>Date of Birth</h1>
                      <input
                        {...register("dob")}
                        defaultValue={data?.dob}
                        type='date'
                        placeholder=''
                        className='input input-bordered w-full'
                      />
                    </div>
                  </div>
                  <div className="mb-5">
                    <button type="submit" value="Save" className="btn w-full">
                      <CheckCircle size={18} /> Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </label>
        </label>
      </div>
    </>
  )
}

export default ChangeInfo;
