import React from "react";
import Photo from "../../../Assets/product.jpg";

const ProductDetails = () => {
  return (
    <>
      <div className='container mt-5'>
        <div className='grid grid-cols-3 gap-2 place-content-center p-7'>
          <div className='...'>
            <div className='card w-11/12 '>
              <img src={Photo} alt='' />
              <div className='grid grid-cols-3 gap-2 place-content-center p-7'>
                <div className='avatar'>
                  <div className='w-20 rounded'>
                    <img src={Photo} alt='' />
                  </div>
                </div>
                <div className='avatar'>
                  <div className='w-20 rounded'>
                    <img src={Photo} alt='' />
                  </div>
                </div>
                <div className='avatar'>
                  <div className='w-20 rounded'>
                    <img src={Photo} alt='' />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 leading-7">
            <h1 className="text-slate-900">
              Smart Care Wet Wipes With Fliptop 120Pcs
            </h1>
            <h1 className="text-slate-900">category Name</h1>
            <h3 className="font-bold  text-lg">
              45
              <span>
                {" "}
                ৳<small className="text-sm font-normal"> / Kg</small>
              </span>
            </h3>
            {/* counter */}

            <div className="custom-number-input h-10 w-32 mt-3">
              <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button
                  data-action="decrement"
                  className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                >
                    
                  <span className='m-auto text-2xl font-thin'>-</span>

                </button>
                <input
                  type="number"
                  className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                  name="custom-input-number"
                  value="0"
                ></input>
                <button
                  data-action="increment"
                  className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                >
                  <span className="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
            <br />
            <h1 className="text-slate-900 font-black">Description</h1>

            <h2>
              Effective product descriptions can possibly lure potential
              customers. Good product descriptions can potentially influence a
              purchase decision. Great product descriptions can ultimately help
              improve conversion rates and increase sales, as well as boost your
              visibility and SEO on paid channels.
            </h2>
            {/* <h1 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">Smart Care Wet Wipes With Fliptop 120Pcs</h1> */}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 place-content-center p-7">
          <h1 className="text-slate-900 font-black">Releted Product</h1>
        </div>
        {/* <h1 className='text-slate-900 font-black'>Releted Product</h1> */}
        <div className="grid grid-cols-4 gap-4 place-content-center p-7">
          <div>
            <div className='card product-loop card-compact w-full bg-base-100 shadow-xl'>
              <img src={Photo} alt='' />
              <div
                className="tooltip"
                data-tip="Smart Care Wet Wipes With Fliptop 120Pcs"
              >
                <h5 className="card-title text-left text-sm">
                  Smart Care Wet Wipes With Fliptop 120Pcs
                </h5>
              </div>
              <div className="card-actions">
                <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                  <div className="row-span-1">
                    <h3 className="font-bold text-center text-lg">
                      45
                      <span>
                        {" "}
                        ৳<small className="text-sm font-normal"> / Kg</small>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='card product-loop card-compact w-full bg-base-100 shadow-xl'>
              <img src={Photo} alt='' />

              <div
                className="tooltip"
                data-tip="Smart Care Wet Wipes With Fliptop 120Pcs"
              >
                <h5 className="card-title text-left text-sm">
                  Smart Care Wet Wipes With Fliptop 120Pcs
                </h5>
              </div>
              <div className="card-actions">
                <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                  <div className="row-span-1">
                    <h3 className="font-bold text-center text-lg">
                      45
                      <span>
                        {" "}
                        ৳<small className="text-sm font-normal"> / Kg</small>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='card product-loop card-compact w-full bg-base-100 shadow-xl'>
              <img src={Photo} alt='' />

              <div
                className="tooltip"
                data-tip="Smart Care Wet Wipes With Fliptop 120Pcs"
              >
                <h5 className="card-title text-left text-sm">
                  Smart Care Wet Wipes With Fliptop 120Pcs
                </h5>
              </div>
              <div className="card-actions">
                <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                  <div className="row-span-1">
                    <h3 className="font-bold text-center text-lg">
                      45
                      <span>
                        {" "}
                        ৳<small className="text-sm font-normal"> / Kg</small>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='card product-loop card-compact w-full bg-base-100 shadow-xl'>
              <img src={Photo} alt='' />

              <div
                className="tooltip"
                data-tip="Smart Care Wet Wipes With Fliptop 120Pcs"
              >
                <h5 className="card-title text-left text-sm">
                  Smart Care Wet Wipes With Fliptop 120Pcs
                </h5>
              </div>
              <div className="card-actions">
                <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                  <div className="row-span-1">
                    <h3 className="font-bold text-center text-lg">
                      45
                      <span>
                        {" "}
                        ৳<small className="text-sm font-normal"> / Kg</small>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
