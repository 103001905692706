import React from 'react'
import { GiCash } from 'react-icons/gi'
import { RiMastercardFill } from 'react-icons/ri'
import { BsCash } from 'react-icons/bs'
import OrderDetails from '../OrderDetails/OrderDetails'
import { Link } from 'react-router-dom'

const Payment = () => {
  return (
    <>
      {/* <div className="grid grid-cols-1 place-content-center">
                <div>
                    <ul className="steps steps-vertical mt-5 mx-auto  lg:steps-horizontal">
                        <li className="step step-primary">Check Out</li>
                        <li className="step step-primary">Payment</li>
                    </ul>
                </div>
            </div> */}
      <div className='grid grid-cols-1'>
        <div className=''>
          <ul className='steps steps-vertical w-96 mt-5 mb-20  ml-96 lg:steps-horizontal'>
            <li className='step step-error'>Check Out</li>
            <li className='step step-error'>Payment</li>
            <li className='step'>Complete</li>
          </ul>
          {/* <div className="card w-96 bg-error-100 mx-auto mt-5 mb-5 shadow-xl">
                        <p className="italic text-center mt-20">Your order is on its ways</p>
                        <figure className="px-10 pt-10">
                            <img src="https://api.lorem.space/image/shoes?w=200&h=100" alt="Shoes" className="rounded-xl" />
                        </figure>
                        <div className="card-body items-center text-center">

                            <h2 className="card-title">Selected Delivery Slot</h2>
                            <p className='text-center'>6.30PM - 7.00PM</p>
                            <div className="card-actions">
                                <div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="...">
                                            <p className='mt-6 text-sm'>Pay with</p>
                                        </div>
                                        <div className="col-span-2">
                                            <p className='mt-6 text-sm'>Cash on delivery 57tk</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          {/* <p className='text-sm text-silver text-center'>OR</p> */}

          <div className='bg-white w-96 mx-auto rounded-lg shadow-lg mb-56'>
            <div className='grid grid-cols-3  gap-2'>
              <div className='col-span-2'>
                <p className='text-sm mt-6 ml-2'>Cash on Delivery</p>
              </div>
              <div className='...'>
                {/* <RiMastercardFill className='mt-4 ml-2'  style={{color:`red`}} /> */}
                <BsCash className='mt-6 ml-7' size={35} />
              </div>
            </div>
          </div>

          {/* <div className="bg-white w-96 mx-auto rounded-lg shadow-lg mb-2">
                        <div className="grid grid-cols-3  gap-2">

                            <div className="col-span-2">
                                <p className='text-sm mt-6 ml-1'>Bkash</p>
                            </div>
                            <div className="...">
                                <RiMastercardFill className='mt-4 ml-2'  style={{color:`red`}} />
                                <RiVisaFill className='mt-6 ml-7' size={35}/>
                            </div>
                        </div>
                    </div> */}
        </div>
        {/* <div className="col-span-2">
                    <OrderDetails />
                    <button className="bg-violet-500 ml-72 text-white active:bg-violet-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        <Link to="/"> Back to shopping</Link>
                    </button>
                </div> */}
      </div>
    </>
  )
}

export default Payment
