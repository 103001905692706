import React, { useState, useEffect } from "react";
import photo from "../../../Assets/product.jpg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsPlus, BsDash, BsBag } from "react-icons/bs";
import Photo from "../../../Assets/product.jpg";
import axios from "axios";
import "./ProductLoop.css";
import BASE_URL from "../../../Utlity/Base_url";
// import { addQuantity, addToCart } from "../../../hooks/addToCart";
// import { addToWishList } from "../../../hooks/addtoWishList";
import { useProductsQuery } from "../../../services/productApi";

const ProductLoop2 = ({ fp }) => {
  const [pro, setPro] = useState([]);
  const [singlepro, setSinglepro] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [featured, setFeatured] = useState([]);
  const [addchange, setAddchange] = useState(false);

  const page = 1;
  const size = 1;
  const q = "";

  // const addressChange = (_id) => {
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useProductsQuery({
      page,
      size,
      q,
    });
  // if (_id) {
  //   axios
  //     .get(`${BASE_URL}product/${_id}`)
  //     .then(function (response) {
  //       console.log(response.data);
  //       setSinglepro(response.data);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });

  //   setAddchange(true);
  // }
  // };

  // useEffect(() => {
  //   addressChange();
  // }, []);

  // const addressDel = () => {
  //   setAddchange(!addchange);
  // };
  // const cart = useSelector((state) => state)
  // const dispatch = useDispatch()

  // console.log(cart)

  //    var apiHeader=`https://pos-api-v1.herokuapp.com/api`;

  // const ProView = async () => {
  //   await axios
  //     .get(`${BASE_URL}ecom/product/1/20`)
  //     .then(function (response) {
  //       console.log(response.data);
  //       setPro(response.data);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    // ProView();
  }, []);

  // const handleAddToCart = (item) => {
  //   console.log("cart item", item);
  //   const add = addToCart(item);
  //   // const addQty = addQuantity(item.article_code)
  //   console.log("add", add);
  //   if (!add) {
  //     const addQty = addQuantity(item.article_code);
  //   }

  //   // console.log("add", addQty)
  // };
  // const handleAddToWishList = (item) => {
  //   console.log("wish item", item);
  //   const add = addToWishList(item);
  //   console.log("wishList", add);
  // };

  return (
    <>
      {pro.slice(0, 10).map((item) => {
        item.qty = 1;
        item.wishlist = 1;
        return (
          <>
            <div className="card product-loop card-compact w-full bg-base-100 shadow-xl">
              <figure>
                <img src={Photo} alt={item.name}/>
              </figure>
              <div className="card-body">
                <div className="tooltip" data-tip={item.name}>
                  <h5 className="card-title text-left text-xs">{item.name}</h5>
                </div>
                <div className="card-actions">
                  <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                    <div
                      className="row-span-1"
                      // onClick={() => addressChange(item._id)}
                    >
                      <h3 className="font-bold  text-lg">
                        {item?.promo_price ? (
                          <span
                            className={`{item?.promo_price ? line-through text-sm : ''}`}
                          >
                            {item?.priceList[0]?.mrp}
                          </span>
                        ) : (
                          `${item?.priceList[0]?.mrp}`
                        )}

                        {item?.promo_price ? " / " + item.promo_price : ""}

                        {/* {
                                                        item.promo_price ? <del>{item?.priceList[0]?.mrp}</del> / `${item.promo_price}` : <span>
                                                            {item?.priceList[0]?.mrp}
                                                        </span>
                                                    } */}

                        <span>
                          {" "}
                          ৳
                          <small className="text-sm font-normal">
                            {" "}
                            {item.unit}
                          </small>
                        </span>
                      </h3>
                    </div>

                    <div className="row-span-1">
                      <div className="flex">
                        <div className="flex-none">
                          <button
                            // onClick={() =>
                            //   dispatch({ type: 'WishList', payload: item })
                            // }
                            // onClick={() => handleAddToWishList(item)}
                            className="float-right p-1"
                          >
                            <AiOutlineHeart
                              className=""
                              size={25}
                            ></AiOutlineHeart>
                          </button>
                        </div>
                        <div className="flex-initial"></div>
                        <div className="flex-1">
                          <div className="  w-12/12">
                            <button
                              // onClick={() =>
                              //   dispatch({ type: 'ADD', payload: item })
                              // }
                              // onClick={() => handleAddToCart(item)}
                              style={{ background: "tomato" }}
                              className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle"
                            >
                              <BsPlus className="" size={25}></BsPlus>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default ProductLoop2;
