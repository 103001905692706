import React, { useEffect, useState } from "react";
import photo from "../../../assats/noDataPhoto.png";
import { useNavigate, useParams } from "react-router-dom";
import ProductLoop from "../../Common/Loop/ProductLoop";
import { useProductByCategoryQuery } from "../../../services/productApi";
import ProductLoopLoader from "../../Common/Loader/ProductLoopLoader";
import LoadingModal from "../../Common/Modal/LoadingModal";

const Category = () => {
  let { id } = useParams();
  // console.log(id);
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useProductByCategoryQuery({ id });

  // console.table("data", data, error);
  // if (isLoading) {
  //   return <LoadingModal />
  // }

  // console.log("data", data);
  return (
    <div className=" mt-5">
      {data?.length > 0 ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 max-[640px]:grid-cols-2  gap-4 place-content-center p-7">
          {isLoading ? (
            <LoadingModal
              title={"Please Wait"}
              onShow={loader}
              handleClose={handleLoaderClose}
            />
          ) : (
            data?.map((item) => {
              return <ProductLoop fp={item} />;
            })
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center animate-bounce duration-400 xxl:pt-20 xl:pt-20 lg:pt-10">
          <div><img src={photo} alt="" className="w-52 object-cover" />
          <p className=" text-center text-2xl font-semibold text-gray-500 -mt-10">Products Not Found</p></div>
        </div>
      )}
    </div>
  );
};

export default Category;
