import React, { useEffect, useState, useMemo } from "react";
import Hero from "../../Common/Hero/Hero";
import photo from "../../../assats/noDataPhoto.png";
import { Link, useParams } from "react-router-dom";
import CategoryLoop from "../../Common/Loop/CategoryLoop";
import { useCategoryByMcQuery } from "../../../services/categoryApi";

const MasterCategory = () => {
  const { mc } = useParams();
  const { data, isSuccess } = useCategoryByMcQuery({ mc });

  // console.table(data);
  return (
    <>
      <Hero />
      <div className="container mt-5">
        {data?.length > 0 ? (
          <div className="grid max-[640px]:grid-cols-2 md:grid-cols-4 sm:grid-cols-3  lg:grid-cols-6 gap-2 place-content-center p-7">
            {data?.map((item) => {
              return <CategoryLoop category={item} key={item._id} />;
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center animate-bounce duration-400 xxl:pt-20 xl:pt-20 lg:pt-10">
            <div>
              <img src={photo} alt="" className="w-52 object-cover" />
              <p className=" text-center text-2xl font-semibold text-gray-500 -mt-10">
                Products Not Found
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MasterCategory;
