import React, { useEffect, useState } from "react";
import "../Model/Model.css";
import photo from "../../../Assets/logo.png";
import { Link, Navigate } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useCustomerIsPhoneMutation, useUpdateCustomerPasswordMutation } from "../../../services/customerApi";
import sendMessage from "../../../Utlity/smsSystem";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../features/authSlice";
import { selectCustomer } from "../../../features/customerSlice";
import { toast } from "react-toastify";

const Forgot = ({ }) => {
  const mobileRef = useRef()
  const otpRef = useRef()
  const newPasswordRef = useRef()
  const retypeNewPasswordRef = useRef()
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const [isPassWordReset] = useUpdateCustomerPasswordMutation()
  const [isPhoneFound] = useCustomerIsPhoneMutation()
  const [isOTP, setIsOTP] = useState(false)
  const [phonePage, setPhonePage] = useState(true)
  const [newPassword, setNewPassword] = useState(false)

  useEffect(() => {
    if (userData.isLogin !== false) {
      console.log("data", userData.isLogin);
      Navigate("/");
    }
  }, []);
  const generateOTP = () => {
    const min = 100000; // minimum value
    const max = 999999; // maximum value

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const clearLocalStorageData = (key, maxAge) => {
    // console.log(key, maxAge)
    const interval = setInterval(() => {
      const item = localStorage.getItem(key);
      if (!item) return;

      const { timestamp } = JSON.parse(item);
      const age = new Date().getTime() - timestamp;

      if (age > maxAge) {
        localStorage.removeItem(key);
        clearInterval(interval);
      }
    }, 60000);
  }

  const handleMobileOTPConfirm = () => {
    // console.log(otpRef.current.value)
    const otp = otpRef.current.value
    const localOtp = JSON.parse(localStorage.getItem("otp"))
    // console.log(otp, localOtp)
    if (otp == localOtp) {
      // console.log("otp matched")
      setNewPassword(true)
      setIsOTP(false)
      // setPhonePage(false)
      // console.log("newPassword", newPassword)
    }
  }
  const handlePassWordSet = async () => {
    // console.log(newPasswordRef.current.value)
    // console.log(retypeNewPasswordRef.current.value)
    const newPassword = newPasswordRef.current.value.trim()
    const retypeNewPassword = retypeNewPasswordRef.current.value.trim()
    // console.log(newPassword, retypeNewPassword)
    if (newPassword == retypeNewPassword) {
      // console.log("matched")
      const phone = JSON.parse(localStorage.getItem("phone"))
      // console.log("matched", phone)
      const update = { phone, password: newPassword }
      // console.log("update", update)
      await isPassWordReset(update)
        .then(res => {
          // console.log(res)
          if (res?.data?.status === true) {
            dispatch(
              loginUser({
                accessToken: res?.data?.access_token,
                userId: res?.data?.user?.id,
                userName: res?.data?.user?.username,
                point: res?.data?.user?.point,
                phone: res?.data?.user?.phone,
                type: res?.data?.user?.type,
                name: res?.data?.user?.name,
              })
            );
            dispatch(
              selectCustomer({
                userId: res?.data?.user?.id,
                point: res?.data?.user?.point,
                phone: res?.data?.user?.phone,
                type: res?.data?.user?.type,
                name: res?.data?.user?.name,
                email: res?.data?.user?.email,
                username: res?.data?.user?.username,
                membership: res?.data?.user?.membership,
                address: res?.data?.user?.address,
              })
            );
            localStorage.setItem("access_token", res?.data?.access_token);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));
          }
          toast.success('Welcome', {
            position: toast.POSITION.TOP_RIGHT,// customize the position
            autoClose: 1000,
          });

        })
        .catch(err => {
          console.log(err)
          toast.error('Please Check Your Password', {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,// customize the position
          });
        })
    }

  }
  const handleMobileOTPSend = async () => {
    // console.log(mobileRef.current.value)
    const phone = mobileRef.current.value
    if (phone?.trim()?.length > 0) {
      // console.log("phone", phone)
      await isPhoneFound({ phone: phone })
        .then(res => {
          // console.log(res)
          if (res?.data?.isFound === true) {
            // console.log("phone1", phone)
            setIsOTP(true)
            setPhonePage(false)
            const otp = generateOTP()
            // console.log("otp", otp)
            localStorage.removeItem("phone")
            localStorage.setItem("phone", JSON.stringify(phone))
            localStorage.removeItem("otp")
            localStorage.setItem("otp", JSON.stringify(otp))
            const timestamp = new Date().getTime();
            localStorage.removeItem("time")
            localStorage.setItem("time", JSON.stringify(timestamp));
            clearLocalStorageData("otp", 5 * 60 * 1000)
            const sms = "Your OTP for Password reset is " + otp
            sendMessage(phone, sms)
          }

        })
        .catch(err => {
          console.log(err)

        })
    }
  }
  return (
    <>
      {/* <!-- ====== Forms Section Start --> */}
      {
        phonePage === true && <section className="overflow-x-hidden">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div
                  className="
                                  max-w-[525px]
                                  mx-auto
                                  text-center
                                  bg-white
                                  rounded-lg
                                  relative
                                  overflow-hidden
                                  py-8
                                  px-10
                                  sm:px-12
                                  md:px-[60px]
                                  "
                >
                  <div className="mb-1 md:mb-1 text-center"></div>

                  <div className="grid grid-cols-1  mb-5 md:mb-10">
                    <div className="text-center">
                      <h1 className="text-3xl text-red-500 ml-5">
                        Recovery Mode
                      </h1>
                    </div>
                  </div>

                  <div className="mb-6">
                    <input
                      ref={mobileRef}
                      type="text"
                      placeholder="Enter Phone Number"
                      className="w-full rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-red-400"
                    />
                  </div>

                  <div className="mb-10">

                    <button
                      onClick={() => { handleMobileOTPSend() }}
                      className="
                                                  w-full
                                                  rounded-md
                                                  border
                                                  py-3
                                                  px-5
                                                  bg-red-500
                                                  text-base text-white
                                                  cursor-pointer
                                                  hover:bg-opacity-90
                                                  transition
                                                  "
                    >Send Reset Code</button>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {/* <!-- ====== Forms Section End --> */}
      {
        isOTP === true && <section className="overflow-x-hidden">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div
                  className="
                                    max-w-[525px]
                                    mx-auto
                                    text-center
                                    bg-white
                                    rounded-lg
                                    relative
                                    overflow-hidden
                                    py-8
                                    px-10
                                    sm:px-12
                                    md:px-[60px]
                                    "
                >
                  <div className="mb-1 md:mb-1 text-center"></div>

                  <div className="grid grid-cols-1  mb-5 md:mb-10">
                    <div className="text-center">
                      <h1 className="text-3xl text-red-500 ml-5">
                        Recovery Mode
                      </h1>
                    </div>
                  </div>

                  <div className="mb-6">
                    <input
                      ref={otpRef}
                      type="text"
                      placeholder="Enter OTP"
                      className="w-full rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-10">

                    <button
                      onClick={() => { handleMobileOTPConfirm() }}
                      className="
                                                    w-full
                                                    rounded-md
                                                    border
                                                    border-primary
                                                    py-3
                                                    px-5
                                                    bg-primary
                                                    text-base text-white
                                                    cursor-pointer
                                                    hover:bg-opacity-90
                                                    transition
                                                    "
                    >Confirm OTP</button>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {
        newPassword === true && <section className="overflow-x-hidden">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div
                  className="
                                  max-w-[525px]
                                  mx-auto
                                  text-center
                                  bg-white
                                  rounded-lg
                                  relative
                                  overflow-hidden
                                  py-8
                                  px-10
                                  sm:px-12
                                  md:px-[60px]
                                  "
                >
                  <div className="mb-1 md:mb-1 text-center"></div>

                  <div className="grid grid-cols-1  mb-5 md:mb-10">
                    <div className="text-center">
                      <h1 className="text-3xl text-[#570DF8] ml-5">
                        Recovery Mode
                      </h1>
                    </div>
                  </div>

                  <div className="mb-6">
                    <input
                      ref={newPasswordRef}
                      type="password"
                      placeholder="New Password"
                      className="w-full rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      ref={retypeNewPasswordRef}
                      type="password"
                      placeholder="Retype New Password"
                      className="w-full rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-10">

                    <button
                      onClick={() => { handlePassWordSet() }}
                      className="
                                                  w-full
                                                  rounded-md
                                                  border
                                                  bordder-primary
                                                  py-3
                                                  px-5
                                                  bg-primary
                                                  text-base text-white
                                                  cursor-pointer
                                                  hover:bg-opacity-90
                                                  transition
                                                  "
                    >Confirm Password</button>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default Forgot;