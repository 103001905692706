import React, { useState } from 'react'
import { Button, Card, Modal } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useCustomerByIdEcomQuery, useUpdateCustomerAddressMutation } from '../../../../services/customerApi';
import { v4 as uuidv4 } from 'uuid';

const ChangeAddress = ({ addchange, addressDel }) => {
  const customer = useSelector((state) => state.customer);
  const [openForm, setOpenForm] = useState(false)
  const { register, handleSubmit, reset } = useForm({});
  const dispatch = useDispatch();
  // console.log("customer", customer)
  const [updateCustomerAddress] = useUpdateCustomerAddressMutation()
  const { data, isSuccess, refetch } = useCustomerByIdEcomQuery({
    id: customer?.userId
  })
  // console.log("address", data)
  const handleAddressAdd = (address) => {
    // console.log("hi")
    const addressString = "House - " + address?.holdingNo + ", Road - " + address?.street + ", Sector - " + address?.sector + ", Town - " + address?.town + "," + address?.city + "-" + address?.zipCode
    // dispatch(selectCustomerDeliveryAddress({
    //   address: addressString,
    //   phone: customer?.phone
    // }))
  }
  const handleAddressDel = async (address) => {
    // console.log(address)
    // console.log(data?.address)
    const newAddress = data?.address.filter(add => add.id !== address.id)
    // console.log(newAddress)
    const confirm = window.confirm("Are you Sure? Delete this Address?");
    if (confirm) {

      await updateCustomerAddress({
        _id: customer?.userId,
        address: newAddress
      })
        .then(res => {
          // console.log(res)
          if (res.data) {
            // setAddchange(false);
            refetch()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

  }
  const onSubmit = async (address) => {
    // console.log("new address data", address)
    const uid = uuidv4()
    const newData = { ...address, id: uid }
    // console.log("customer", customer)
    // console.log("customer", data?.address)
    // console.log("new customer", [...data?.address, newData])
    const newAddress = [...data?.address, newData]
    await updateCustomerAddress({
      _id: customer?.userId,
      address: newAddress
    })
      .then(res => {
        // console.log(res)
        if (res.data) {
          // setAddchange(false);
          refetch()
          setOpenForm(!openForm)
        }
      })
      .catch(err => {
        console.log(err)
      })

  }

  return (
    // <>
    //   <div className=''>
    //     <input type='checkbox' id='my-modal-8' className='modal-toggle' />
    //     <label
    //       htmlFor='my-modal-8'
    //       className='modal cursor-pointer overflow-y-auto '
    //     >
    //       <label
    //         className='bg-white rounded-md p-0 w-3/5 max-h-max m-auto'
    //         htmlFor=''
    //       >
    //         <div className='w-full'>
    //           <div className='modal-action'>
    //             <label
    //               htmlFor='my-modal-8'
    //               className='btn btn-sm btn-circle mr-10 cursor-pointer md:text-bold'
    //             >
    //               ✕
    //             </label>
    //           </div>
    //           <div className='flex flex-col justify-center items-center w-full m-auto'>
    //             <div className='flex justify-center items-center w-11/12 mx-auto mb-2'>
    //               <select className='select select-bordered w-1/4 mr-1'>
    //                 <option disabled selected>
    //                   Select Label
    //                 </option>
    //                 <option>Primary</option>
    //                 <option>Secondary</option>
    //                 <option>Tertiary</option>
    //               </select>
    //               <input
    //                 type='text'
    //                 placeholder='Previous Address will be shown here'
    //                 className='input input-bordered w-3/4'
    //               />
    //             </div>
    //             <div className='flex justify-center items-center w-11/12 m-auto mb-2'>
    //               <select className='select select-bordered w-1/4 mr-1'>
    //                 <option disabled selected>
    //                   Select Label
    //                 </option>
    //                 <option>Primary</option>
    //                 <option>Secondary</option>
    //                 <option>Tertiary</option>
    //               </select>
    //               <input
    //                 type='text'
    //                 placeholder='Previous Address will be shown here'
    //                 className='input input-bordered w-3/4'
    //               />
    //             </div>
    //             <div className='flex items-center justify-center w-11/12'>
    //               <div>
    //                 <h1 className='btn m-2'>
    //                   <span className='text-xl mr-1'>+</span> Add Another
    //                   Address
    //                 </h1>
    //               </div>
    //               <div>
    //                 <h1 className='btn m-2'>Save</h1>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </label>
    //     </label>
    //   </div>
    // </>
    <>
      <Modal open={addchange} className='bg-white relative'>
        <Modal.Header className="font-bold">
          New Address
        </Modal.Header>

        <Modal.Body>



          <div className="card w-full bg-base-100 shadow-xl m-2">
            <div className="card-body">
              {/* <h2 className="card-title">New Address Details</h2> */}
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Type</label>
                  <select {...register("type")}
                    className="input input-bordered"
                  >
                    <option selected value="Home">Home</option>
                    <option value="Work">Work</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Holding Number</label>
                  <input
                    {...register("holdingNo")}
                    type="text"
                    className="input input-bordered"
                    placeholder="Holding Number"
                    required
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Road</label>
                  <input
                    {...register("street")}
                    type="text"
                    className="input input-bordered"
                    placeholder="Road"
                    required
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Sector</label>
                  <select {...register("sector")}
                    className="input input-bordered"
                  >
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option selected value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Town</label>
                  <select {...register("town")}
                    className="input input-bordered"
                  >
                    <option selected value="Uttara">Uttara</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">City</label>
                  <select {...register("city")}
                    className="input input-bordered"
                  >
                    <option selected value="Dhaka">Dhaka</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Division</label>
                  <select {...register("division")}
                    className="input input-bordered"
                  >
                    <option selected value="Dhaka">Dhaka</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Zip Code</label>
                  <select {...register("zipCode")}
                    className="input input-bordered"
                  >
                    <option selected value="1230">1230</option>
                  </select>
                </div>
                <div className="form-control w-full max-w-xs mt-3">
                  <label className='mb-2' htmlFor="MCId">Country</label>
                  <select {...register("country")}
                    className="input input-bordered"
                  >
                    <option selected value="BD">Bangladesh</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="btn btn-dark col-8 col-md-8 mt-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>

          <Button
            size="sm"
            shape="circle"
            className="absolute right-2 top-2"
            onClick={addressDel}
          >
            ✕
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangeAddress
