import React from 'react'
import { Link } from 'react-router-dom'
import { FaCheckCircle } from 'react-icons/fa'

const SuccessModal = () => {
  return (
    <>
      <div className='modal' id='my-modal-10'>
        <div className='bg-white flex flex-col items-center justify-center w-4/5 md:w-2/5 px-6 md:px-16 py-4 md:py-10 rounded-lg'>
          <h3 className='font-bold text-md md:text-lg p-2 mb-2'>
            Your order has been received
          </h3>
          <FaCheckCircle style={{ color: '#5cb85c' }} size={52} />
          <h3 className='mt-4 mb-2'>Thank you for your purchase!</h3>
          <p>Your order id is: {new Date().getTime().toString()}</p>
          <div className='modal-action'>
            <Link to={'/'} href='#' className='btn btn-error'>
              Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessModal
