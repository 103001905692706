import React, { useState, useEffect, useRef } from "react";
import logo from "../../../../Assets/logo.png";
import CustomerHook from "../../../../hooks/useCustomerHook";
import CartHook from "../../../../hooks/useCartHook";
import {
  BsChatDotsFill,
  BsChatDots,
  BsThreeDots,
  BsCart,
  BsCartFill,
  BsHeart,
  BsHeartFill,
} from "react-icons/bs";
import { RiUser3Line } from "react-icons/ri";
import "../StickyHeader/Sticky.css";
import Wishlist from "../../Wishlist/Wishlist";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  resetAuth,
  setAccessToken,
  setIsLogin,
  setUserData,
} from "../../../../features/authSlice";
import { selcetProduct, setTotalPrice } from "../../../../features/orderSlice";
import Cart from "../../Cart/Cart";
import { selectCustomer } from "../../../../features/customerSlice";
import { notify } from "../../../../Utlity/Notify";
import SelectProduct from "../../../../CustomSelect/ProductSelect";

const StickyHeader = ({ notification, chat, handleSideBar }) => {
  const authData = useSelector((state) => state.auth);
  const cartData = useSelector((state) => state.order);
  const wishListData = useSelector((state) => state.customer.wishList);
  const customer = useSelector((state) => state.customer);
  const { isLogin, getUser, accessToken, updateAuth } = CustomerHook();
  const { getStoredCart, totalPrice } = CartHook();

  const [selectedOption, setSelectedOption] = useState([]);
  const [products, setProducts] = useState([]);
  const [isWishList, setIsWishList] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [dropDownState, setDropDownState] = useState(false);
  const dropDownMenuRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsRef = useRef(null);

  const handleProductChange = (e) => {
    // console.log(e)
    navigate(`/product/${e._id}`);
  };

  function setFixed() {
    if (window.scrollY >= 392) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }

  useEffect(() => {
    if (authData.accessToken === null) {
      dispatch(setIsLogin(false));
    }
  }, [authData]);

  useEffect(() => {
    window.addEventListener("scroll", setFixed);
    return () => {
      window.removeEventListener("scroll", setFixed);
    };
  }, []);

  const toggleDrawer = () => {
    setIsWishList((prevState) => !prevState);
  };
  const toggleCartDrawer = () => {
    setIsCartOpen((prevState) => !prevState);
  };

  const signOut = () => {
    localStorage.setItem("access_token", null);
    localStorage.setItem("user", null);
    dispatch(resetAuth());
    navigate("/login");
  };

  const checkOut = () => {
    if (cartData?.products?.length > 0) {
      navigate("/check-out");
      setIsCartOpen(false);
    } else {
      notify("Please Add Some Products");
    }
  };

  useEffect(() => {
    let options = [];
    products?.slice(0, 50).map((pro) =>
      options.push({
        option: pro.article_code,
        label: `${pro.article_code} - ${pro.name} (${pro.price})`,
      })
    );
    setSelectedOption(options);
  }, [products]);

  useEffect(() => {
    const access_token = accessToken();
    const userData = getUser();
    const localCart = getStoredCart();

    dispatch(selcetProduct(localCart));

    if (access_token !== "null") {
      dispatch(setAccessToken(access_token));
    }
    dispatch(
      setUserData({
        userId: userData?.id,
        userName: userData?.username,
        point: userData?.point,
        phone: userData?.phone,
        type: userData?.type,
        name: userData?.name,
      })
    );
    dispatch(
      selectCustomer({
        userId: userData?.userId,
        name: userData?.name,
        email: userData?.email,
        username: userData?.username,
        membership: userData?.membership,
        address: userData?.address,
        type: userData?.type,
        point: userData?.point,
        phone: userData?.phone,
      })
    );
  }, []);

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        dropDownMenuRef.current &&
        !dropDownMenuRef.current.contains(e.target)
      ) {
        setDropDownState(false);
      }
    };
    document.addEventListener("mousedown", closeDropDown);
    return () => {
      document.removeEventListener("mousedown", closeDropDown);
    };
  }, []);

  return (
    <>
      <div className="navbar header-section bg-[#FFFFFF] sticky top-0 z-50">
        <div className="flex-none">
          <button
            className="cursor-pointer btn btn-ghost"
            onClick={handleSideBar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
              className="inline-block w-5 h-5 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex-1">
          <Link to="/" className="pl-3">
            <img src={logo} width={120} alt="" />
          </Link>
        </div>
        {/* global search*/}
        <div className=" w-80 justify-center mr-5 hidden xl:block">
          <SelectProduct
            className="searchProduct"
            productsRef={productsRef}
            handleProductChange={handleProductChange}
          />
        </div>
        <div className="flex-none">
          <label
            htmlFor="my-drawer-4"
            onClick={toggleDrawer}
            className="cursor-pointer mx-2"
          >
            {wishListData?.length > 0 ? (
              <div className="flex flex-row">
                <BsHeartFill color="tomato" size={23} />
                <span className="badge badge-md bg-danger-200 w-5 h-5 -mt-3 -ml-2 text-sm">
                  {wishListData?.length}
                </span>
              </div>
            ) : (
              <BsHeart color="tomato" size={23} />
            )}
          </label>
          <label
            htmlFor="my-drawer-4"
            onClick={toggleCartDrawer}
            className="cursor-pointer mx-2"
          >
            {cartData?.products?.length > 0 ? (
              <div className="flex flex-row">
                <BsCartFill size={23} color="tomato" />
                <span className="badge badge-md bg-danger-200  w-5 h-5 -mt-3 -ml-2 text-sm ">
                  {cartData?.products?.length}
                </span>
              </div>
            ) : (
              <BsCart color="tomato" size={23} className="mr-1" />
            )}
          </label>
          {authData.isLogin === true ? (
            <div ref={dropDownMenuRef} className="relative">
              <Link
                onClick={() => setDropDownState(!dropDownState)}
                className="cursor-pointer m-2 ml-2 mr-5"
              >
                <RiUser3Line color="tomato" size={23} />
              </Link>
              {dropDownState && (
                <ul className="absolute menu p-2 shadow bg-base-100 rounded-box w-52 border border-gray-100 font-semibold text-sm right-0">
                  <li>
                    <Link to="/your-profile" className="px-2 py-1">
                      Your Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/order" className="px-2 py-1">
                      Your Order
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment-history" className="px-2 py-1 mb-3">
                      Payment History
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={signOut}
                      className="p-2 border border-red-500 rounded-xl bg-red-500 font-semibold hover:text-black text-white"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <Link to={`/login/?ref="product"`} className="ml-2 mr-5">
              <RiUser3Line color="tomato" size={23} />
            </Link>
          )}
          <Wishlist isWishList={isWishList} toggleDrawer={toggleDrawer} />
          <Cart
            isCartOpen={isCartOpen}
            toggleCartDrawer={toggleCartDrawer}
            checkOut={checkOut}
          />
        </div>
      </div>
    </>
  );
};

export default StickyHeader;
