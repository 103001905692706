import React, { useEffect } from "react";
import "../Model/Model.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCustomerRegisterMutation } from "../../../services/customerApi";
import LoginBg from "../../../Assets/bg-br.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../features/authSlice";
import { selectCustomer } from "../../../features/customerSlice";

const Register = () => {
  const userData = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();
  const [addCustomer] = useCustomerRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData.isLogin !== false) {
      // console.log("data", userData.isLogin);
      navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      const newData = {
        name: data.firstName + " " + data.lastName,
        email: "",
        type: "regular",
        phone: data.phone,
        // address: [],
        password: data.password,
        username: data.firstName + data.lastName,
        membership: "",
        status: "active",
      };
      // console.log(newData);
      const { data: register, isSuccess } = await addCustomer(newData);
      // console.log('register', register)
      if (register) {
        dispatch(
          loginUser({
            accessToken: register.access_token,
            userId: register.user.id,
            userName: register.user.username,
            point: register.user.point,
            phone: register.user.phone,
            type: register.user.type,
            name: register.user.name,
          })
        );
        dispatch(
          selectCustomer({
            userId: register.user.id,
            point: register.user.point,
            phone: register.user.phone,
            type: register.user.type,
            name: register.user.name,
            email: register.user.email,
            username: register.user.username,
            membership: register.user.membership,
            address: register.user.address,
          })
        );
        toast.success('Welcome', {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT // customize the position
        });
        navigate("/");
        localStorage.setItem("access_token", register.access_token);
        localStorage.setItem("user", JSON.stringify(register.newUser));
      }
      else {
        toast.error("Give correct information", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT // customize the position
        });
      }
      // console.log(register);
    } catch (err) {
      console.log(err)
      toast.error("Give correct information", {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT // customize the position
      });
    }

    // console.log(success);
  };

  return (
    <>
      <section
        className="h-full bg-no-repeat bg-right-bottom"
        style={{ backgroundImage: `url(${LoginBg})` }}
      >
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div
                className="
                                    max-w-[525px]
                                    mx-auto
                                    text-center
                                    rounded-lg
                                    relative
                                    overflow-hidden
                                    py-8
                                    px-10
                                    sm:px-12
                                    md:px-[60px]
                                    "
              >
                <div className="grid grid-cols-1  mb-5 md:mb-5 mt-10">
                  <div className="text-center">
                    <h1 className="text-3xl text-red-500 font-bold ml-5">
                      Register
                    </h1>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex mb-6">
                    <input
                      {...register("firstName")}
                      type="text"
                      placeholder="First Name"
                      className="w-1/2 rounded-md border border-[#E9EDF4] py-3 px-5 mr-2 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                    <input
                      {...register("lastName")}
                      type="text"
                      placeholder="Last Name"
                      className="w-1/2 rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <input
                      {...register("phone")}
                      type="number"
                      placeholder="Enter Your Phone Number"
                      className="w-full rounded-md border border-[#E9EDF4] py-3 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <input
                      {...register("password")}
                      type="password"
                      placeholder="Enter Your Password"
                      className="
                                                    w-full
                                                    rounded-md
                                                    border
                                                    border-[#E9EDF4]
                                                    py-3
                                                    px-5
                                                    bg-[#FCFDFE]
                                                    text-base text-body-color
                                                    placeholder-[#ACB6BE]
                                                    outline-none
                                                    focus-visible:shadow-none
                                                    focus:border-primary
                                                    "
                    />
                  </div>

                  <div className="mb-10">
                    <input
                      type="submit"
                      value="Sign Up"
                      className="
                                                    w-full
                                                    btn
                                                    "
                    />
                  </div>
                </form>

                <p className="mr-1 first-letter:text-base text-[#adadad]">
                  Already a Member?{" "}
                  <Link className="text-red-500" to={"/login"}>
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Forms Section End --> */}
    </>
  );
};

export default Register;
