import React, { useState } from 'react';
import { Modal } from 'react-daisyui';

const OrderCloseModal = ({products,onShow,title,handleClose}) => {
    
    return (
        
        <>
        <div>
            <Modal open={onShow} onClickBackdrop={handleClose} center>
                <Modal.Header className="font-bold">
                    {title}
                </Modal.Header>

                <Modal.Body>
                    {/* <h1>hi</h1> */}
                    <div className="flex flex-col items-center justify-center">
                        {
                            products?.length > 0 ? products?.map(pro => {

                                return (
                                    <div className="bg-white flex justify-around items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md">
                                        <div className="flex flex-col justify-start items-start w-2/6 my-2">
                                            <h3 className="text-left text-gray-500 my-1">Product Name</h3>
                                            <h1 className="text-left mt-2 text-md">{pro?.name}</h1>
                                        </div>
                                        <div className="flex flex-col justify-start items-start w-1/6 my-2">
                                            <h3 className="text-left text-gray-500 my-1">Price</h3>
                                            <h1 className="text-left mt-2 text-md text-bold">
                                                {pro.mrp} BDT
                                            </h1>
                                        </div>
                                        <div className="flex flex-col justify-start items-start w-1/6 my-2">
                                            <h3 className="text-left text-gray-500 my-1">Quantity</h3>
                                            <h1 className="text-left mt-2 text-md">{pro.qty}</h1>
                                        </div>
                                        <div className="flex flex-col justify-start items-start w-1/6 my-2">
                                            <h3 className="text-left text-gray-500 my-1">total</h3>
                                            <h1 className="text-left mt-2 text-md">{(parseFloat(pro.qty) * parseFloat(pro.mrp)).toFixed(2)}</h1>
                                        </div>
                                    </div>
                                )
                            }) : <>
                                <p>No products found
                                </p>
                            </>
                        }
                        {/* {
                            products?.map(pro => {
                                console.log(pro.name)
                                return (

                                    <h1>{pro.name}</h1>
                                )
                            })
                        }  */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        </>
    );
};

export default OrderCloseModal;