import React, { useEffect } from "react";
import "../Model/Model.css";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillLock, AiOutlineUser } from "react-icons/ai";
import { useCustomerLoginMutation } from "../../../services/customerApi";
import { loginUser } from "../../../features/authSlice";
import LoginBg from "../../../Assets/bg-br.png";
import { selectCustomer } from "../../../features/customerSlice";
import { useMediaQuery } from "react-responsive";
import { notify } from "../../../Utlity/Notify";
import { toast } from "react-toastify";

const Login = ({ onRequestClose }) => {
  const { register, handleSubmit } = useForm();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const isLaptopScreen = useMediaQuery({ minWidth: 576, maxWidth: 1824 });
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const titleData = useSelector((state) => state.headerReducer)
  const [loginCustomer] = useCustomerLoginMutation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location);
  // let from = location.state?.from?.pathname || "/";
  // console.log(from);
  // useEffect(() => {
  //   if (userData.isLogin !== false) {
  //     console.log("data", userData.isLogin);
  //     navigate("/");
  //   }
  // }, []);
  const { from } = location.state || { from: { pathname: "/" } };

  const onSubmit = async (data) => {
    // notify("Incorrect user and password", "error")

    // console.log("hi", data);
    try {
      const { data: login, isSucces, isLoading } = await loginCustomer(data);

      // console.log(login);
      if (login?.status == true) {
        dispatch(
          loginUser({
            accessToken: login?.access_token,
            userId: login?.user?.id,
            userName: login?.user?.username,
            point: login?.user?.point,
            phone: login?.user?.phone,
            type: login?.user?.type,
            name: login?.user?.name,
          })
        );
        dispatch(
          selectCustomer({
            userId: login?.user?.id,
            point: login?.user?.point,
            phone: login?.user?.phone,
            type: login?.user?.type,
            name: login?.user?.name,
            email: login?.user?.email,
            username: login?.user?.username,
            membership: login?.user?.membership,
            address: login?.user?.address,
          })
        );
        // notify("Welcome", "success")
        toast.success('Welcome', {
          position: toast.POSITION.TOP_RIGHT,// customize the position
          autoClose: 1000,
        });
        localStorage.setItem("access_token", login.access_token);
        localStorage.setItem("user", JSON.stringify(login.user));
        console.log("location", from);
        navigate(from);
        // setTimeout(navigate(from, { replace: true }), 3000);
      } else {
        // notify("Incorrect user and password", "error")
        toast.error('Incorrect user and password', {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT // customize the position
        });
      }
    } catch (err) {
      console.log("err", err)
      toast.error('Incorrect user and password', {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,// customize the position
      });
    }
  };
  return (
    <>
      {/* <!-- ====== Forms Section Start --> */}
      <section
        className="h-full bg-no-repeat bg-right-bottom"
        style={{ backgroundImage: `url(${LoginBg})` }}
      >
        <div>
          <div className="flex justify-center items-center">
            <div className="w-80 h-100 bg-white p-4 rounded-md items-center">
              <div className="h-20"></div>
              <h1 className="text-3xl font-bold text-red-500  mb-5 text-center block ">
                Login
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control mb-3">
                  {/* <label className="input-group ">

                  <span>
                    <RiUser3Line size={30} />
                  </span> */}
                  <input
                    {...register("phone")}
                    type="text"
                    placeholder="Phone Number"
                    className="input input-bordered"
                  />
                  {/* </label> */}
                </div>
                <div className="form-control  mb-3">
                  {/* <label className="label">
                      <span className="label-text">Your Email</span>
                    </label> */}
                  {/* <label className="input-group  w-full">
                  <span>
                    <AiFillLock size={30} />
                  </span> */}
                  <input
                    {...register("password")}
                    type="password"
                    placeholder="Enter Password"
                    className="input input-bordered"
                  />
                  {/* </label> */}
                </div>
                <div className="mb-5">
                  <button type="submit" value="Sign In" className="btn w-full">
                    <AiFillLock size={18} /> Login
                  </button>
                </div>
              </form>
              <Link className=" text-red-500 mb-5 text-center" to={"/forgot"}>
                Forget Password?
              </Link>
              <p className="text-base text-[#adadad]">
                Not a member yet?
                <Link to={"/register"} className="text-red-500">
                  {" "}
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Forms Section End --> */}
    </>
  );
};

export default Login;
