import React, { useEffect } from 'react'
import '../Model/Model.css'
import LoginBg from '../../../Assets/bg-br.png'
import photo from '../../../Assets/logo.png'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

const Authentication = ({}) => {
  return (
    <>
      {/* <!-- ====== Forms Section Start --> */}
      <section
        className='h-full bg-no-repeat bg-right-bottom'
        style={{ backgroundImage: `url(${LoginBg})` }}
      >
        <div>
          <div className='flex justify-center items-center'>
            <div className='w-80 h-100'>
              <div className='h-20'></div>
              <h1 className='text-3xl font-bold text-red-500  mb-5 text-center block '>
                Authentication
              </h1>
              <form>
                <div className='form-control mb-3'>
                  <input
                    type='text'
                    placeholder='Enter OTP Here'
                    className='input input-bordered'
                  />
                </div>
                <div className='mb-10'>
                  <Link to={'/reset-password'}>
                    <input
                      type='submit'
                      value='Send Reset Code'
                      className='btn w-full'
                    />
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== Forms Section End --> */}
    </>
  )
}

export default Authentication
