import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
// import { getUser } from "../hooks/useCustomerHook";

const RequireAuth = ({ children }) => {
  // const getData = useSelector();
  const location = useLocation();
  // console.log(location);
  const userData = useSelector((state) => state.auth);

  // console.log("userData", userData);

  if (userData?.isLogin !== true) {
    // console.log(location);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
