import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCart, BsXCircle } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { BsDash } from "react-icons/bs";
import { BsPlus } from "react-icons/bs";
import photo from "../../../Assets/product.jpg";
import { useSelector, useDispatch } from "react-redux";
import CartHook from "../../../hooks/useCartHook";
import Drawer from "react-modern-drawer";
import CartLoop from "./CartLoop";

const Cart = ({ isCartOpen, toggleCartDrawer, checkOut }) => {
  const cartData = useSelector((state) => state.order);
  const { addQuantity, removeQuantity, deleteShoppingCart, removeFromDb } =
    CartHook();

    // console.log('checkOut', cartData)

  return (
    <Drawer
      open={isCartOpen}
      onClose={toggleCartDrawer}
      direction="right"
      className="bla bla bla"
      style={{ width: `400px` }}
    >
      <div className="flex h-full flex-col pb-0 pt-3  overflow-x-hidden  overflow-y-auto w-90 bg-base-100 text-base-content">
        <header className="flex-none px-5">
          <div className="flex justify-between items-center">
            <h2 className="flex mb-1 font-bold">
              <BsCart
                size={20}
                className="mr-3"
                style={{ color: "tomato" }}
              ></BsCart>
              My Cart 
            </h2>
            <button
              onClick={() => deleteShoppingCart()}
              className="ghost flex items-center mb-1 text-[14px] hover:text-red-500"
            >
              Clear Cart <BsXCircle className="ml-1" size={20} />
            </button>
          </div>
          <div className="flex justify-between  mb-2 items-center">
            <div className="total-item text-sm ">
              Item: <b>{cartData?.totalItem}</b>
            </div>
            <div className="today-point text-sm ">
              Point: <b>{cartData?.todayPoint?.toFixed(2)}</b>
            </div>
          </div>
        </header>
        <div className="grow-2 md:grow">
          <div className="h-[70vh] md:h-[80vh] bg-[#f5f5f5] px-3  pt-3 overflow-x-hidden  overflow-y-scroll">
            {cartData?.products?.length > 0 ? (
              cartData?.products?.map((item) => {
                  return (
                    <CartLoop
                      item={item}
                      removeQuantity={removeQuantity}
                      addQuantity={addQuantity}
                      removeFromDb={removeFromDb}
                    />
                  );
                })
            ) : (
              <h1>Your Shopping Bag is Empty</h1>
            )}
          </div>
        </div>
        <div className="flex-none p-3">
          <div
            className="btn-group rounded-none border-separate flex w-full justify-center"
            onClick={() => checkOut()}
          >
            {/* <Link to="/check-out" className="w-grow"> */}
            <div className="btn bg-red-500 rounded-[0px] border-red-400 grow">
              Places Order
            </div>
            <div className="btn rounded-[0px]  font-bold">
              {parseFloat(cartData.grossTotal).toFixed(2)} Tk
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Cart;
