import React from 'react'

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center translate-y-56'>
      <h1 className='text-5xl'>404</h1>
      <h3 className='text-4xl'>Not Found</h3>
    </div>
  )
}

export default NotFound
