import {
  startOfToday,
  endOfToday,
  format,
  formatDistance,
  isWithinInterval,
  parseISO,
} from "date-fns";
import { useDispatch } from "react-redux";
import { selcetProduct, setTotalPrice } from "../features/orderSlice";
// use local storage to manage cart data
const CartHook = () => {
  const dispatch = useDispatch();

  //   GET STORE DATA
  const getStoredCart = () => {
    let shoppingCart = [];
    //get the shopping cart from local storage
    const storedCart = JSON.parse(localStorage.getItem("shopping_cart"));

    return storedCart;
  };

  //   TOTAL PRICE CALCULATION
  const totalPrice = (items) => {
    let total = 0;
    let changeAmmount = 0;
    let grossTotal = 0;
    let todayPoint = 0;
    let totalItem = 0;
    let totalRecieved = 0;
    let vat = 0;
    // console.log("carthook", items)
    items?.map((item) => {
      if (item?.promo_price > 0) {
        if (item?.promo_start && item?.promo_end) {
          const isBetween = isWithinInterval(new Date(), { start: parseISO(item?.promo_start), end: parseISO(item?.promo_end) });
          if (isBetween) {
            if (item.promo_type === true) {
              total = total + (item.mrp || item?.priceList[0]?.mrp - ((item.mrp || item?.priceList[0]?.mrp * (item?.promo_price / 100)) * item.qty));
              grossTotal = total + (item.mrp || item?.priceList[0]?.mrp - ((item.mrp || item?.priceList[0]?.mrp * (item?.promo_price / 100)) * item.qty));
              todayPoint = total / 100;
              totalItem = total + (item.mrp || item?.priceList[0]?.mrp - ((item.mrp || item?.priceList[0]?.mrp * (item?.promo_price / 100)) * item.qty));
              totalRecieved = total;
              vat = total;

            } else {
              total = total + ((item.mrp || item?.priceList[0]?.mrp - item?.promo_price) * item.qty);
              grossTotal = total + ((item.mrp || item?.priceList[0]?.mrp - item?.promo_price) * item.qty);
              todayPoint = total / 100;
              totalItem = total + ((item.mrp || item?.priceList[0]?.mrp - item?.promo_price) * item.qty);
              totalRecieved = total;
              vat = total;

            }
          }
        }
      }
      else {
        total = total + item.qty * item.mrp ;
        grossTotal = total + item.qty * item.mrp ;
        todayPoint = total / 100;
        totalItem = total + item.qty * item.mrp || item?.priceList[0].mrp ;
        totalRecieved = total;
        vat = total;
      }
    });
    // console.log("🚀 ~ total", total);
    // dispatch(setTotalPrice(total));

    return total;
  };

  const addToCart = (data) => {
    let product = [];
    const cartData = getStoredCart();
    // CHECK DATA
    // console.log("hi from vat", data)
    if (cartData !== null) {
      let matched = cartData?.find((pro) => pro.id === data._id);
      let rest = cartData?.filter((pro) => pro.id !== data._id);

      if (matched !== undefined) {
        product = [
          ...rest,
          {
            ...matched,
            qty: matched.qty + 1,
          },
        ];
      } else {
        const activePriceList = data?.priceList?.filter(
          (pr) => pr.status === "active"
        );
        // console.log("hi from vat", data)
        let newProduct = {
          id: data?._id,
          priceId: activePriceList[0]?._id,
          name: data.name,
          article_code: data.article_code,
          ean: data.ean,
          photo: data?.photo,
          mrp: activePriceList[0]?.mrp || data?.mrp,
          qty: 1,
          tp: activePriceList[0]?.tp,
          vat: data?.vat ? data?.vat : 0, //TODO:: Update this to dynamic with product vat
          unit: data.unit,
          promo_price: data?.promo_price ? data?.promo_price : "0",
          promo_type: data?.promo_type ? data?.promo_type : false,
          promo_start: data?.promo_start ? data?.promo_start : "",
          promo_end: data?.promo_end ? data?.promo_end : "",
          // promo: {
          //   promo_price: newPrice?.promo_price ? newPrice?.promo_price : 0,
          //   promo_type: newPrice?.promo_type ? newPrice.promo_type : false,
          // },
          supplier: activePriceList[0]?.supplier,
          order: cartData?.length + 1,
        };

        product = [...cartData, newProduct];
      }
    } else {
      const activePriceList = data?.priceList?.filter(
        (pr) => pr.status === "active"
      );
      // console.log("hi from vat", data)
      let newProduct = {
        id: data?._id,
        priceId: activePriceList[0]?._id,
        name: data.name,
        article_code: data.article_code,
        ean: data.ean,
        photo:data?.photo,
        mrp: activePriceList[0]?.mrp || data?.mrp,
        qty: 1,
        tp: activePriceList[0]?.tp,
        vat: data?.vat ? data?.vat : 0, //TODO:: Update this to dynamic with product vat
        unit: data.unit,
        promo_price: data?.promo_price ? data?.promo_price : "0",
        promo_type: data?.promo_type ? data?.promo_type : false,
        promo_start: data?.promo_start ? data?.promo_start : "",
        promo_end: data?.promo_end ? data?.promo_end : "",
        // promo: {
        //   promo_price: newPrice?.promo_price ? newPrice?.promo_price : 0,
        //   promo_type: newPrice?.promo_type ? newPrice.promo_type : false,
        // },
        supplier: activePriceList[0]?.supplier,
        order: 1,
      };

      product = [newProduct];
    }

    totalPrice(product);
    dispatch(selcetProduct(product));

    return localStorage.setItem("shopping_cart", JSON.stringify(product));
  };

  const removeFromDb = (id) => {
    const storedCart = getStoredCart();
    if (storedCart) {
      const newCart = storedCart?.filter((p) => p.id !== id);

      totalPrice(newCart);
      dispatch(selcetProduct(newCart));
      return localStorage.setItem("shopping_cart", JSON.stringify(newCart));
    }
  };

  const removeQuantity = (id) => {
    const storedCart = getStoredCart();
    let newCart = [];
    if (storedCart) {
      const selectedItem = storedCart.find((p) => p.id === id);
      const restItem = storedCart.filter((p) => p.id !== id);
      // console.log("selectedItem", selectedItem.qty, "restItem", restItem);

      /*
       * TODO:: If Qty 0 > remove the item
       */
      if (selectedItem.qty === 1) {
        newCart = [...restItem];
      } else {
        selectedItem.qty = selectedItem.qty > 0 ? selectedItem.qty - 1 : 0;
        newCart = [...restItem, selectedItem];
      }
      // console.log("New Cart", newCart);
      totalPrice(newCart);
      dispatch(selcetProduct(newCart));
      return localStorage.setItem("shopping_cart", JSON.stringify(newCart));
    }
  };

  const addQuantity = async (id) => {
    const storedCart = getStoredCart();
    if (storedCart) {
      const selectedItem = storedCart.find((p) => p.id === id);
      const restItem = storedCart.filter((p) => p.id !== id);
      // console.log(selectedItem, selectedItem.qty);

      const newCart = [
        ...restItem,
        {
          ...selectedItem,
          qty: parseFloat(selectedItem.qty) + 1,
        },
      ];

      totalPrice(newCart);
      dispatch(selcetProduct(newCart));
      return localStorage.setItem("shopping_cart", JSON.stringify(newCart));
    }
  };

  const customQuantity = (id, value) => {
    const storedCart = getStoredCart();
    if (storedCart) {
      const selectedItem = storedCart.find((p) => p.article_code === id);
      const restItem = storedCart.filter((p) => p.article_code !== id);

      selectedItem.qty = value > 0 ? value : 0;
      const newCart = [...restItem, selectedItem];

      totalPrice(newCart);
      dispatch(selcetProduct(newCart));
      return localStorage.setItem("shopping_cart", JSON.stringify(newCart));
    }
  };

  const deleteShoppingCart = () => {
    dispatch(setTotalPrice(0));
    dispatch(selcetProduct([]));
    return localStorage.setItem("shopping_cart", null);
  };

  return {
    totalPrice,
    addToCart,
    addQuantity,
    removeQuantity,
    getStoredCart,
    removeFromDb,
    deleteShoppingCart,
    customQuantity,
  };
};

export default CartHook;
