import React, { useState } from 'react'
import HeroInfoPage from '../../Common/Hero/HeroInfoPage'

const AboutUs = () => {
  const [person, setPerson] = useState({
    name: '',
    phone: '',
    email: '',
    msg: '',
  })
  const [people, setPeople] = useState([])

  return (
    <>
      <HeroInfoPage />

      <section className='flex flex-col md:flex-row text-justify w-11/12 my-4 pl-8 pr-0'>
        TCM started it's journey back in ..... since then it is satisfiying its
        customer through Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Fugit eaque dolorum natus fuga iusto omnis aliquid ad eius
        doloribus blanditiis accusamus, fugiat quia amet vitae quasi commodi,
        iste cupiditate nulla. Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Odit, quae. Exercitationem totam similique laborum
        eius cupiditate quas earum distinctio officiis praesentium eum nisi nemo
        veniam doloremque dolorem voluptatum, enim ipsa provident doloribus,
        explicabo, quisquam ipsam quod. Illum illo optio beatae vitae id.
        Quibusdam ab, eum vitae, pariatur cumque, voluptate voluptates
        voluptatibus dignissimos fuga ipsam officia saepe quia aliquam. Illo
        reiciendis soluta consectetur ducimus, facilis earum consequatur
        voluptatibus corporis totam similique! Perspiciatis, laborum. Et
        exercitationem corrupti amet, asperiores dolor enim blanditiis nisi?
        Blanditiis, alias corrupti est, officiis maiores corporis, hic
        perspiciatis eaque deleniti explicabo culpa atque mollitia consequatur
        itaque accusamus quo.
      </section>
    </>
  )
}

export default AboutUs
