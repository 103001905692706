import React, { useState } from "react";
import { usePopularProductQuery } from "../../../services/productApi";
import ProductLoop from "../Loop/ProductLoop";
import LoadingModal from "../Modal/LoadingModal";
import Pagination from "../../Common/Pagination/Pagination"; // Assuming this is your Pagination component


const PopularProducts = () => {
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);


  const [activePageIndex, setActivePageIndex] = useState(0); // State to manage active page index
  const itemsPerPage = 15; // Number of products per page
  const { data, isSuccess, isLoading } = usePopularProductQuery({
    page: activePageIndex + 1, // Adjust to 1-based index for backend
    size: itemsPerPage,
  });
  // console.log("popular", data);
  const handlePageClick = (pageIndex) => {
    setActivePageIndex(pageIndex);
  };

  return (
    <div className=" mt-5">
      <h1 className="text-3xl text-center mb-5">Popular Products</h1>
      <div className="grid lg:grid-cols-5 grid-cols-2 gap-4 place-content-center p-7">
        {isLoading ? (
          <LoadingModal
            title="Please Wait"
            onShow={loader}
            handleClose={handleLoaderClose}
          />
        ) : data?.products?.length > 0 ? (
          data?.products
            .map((item, index) => (
              <ProductLoop key={item.id || index} fp={item} />
            ))
        ) : (
          <p>No Products to show</p>
        )}
      </div>
      {/* Pagination component */}
      <Pagination
        activePageIndex={activePageIndex}
        pages={Array.from({ length: Math.ceil(data?.totalCount / itemsPerPage) }, (_, i) => i)}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default PopularProducts;
