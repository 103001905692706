import { isWithinInterval, parseISO } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import photo from "../../../Assets/product.jpg";
import CartPromoPriceHook from "../../../hooks/useCartPromoPrice";
import PHOTO_URL from "../../../Utlity/Photo_url";

const CartLoop = ({ item, removeQuantity, addQuantity, removeFromDb }) => {
  const { isPromo, newPrice } = CartPromoPriceHook(item);

  // console.log("cartloop", item)
  // console.log("cartloop", isPromo)
  // console.log("cartloop", newPrice)
  const [dataPhoto, setDataPhoto] = useState(photo);

  useEffect(() => {
    if (item?.photo !== undefined) {
      if (item?.photo !== "") {
        if (item?.photo !== "photo.jpg") {
          setDataPhoto(`${PHOTO_URL}${item.photo}`);
        }
      }
    }
  }, [item]);
  // console.log("dataPhoto", dataPhoto)
  const handleImageError = () => {
    setDataPhoto(photo);
  };

  return (
    <>
      <div className="flex ">
        <div className="card rounded-xl flex-row mb-3 p-1 bg-white duration-200 w-full shadow-xl">
          {/* Card image */}
          <figure className="flex-none rounded-xl w-24 ">
            <img
              onError={handleImageError}
              className="w-full h-full"
              src={item.photo ? `${PHOTO_URL}${item.photo}` : dataPhoto} 

              // src={dataPhoto}
              alt={item.name}
            />
          </figure>

          {/* Card body */}
          <div className="card-body p-2">
            {/* Item name */}
            <div className="font-normal text-[12px] m-0 ">{item.name}</div>

            {/* Card actions */}
            <div className="card-actions justify-between">
              {/* Price display */}
              {isPromo === true ? (
                <div className="flex flex-row items-end justify-between">
                  {/* Promotional price */}
                  <p className="font-bold text-red-500">
                    {parseFloat(newPrice).toFixed(2)}
                    <span className="text-xs text-black">TK</span>
                  </p>
                  {/* Original price */}
                  <p className="font-bold pl-2 text-xs text-red-400 line-through">
                    {(
                      parseFloat(item?.mrp ? item?.mrp : 0) *
                      parseFloat(item?.qty)
                    ).toFixed(2)}{" "}
                    <span className="text-xs text-black">TK</span>
                  </p>
                </div>
              ) : (
                <>
                  <h2 className="font-bold pl-2 text-red-500">
                    {(
                      parseFloat(item?.mrp ? item?.mrp : 0) *
                      parseFloat(item?.qty)
                    ).toFixed(2)}{" "}
                    <span className="text-xs text-black">TK</span>
                  </h2>
                </>
              )}

             
            </div>
             {/* Quantity controls */}
             <div className="">
                <div className="flex items-center justify-end gap-2">
                  <div className="flex  h-5 rounded-lg  bg-transparent">
                    {/* Decrement button */}
                    <button
                      data-action="decrement"
                      onClick={() => {
                        removeQuantity(item?.id);
                      }}
                      className="flex items-center justify-center bg-red-500 text-xl font-thin btn-circle text-white hover:text-gray-700 hover:bg-red-500 h-full w-5 cursor-pointer outline-none"
                    >
                      -
                    </button>
                    {/* Quantity input */}
                    <input
                      type=""
                      className="text-center w-10 bg-white font-semibold text-md items-center"
                      name="custom-input-number"
                      value={item?.qty}
                    />
                    {/* Increment button */}
                    <button
                      data-action="increment"
                      onClick={() => {
                        addQuantity(item?.id);
                      }}
                      className="flex items-center justify-center bg-red-500 text-xl font-thin btn-circle text-white hover:text-gray-700 hover:bg-red-500 h-full w-5 cursor-pointer"
                    >
                      +
                    </button>
                  </div>
                  {/* Delete button */}
                  <button
                    className="ghost ml-1 mr-2 items-end hover:text-red-500 duration-300"
                    onClick={() => {
                      removeFromDb(item.id);
                    }}
                  >
                    <MdDeleteForever size={30} />
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartLoop;
