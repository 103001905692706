import React from 'react';

const Pagination = ({ activePageIndex, pages, handlePageClick }) => {
  const pagesToShow = 5; // number of pages to display
  const startPage = Math.max(activePageIndex - Math.floor(pagesToShow / 2), 0);
  const endPage = Math.min(startPage + pagesToShow, pages?.length);

  return (
    <div className='flex justify-end btn-group mr-8 mb-2'>
      {pages?.slice(startPage, endPage).map((page, index) => (
        <button
          key={index}
          className={`btn ${index + startPage === activePageIndex ? 'btn bg-orange-500 border-red-500' : 'border-red-500 bg-red-500'} hover:border-orange-500 hover:bg-orange-500`}
          onClick={() => handlePageClick(index + startPage)}
        >
          {index + startPage + 1}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
