import React, { useEffect, useState } from "react";
import { ImLocation2 } from "react-icons/im";
import { CiStickyNote } from "react-icons/ci";
import { MdPayment } from "react-icons/md";
import UseAddressModel from "../UseAddressModel/UseAddressModel";
import SuccessModal from "../../Common/Modal/OrderSuccess/SuccessModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  reset,
  selectCustomerDeliveryAddress,
  selectCustomerInfo,
  selectNote,
} from "../../../features/orderSlice";
import {
  useCustomerByIdEcomQuery,
  useUpdateCustomerPointMutation,
} from "../../../services/customerApi";

import axios from "axios";
import BASE_URL from "../../../Utlity/Base_url";
import { useNavigate } from "react-router-dom";
import { selectPoint } from "../../../features/customerSlice";

const Checkout = () => {
  // console.log("hello from checkout");
  // const localUser = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector((state) => state.customer);
  const authData = useSelector((state) => state.auth);
  const order = useSelector((state) => state.order);


  const { data, isSuccess, refetch } = useCustomerByIdEcomQuery({
    id: customer?.userId,
  });
  const [updateCustomerPoint] = useUpdateCustomerPointMutation();

  // console.log("data",data?.address[0]?.holdingNo)

  useEffect(() => {
    dispatch(
      selectCustomerDeliveryAddress({
        address: {
            holdingNo: data?.address[0]?.holdingNo,
            sector: data?.address[0]?.sector,
            street: data?.address[0]?.street,
            town: data?.address[0]?.town,
            city: data?.address[0]?.city,
            division: data?.address[0]?.division,
            country: data?.address[0]?.country,
            zipCode: data?.address[0]?.zipCode,
        },
        phone: data?.phone,
      })
    );
  }, [data]);

  useEffect(() => {
    dispatch(
      selectCustomerInfo({ id: customer.userId, point: customer.point })
    );
  }, [customer]);

  const [addchange, setAddchange] = useState(false);
  const [address, setAddress] = useState([]);
  // const [address, setAddress] = useState({
  //   // House: "05",
  //   // Road: 8,
  //   // Sector: 11,
  //   // city: "uttara",
  //   // State: "Dhaka",
  //   // zip: 1230,
  // });
  const [phone, setPhone] = useState("1231379734");
  const [deliveryInfo, setDeliveryInfo] = useState({
    address: address,
    phone: phone,
  });

  // console.log(deliveryInfo);
  const addressChange = () => {
    setAddchange(true);
  };

  const addressDel = () => {
    setAddchange(!addchange);
  };

  // const userLoggedin = () => {
  //   if (authData.access_token === "null") {
  //     setIsUser(!isUser);
  //   }
  // };

  const handleNoteChange = (e) => {
    // console.log(e.target.value);
    dispatch(selectNote(e.target.value));
  };

  const handleOrderSubmit = async () => {
    // console.log("clicked in sale", order);
    // console.log("clicked in sale", customer);
    const confirm = window.confirm("Confirm order?");
    if (confirm) {
      await axios
        .post(`${BASE_URL}ecom/sale`, order)
        .then(async (res) => {
          // console.log(res);


          await updateCustomerPoint({
            _id: customer?.userId,
            point: order?.point?.new,
          })
            .then((res) => {
              dispatch(selectPoint(order?.point?.new));
              navigate("/success");

            })
            .catch((err) => console.log(err));

          localStorage.removeItem("shopping_cart");
          dispatch(reset());
        })
        .catch((err) => console.log(err));
    }
  };

  // console.log('order Data', order)

  return (
    <div>
    
      <div className="grid mt-2 mb-2 gap-4">
        <div className="col-start-1 col-span-8">
          <div className="flex flex-row justify-center mb-10">
            <ul className="steps steps-vertical  mt-5  mb-3 xl:steps-horizontal">
              <li data-content="●" className="step w-52 step-error text-sm">
                Check Out
              </li>
              {/* <li className='step text-sm'>Payment</li> */}
              <li data-content="!" className="step w-52 text-sm">
                Order
              </li>
              <li data-content="?" className="step w-52 text-sm">
                Process
              </li>
              <li data-content="?" className="step w-52 text-sm">
                Delivered
              </li>
            </ul>
          </div>
          {/* <!-- component --> */}
          {/* <!-- component --> */}
        </div>
      </div>

      <div className="grid grid-cols-6 mt-4 mb-20 gap-4">
        <div className="col-start-2 col-span-4">
          <div className=" rounded mb-5 overflow-hidden border w-full lg:w-12/12 md:w-12/12 bg-white  md:mx-0 lg:mx-0">
            <div className="w-full bg-gray border-b px-3">
              <div className="flex justify-between items-center ">
                <h2 className="flex justify-center pt-1 pb-1 ml-2 font-bold">
                  <ImLocation2 size={20} className="mr-2" />
                  Delivery Address
                </h2>
                <button
                  className="btn btn-outline m-2 px-6 btn-sm  btn-error"
                  htmlFor="clntdvm"
                  onClick={addressChange}
                >
                  Change
                </button>
                <UseAddressModel />
              </div>
            </div>

            <div className="px-3 pb-2">
              <div className="grid grid-cols-6 gap-4 max-[640px]:gap-1 max-[640px]:grid-cols-3">
                <div className="col-start-1 col-end-5 ">
                  <p
                    className=" font-medium text-sm pt-1 text-gray-900"
                    id="slide-over-title"
                  >
                    {" "}
                    <b>Customer Name: </b> {customer?.name}
                  </p>
                  <p
                    className=" font-medium text-sm text-gray-900"
                    id="slide-over-title"
                  >
                    <b> Address: </b>
                    <p>
                      <b>house :</b> {order?.delivery?.address?.holdingNo}, <b>Road: </b>
                        {order?.delivery?.address?.street},<br />
                        <b>Sector:</b> {order?.delivery?.address?.sector}, <b>Town :</b> {order?.delivery?.address?.town},{" "}
                        <br />
                        <b>City:</b> {order?.delivery?.address?.city}, {order?.delivery?.address?.zipCode} <br />
                        <b>Phone: </b>
                        {phone}
                      </p>
                  </p>
                  <p
                    className=" font-medium text-sm text-gray-900"
                    id="slide-over-title"
                  >
                    <b>Phone: </b> {order?.delivery?.phone}
                  </p>
                </div>
                <div className="col-end-10  col-span-2"></div>
              </div>
            </div>
          </div>
          <div className=" rounded mb-5 overflow-hidden border w-full lg:w-12/12 md:w-12/12 bg-white  md:mx-0 lg:mx-0">
            <div className="w-full bg-gray border-b my-3 px-3">
              <div className="flex justify-between items-center ">
                <h2 className="flex justify-center items-center pt-1 pb-1 ml-2 font-bold">
                  <CiStickyNote size={20} className="mr-2" />
                  Note
                </h2>
              </div>
            </div>

            <div className="px-3 pb-2">
              {/* <div className="grid grid-cols-6 gap-4">
                <div className="col-start-1 col-end-5">
                  <h2
                    className=" font-medium text-sm text-gray-900"
                    id="slide-over-title"
                  >
                    Manishankar Vakta
                  </h2>
                  <h2
                    className=" font-medium text-sm text-gray-900"
                    id="slide-over-title"
                  >
                    Address: {address}
                  </h2>
                  <h2
                    className=" font-medium text-sm text-gray-900"
                    id="slide-over-title"
                  >
                    Phone: {phone}
                  </h2>
                </div>
                <div className="col-end-10  col-span-2"></div>
              </div> */}
              <textarea
                onChange={(e) => handleNoteChange(e)}
                type="text"
                className="input input-bordered w-full"
              ></textarea>
            </div>
          </div>
          <div className=" rounded overflow-hidden border w-full lg:w-12/12 md:w-12/12 bg-white mx-3 mx-0">
            <div className="w-full flex justify-between  bg-gray border-b mb-5 p-3">
              <div className="flex">
                <div className="rounded-full h-8 w-8  flex items-center justify-center overflow-hidden">
                  <MdPayment />
                </div>
                <span className="pt-1 ml-2 font-bold text-sm">Payment</span>
              </div>
            </div>

            <div className="pl-4 pb-2">
              <div className="grid grid-cols-6 gap-16 max-[640px]:grid-cols-6 max-[640px]:gap-2">
                <div className="col-start-1 col-end-3 max-[640px]:col-start-1 max-[640px]:col-end-6">
                  <select className="select select-info w-full max-w-xs">
                    <option disabled selected>
                      Payment Option
                    </option>
                    <option selected>Cash on Delivery</option>
                    {/* <option>18 Jun</option>
                    <option>19 Jun</option> */}
                  </select>
                </div>

                <div
                  className="col-start-5 col-end-6 mb-5 max-[640px]:col-start-1 max-[640px]:col-end-6"
                  onClick={() => handleOrderSubmit()}
                >
                  {/* a href='#my-modal-10' */}
                  <div className="btn-group">
                    <button className="btn btn-error text-white">
                      Confirm
                    </button>
                    <button className="btn">{order.grossTotalRound}Tk</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='pl-4 pb-2'>
              <button
                // href='#my-modal-10'
                className='btn'
                onClick={() => handleOrderSubmit()}
              >
                Confirm Order
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='flex items-center justify-between w-2/3 ml-52 p-2'>
        <p className='text-sm text-gray-500'>
          Payment Options available on the next Page
        </p>
        <p className='text-sm text-gray-500'>29TK Delivery charge included</p>
      </div> */}

      {addchange && (
        <UseAddressModel
          addchange={addchange}
          addressDel={addressDel}
          setAddchange={setAddchange}
        />
      )}

      {/* <input type="checkbox" id="clntdvm" className="modal-toggle" /> */}
      {/* {isUser && <loginModel isUser={isUser} onRequest={userLoggedin} />} */}
      <SuccessModal />
    </div>
  );
};

export default Checkout;
