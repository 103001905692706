import React from "react";
import { IconBase } from "react-icons";
import {
  FaCheckCircleOutline,
  FaCircle,
  FaRegCheckCircle,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-full">
      {/* <h1>Thank you For Shopping</h1>
            <h2>Your Orders</h2> */}
      <div className="w-100">
        <p className="text-center flex justify-center">
          <FaRegCheckCircle color="green" size={150} />
        </p>
        <h2 className="text-xl py-4 text-center">Order Successful !</h2>
        <p
          className="flex justify-between text-center"
          style={{ width: "300px" }}
        >
          <Link to="/order" className="text-red-500">
            View Order
          </Link>
          <Link to="/" className="text-red-500">
            Go to Home
          </Link>
        </p>
      </div>
      {/* <div className="card w-1/2 bg-base-100 shadow-xl m-2">
                <div className="card-body">
                    <h2 className="card-title">Thank you For Shopping</h2>
                </div>
            </div> */}
      {/* <div className="card w-1/2 bg-base-100 shadow-xl m-2">
                <div className="card-body">
                    <h2 className="card-title">Your Order</h2>
                </div>
            </div> */}
      {/* <button
        onClick={() => {
          navigate(`/`);
        }}
        className="btn btn-dark"
      >
        Go to home
      </button> */}
    </div>
  );
};

export default SuccessPage;
