import React, { useEffect, useState, useMemo } from "react";
import Photo from "../../../../Assets/category.jpg";
import Hero from "../../../Common/Hero/Hero";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../../Utlity/Base_url";

const SubFood = ({ catGroup }) => {
  const [cat, setCat] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCat();
  }, []);

  //    var catHeader=`https://pos-api-v1.herokuapp.com/api`;

  const getCat = () => {
    axios
      .get(`${BASE_URL}category/group/food`)
      .then(function (response) {
        setCat(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <>
      <Hero />
      <div className="container mt-5">
        <div className="grid grid-cols-5 gap-3 place-content-center p-7">
          {cat.map((item) => {
            return (
              <>
                <Link to="/product-view">
                  <div className="card product-loop  w-full bg-base-100">
                    <figure>
                      <img
                        src='https://chaldn.com/_mpimage/fruits-vegetables?src=https%3A%2F%2Feggyolk.chaldal.com%2Fapi%2FPicture%2FRaw%3FpictureId%3D23769&q=low&v=1&m=400'

                        alt={item.name}
                      />
                    </figure>
                    <div className="card-body">
                      <div className="tooltip" data-tip={item.name}>
                        <h5 className="card-title text-center text-sm">
                          {item.name}
                        </h5>
                      </div>
                      {/* <div className="card-actions">
                                                <div className="grid w-full grid-rows-2 grid-flow-col gap-0">
                                                    <div className="row-span-1">
                                                        <h3 className='font-bold text-center text-lg'>
                                                            {fp.price}
                                                            <span> ৳
                                                                <small className='text-sm font-normal'> / {fp.unit}</small>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <div className="row-span-1">
                                                        <div className="flex">
                                                            <div className="w-1/2">
                                                                <div className="from-control">
                                                                    <div className="input-group h-8">
                                                                        <BsDash className='' size={25}></BsDash>
                                                                        <input type="text" placeholder="1" className="text-base h-6 text-center input w-6 p-0 w-1/6" />
                                                                        <BsPlus className='' size={25}></BsPlus>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-1/2">
                                                                <button style={{ background: 'tomato' }} className="shadow-md hover:shadow btn float-right w-7 h-7 add-to-cart border-0 p-0 btn-circle">
                                                                    <BsPlus className='' size={25}></BsPlus>
                                                                </button>
                                                                <span className="float-right p-1">
                                                                    <AiOutlineHeart className='' size={25}></AiOutlineHeart>
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SubFood;
