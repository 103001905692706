import { configureStore } from "@reduxjs/toolkit";
import customerSlice from "../features/customerSlice";
import authSlice from "../features/authSlice";
import orderSlice from "../features/orderSlice";
import { CategoryApi } from "../services/categoryApi";
import { CustomerApi } from "../services/customerApi";
import { ProductApi } from "../services/productApi";

const Store = configureStore({
  reducer: {
    customer: customerSlice,
    auth: authSlice,
    order: orderSlice,
    // API Recuder
    [CustomerApi.reducerPath]: CustomerApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(CustomerApi.middleware)
      .concat(ProductApi.middleware)
      .concat(CategoryApi.middleware),
});

export default Store;
