import React from 'react'
import { Button } from 'react-daisyui'
import {
  FaCcVisa,
  FaCcPaypal,
  FaCcMastercard,
  FaCcStripe,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import UserDashboardSidebar from '../../Common/SideBar/UserDashboardSidebar'
import { useSelector } from 'react-redux'

function PaymentHistory() {
  const customer = useSelector((state) => state.customer)

  const data = [
    {
      orderId: 'TCM-ECOM-82475134750',
      date: '1/2/23',
      amount: 5000,
      qty: 7,
      paymentMode: 'bKash',
    },
    {
      orderId: 'TCM-ECOM-82475134750',
      date: '1/2/23',
      amount: 5000,
      qty: 7,
      paymentMode: 'bKash',
    },
    {
      orderId: 'TCM-ECOM-82475134750',
      date: '1/2/23',
      amount: 5000,
      qty: 7,
      paymentMode: 'bKash',
    },
    {
      orderId: 'TCM-ECOM-82475134750',
      date: '1/2/23',
      amount: 5000,
      qty: 7,
      paymentMode: 'bKash',
    },
    {
      orderId: 'TCM-ECOM-82475134750',
      date: '1/2/23',
      amount: 5000,
      qty: 7,
      paymentMode: 'bKash',
    },
  ]

  return (
    <>
      <h1 className='text-3xl mt-3 md:text-left text-center md:pl-8'>
        Payment History
      </h1>
      <div className='container md:h-screen mx-auto'>
        <div className='flex flex-col md:flex-row'>
          <div className='md:w-1/4 w-full p-4'>
            <UserDashboardSidebar customer={customer} />
          </div>
          <div className='md:w-3/4 w-full p-4'>
            {/* {data.map((order) => {
              <div className='bg-white flex justify-arround items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md'>
                <div className='flex flex-col justify-start items-start w-2/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                    Order No
                  </h3>
                  <h1 className='text-left mt-2 text-xs md:text-md'>
                    {order.orderId}
                  </h1>
                </div>
                <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>Date</h3>
                  <h1 className='text-left mt-2 text-xs md:text-md'>
                    {order.date}
                  </h1>
                </div>
                <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                    Amount
                  </h3>
                  <h1 className='text-left mt-2 text-xs md:text-md text-bold'>
                    {order.amount} BDT
                  </h1>
                </div>
                <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                    Quantity
                  </h3>
                  <h1 className='text-left mt-2 text-xs md:text-md'>
                    {order.qty}
                  </h1>
                </div>
                <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                    Payment
                  </h3>
                  <h1 className='text-left mt-2 text-xs md:text-md'>
                    {order.paymentMode}
                  </h1>
                </div>
                <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                  <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                    Action
                  </h3>
                  <h1 className='text-left mt-2 text-xs md:text-md'>
                    <Button className='btn-sm'>Details</Button>
                  </h1>
                </div>
              </div>
            })} */}
            <div className='bg-white flex justify-arround items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md'>
              <div className='flex flex-col justify-start items-start w-2/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Order No
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  TCM-ECOM-82475134750
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Date
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>1/02/2023</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Amount
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md text-bold'>
                  5000 BDT
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Quantity
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>07</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Payment
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>Bkash</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Action
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  <Button className='btn-sm'>Details</Button>
                </h1>
              </div>
            </div>
            <div className='bg-white flex justify-arround items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md'>
              <div className='flex flex-col justify-start items-start w-2/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Order No
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  TCM-ECOM-82475134750
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Date
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>1/02/2023</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Amount
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md text-bold'>
                  5000 BDT
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Quantity
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>07</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Payment
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>Bkash</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Action
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  <Button className='btn-sm'>Details</Button>
                </h1>
              </div>
            </div>
            <div className='bg-white flex justify-arround items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md'>
              <div className='flex flex-col justify-start items-start w-2/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Order No
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  TCM-ECOM-82475134750
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Date
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>1/02/2023</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Amount
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md text-bold'>
                  5000 BDT
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Quantity
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>07</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Payment
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>Bkash</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Action
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  <Button className='btn-sm'>Details</Button>
                </h1>
              </div>
            </div>
            <div className='bg-white flex justify-arround items-center p-1 px-3 mx-auto mb-2 rounded-lg shadow-md'>
              <div className='flex flex-col justify-start items-start w-2/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Order No
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  TCM-ECOM-82475134750
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Date
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>1/02/2023</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Amount
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md text-bold'>
                  5000 BDT
                </h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Quantity
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>07</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Payment
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>Bkash</h1>
              </div>
              <div className='flex flex-col justify-start items-start w-1/6 my-2'>
                <h3 className='text-left text-gray-500 text-xs md:text-md my-1'>
                  Action
                </h3>
                <h1 className='text-left mt-2 text-xs md:text-md'>
                  <Button className='btn-sm'>Details</Button>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentHistory
