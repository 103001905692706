import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import herobgLeft from '../../../Assets/hero-bg.jpg'
import SelectProduct from '../../../CustomSelect/ProductSelect'
import { useProductSearchFullQuery, useProductSearchQuery } from '../../../services/productApi'
import BASE_URL from '../../../Utlity/Base_url'

const Hero = () => {
  const [selectedOption, setSelectedOption] = useState([])
  const [products, setProducts] = useState([])
  const [q, setQ] = useState('');
  const navigate = useNavigate()

  // const { data, error, isLoading, isFetching, isSuccess, refetch } =
  //   useProductSearchFullQuery({
  //     q,
  //   })
  const productsRef = useRef(null);
  // console.log("productsRef", productsRef)
  // const [page, setPage] = useState(0);
  // const [size, setSize] = useState(100);

  // const { data, error, isLoading, isFetching, isSuccess, refetch } =
  // useProductPagenationQuery({
  //     page,
  //     size,
  //     q,
  //   });  
  // console.log("data", data)
  // console.log("products", products)
  // React.useEffect(() => {
  //   setProducts(data)
  // }, [isSuccess])

  // useEffect(() => {
  //   let options = []
  //   data?.map(
  //     (pro) =>
  //     (options = [
  //       ...options,
  //       {
  //         id: pro._id,
  //         option: pro.article_code,
  //         label: `${pro.article_code} - ${pro.name} )`,
  //       },
  //     ])
  //   )
  //   setSelectedOption(options)
  // }, [data])

  // console.log('pro Opt', selectedOption)
  const handleProductChange = (e) => {
    // console.log(e)
    navigate(`/product/${e._id}`)

  }

  return (
    <div
      className='hero  bg-base-100 rounded-md-lg h-72 '
      style={{ backgroundImage: `url(${herobgLeft})`, }}
    >
      <div className='hero-content flex-col lg:flex-row'>
        {/* <img src="https://api.lorem.space/image/movie?w=260&h=400" className="max-w-sm rounded-lg shadow-2xl" /> */}
        <div className='py-10 md:pt-8 pb-10'>
          <h1 className='xl:text-3xl font-bold align-left xl:pb-5 pb-3'>
            Groceries Delivery in Uttara Sector 7
          </h1>
          {/*
           * PRODUCT SEARCH
           */}
          {/* <Select
            defaultValue={selectedOption[0]}
            className="basic-single"
            classNamePrefix="select"
            value={selectedOption[selectedOption.indexOf(selectedOption)]}
            onChange={(e) => handleProductChange(e)}
            // onChange={setSelectedOption}
            options={selectedOption}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          /> */}
          <SelectProduct
            className="searchProduct "
            productsRef={productsRef}
            handleProductChange={handleProductChange}
          />
          {/* <p className='py-2 md:py-6'>
            <i>Inside Uttara Sector 7</i>
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default Hero
