import React, { useState } from 'react'
import HeroInfoPage from '../../../Common/Hero/HeroInfoPage'
import { ImLocation2 } from 'react-icons/im'
import { BiTimeFive } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const Career = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])
  return (
    <>
      <HeroInfoPage />

      <section className='flex flex-col items-start justify-start w-11/12 my-4 px-10'>
        <h3 className='text-md border-2 border-black rounded-full mb-4 py-2 px-5'>
          We are hiring
        </h3>
        <h1 className='text-5xl text-black font-bold tracking-wide'>
          Be a part of our mission
        </h1>
        <h2 className='text-lg mt-4 font-normal '>
          We are looking for passionate people to join us on our mission. We
          value flat hierarchies, clear communication, and full ownership and
          responsibility
        </h2>

        <div className='flex w-full mt-10 bg-gray border-b-2 pb-5'>
          <h3 className='text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
            View All
          </h3>
          <h3 className='text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
            Full Stack
          </h3>
          <h3 className='text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
            Front End
          </h3>
          <h3 className='text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
            Back End
          </h3>
          <h3 className='text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
            Wordpress
          </h3>
        </div>

        <div className='flex flex-col items-start justify-start w-full py-5 border-b-2 pb-5'>
          <div className='flex flex-col md:flex-row justify-between items-center w-full py-2'>
            <h1 className='text-xl font-semibold'>UI/UX Designer</h1>
            <a href='#' className='text-xl font-semibold'>
              Apply
            </a>
          </div>
          <h2 className='text-lg mt-4 font-normal '>
            We are looking for a mid-lebel UI/UX Designer.
          </h2>
          <div className='flex my-3'>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <ImLocation2 className='mr-2' /> 100% Remote
            </h3>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <BiTimeFive className='mr-2' />
              Full-time
            </h3>
          </div>
        </div>

        <div className='flex flex-col items-start justify-start w-full py-5 border-b-2 pb-5'>
          <div className='flex flex-col md:flex-row justify-between items-center w-full py-2'>
            <h1 className='text-xl font-semibold'>UI/UX Designer</h1>
            <a href='#' className='text-xl font-semibold'>
              Apply
            </a>
          </div>
          <h2 className='text-lg mt-4 font-normal '>
            We are looking for a mid-lebel UI/UX Designer.
          </h2>
          <div className='flex my-3'>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <ImLocation2 className='mr-2' /> 100% Remote
            </h3>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <BiTimeFive className='mr-2' />
              Full-time
            </h3>
          </div>
        </div>

        <div className='flex flex-col items-start justify-start w-full py-5 border-b-2 pb-5'>
          <div className='flex flex-col md:flex-row justify-between items-center w-full py-2'>
            <h1 className='text-xl font-semibold'>UI/UX Designer</h1>
            <a href='#' className='text-xl font-semibold'>
              Apply
            </a>
          </div>
          <h2 className='text-lg mt-4 font-normal '>
            We are looking for a mid-lebel UI/UX Designer.
          </h2>
          <div className='flex my-3'>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <ImLocation2 className='mr-2' /> 100% Remote
            </h3>
            <h3 className='flex items-center justify-start text-md border-2 border-black rounded-full mr-3 py-1 px-3 cursor-pointer'>
              <BiTimeFive className='mr-2' />
              Full-time
            </h3>
          </div>
        </div>
      </section>
    </>
  )
}

export default Career
