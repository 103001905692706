import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../Common/Header/NavBar";
import Home from "../Pages/Home/Home";
import Shop from "../Pages/Shop/Shop";
import NotFound from "../Pages/NotFound/NotFound";
import Footer from "../Common/Footer/Footer";
// import Category from '../Pages/Category/Category'
import photo from "../../Assets/product.jpg";
import { BsCart } from "react-icons/bs";
import SideBar from "../Common/SideBar/SideBar";
import Offers from "../Pages/Offers/Offers";
import PopularProduct from "../Pages/PopularProducts/PopularProduct";
import Food from "../Pages/Food/Food";
import Cart from "../Common/Cart/Cart";
import SubFood from "../Pages/Food/SubFood/SubFood";
import TopNav from "../Common/Header/TopNav";
import ProductDetails from "../Pages/Product/ProductDetails";
import Group from "../template/Group/Group";
import MasterCategory from "../template/MasterCategory/MasterCategory";
import Category from "../template/Category/Category";
import ItemDetails from "../template/Product/Product";

// footer links
import TermsOfUse from "../Pages/Links/TermsOfUse";
import PrivacyPolicy from "../Pages/Links/PrivacyPolicy";
import CookiePolicy from "../Pages/Links/CookiePolicy";
import ContactUs from "../Pages/Links/ContactUs";
import Career from "../Pages/Links/Career/Career";
import AboutUs from "../Pages/Links/AboutUs";

// test dasborad

import YourProfile from "../UserDasbord/YourProfile/YourProfile";
import MyOrder from "../UserDasbord/MyOrder/MyOrder";
import OrderDetails from "../UserDasbord/OrderDetails/OrderDetails";
import Checkout from "../UserDasbord/Checkout/Checkout";
import Payment from "../UserDasbord/Payment/Payment";
import PaymentHistory from "../UserDasbord/PaymentHistory/PaymentHistory";

//Auth Route

import Login from "../Auth/Login/Login";
import Register from "../Auth/Register/Register";
import Forgot from "../Auth/ForgotPass/Forgot";
import Authentication from "../Auth/Authentication/Authentication";
import RequireAuth from "../../Utlity/RequireAuth";
import AuthCheck from "../../Utlity/AuthCheck";
import login from "../../Utlity/CheckCustomerLogin";
import SuccessPage from "../Success/SuccessPage";
import { useMediaQuery } from "react-responsive";
import FlashSale from "../Pages/FlashSale/FlashSale";
import ResetPass from "../Auth/ResetPass/ResetPass";
import ChangePass from "../UserDasbord/ChangePass/ChangePass";

const LayoutSidBarLeft = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isLaptopScreen = useMediaQuery({ minWidth: 1341, maxWidth: 2174 });
  const isTabletLandscape = useMediaQuery({ query: "(max-width: 1340px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const isPortrait = useMediaQuery({ query: "(max-width: 800px)" });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const location = useLocation();

  let navigate = useNavigate();

  const [notification, setNotification] = useState(true);
  const [chat, setChat] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [wishList, setWishList] = useState(false);
  const [cart, setCart] = useState(false);
  const [products, setProducts] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  const [show, setShow] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const CartShow = () => {
    setShow(true);
  };
  // const CartHide = () => {
  //   navigate("/check-out");
  //   setShow(false);
  //   alert(false);
  // };
  useEffect(() => {
    if (isMobile) {
      setShowSideBar(false);
    } else if (isPortrait) {
      setShowSideBar(false);
    } else if (isTabletLandscape) {
      setShowSideBar(true);
    } else {
      setShowSideBar(true);
    }
  }, [isMobile, isTabletLandscape, location, isPortrait]);

  // console.log("isPortrait", isPortrait);
  // console.log("SideBar", showSideBar);
  // useEffect(() => {
  //   if (isTabletOrMobile) {
  //     setShowSideBar(false)
  //   } else {
  //     setShowSideBar(true)
  //   }
  // }, [isTabletOrMobile])

  useEffect(() => {
    fetch("products.json")
      .then((res) => res.json())
      .then((data) => setProducts(data));
  }, []);
  useEffect(() => {
    let fp = [];
    fp = products.filter((product) => {
      return product.featured.toString().toLowerCase() === "true";
    });
    setFeatured(fp);
  }, [products]);

  const handleSideBar = () => {
    setShowSideBar((prevCheck) => !prevCheck);
  };

  // ------------------ working to fix scrolling to top issue -------------------------

  // const position = useLocation()
  // Scroll to top if path changes
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [position.pathname])

  // const Wrapper = ({ children }) => {
  //   const position = useLocation()
  //   useLayoutEffect(() => {
  //     document.documentElement.scrollTo(0, 0)
  //   }, [position.pathname])
  //   return children
  // }

  // const oldPage = useRef(pathname)

  // useEffect(() => {
  //   if (pathname !== oldPage.current) {
  //     try {
  //       window.scroll({
  //         top: 0,
  //         left: 0,
  //         behavior: 'smooth',
  //       })
  //     } catch (error) {
  //       // for older browser
  //       window.scrollTo(0, 0)
  //     }
  //     oldPage.current = pathname
  //   }
  // }, [pathname])

  // const { pathname } = useLocation()

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   })
  // }, [pathname])

  // useEffect(() => {
  //   document.body.scrollTo(0, 0)
  // })

  return (
    <>
      <div className="drawer drawer-end">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* <!-- Page content here --> */}
          <TopNav
            setIsOpen={setIsOpen}
            CartShow={CartShow}
            notification={notification}
            handleSideBar={handleSideBar}
            chat={chat}
            loggedIn={loggedIn}
            cart={cart}
            wishList={wishList}
          />

          <div className="grid grid-cols-12">
            <div
              className={
                showSideBar
                  ? "col-span-8 sm:col-span-4 md:col-span-3 lg:col-span-2 "
                  : "hidden"
              }
            >
              <SideBar />
            </div>

            <div
              className={
                showSideBar
                  ? "col-span-4 sm:col-span-8 md:col-span-9 lg:col-span-10"
                  : "col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12"
              }
            >
              <div className="w-full h-full ">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Home featured={featured} wishList={wishList} />}
                  />
                  <Route
                    path="/food"
                    element={
                      <SubFood featured={featured} wishList={wishList} />
                    }
                  />
                  {/* <Route path='/category/:id' element={<Category />} /> */}
                  <Route path="/offer" element={<Offers />} />
                  <Route
                    path="/popular-products"
                    element={<PopularProduct products={products} />}
                  />
                  <Route path="/flash-sale" element={<FlashSale />} />
                  <Route path="/shop" element={<Shop />} />
                  <Route
                    path="/subfood"
                    element={<Food featured={featured} wishList={wishList} />}
                  />
                  {/* TEMPLATES */}
                  <Route path="/category/group/:group" element={<Group />} />
                  <Route path="/category/mc/:mc" element={<MasterCategory />} />
                  <Route path="/category/:id" element={<Category />} />
                  <Route path="/product/:id" element={<ItemDetails />} />
                  {/* TEMPLATES */}

                  <Route
                    path="/your-profile"
                    element={
                      <RequireAuth>
                        <YourProfile />
                      </RequireAuth>
                    }
                  />

                  <Route
                    path="/order"
                    element={
                      <RequireAuth>
                        <MyOrder />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/order-details/:id"
                    element={
                      <RequireAuth>
                        <OrderDetails />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/check-out"
                    element={
                      <RequireAuth>
                        <Checkout />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/success"
                    element={
                      <RequireAuth>
                        <SuccessPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/payment"
                    element={
                      <RequireAuth>
                        <Payment />
                      </RequireAuth>
                    }
                  />
                  {/*
                 <Route
                    path="/payment-history"
                    element={
                      <RequireAuth>
                        <OrderHistory />
                      </RequireAuth>
                    }
                  />
                */}
                  <Route
                    path="/change-pass"
                    element={
                      <RequireAuth>
                        <PaymentHistory />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <AuthCheck>
                        <Login />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <AuthCheck>
                        <Register />
                      </AuthCheck>
                    }
                  />
                  <Route
                    path="/forgot"
                    element={
                      <AuthCheck>
                        <Forgot />
                      </AuthCheck>
                    }
                  />
                  <Route path="/authentication" element={<Authentication />} />
                  <Route path="/reset-password" element={<ResetPass />} />

                  {/* footer section Pages */}
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/cookie-policy" element={<CookiePolicy />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/career" element={<Career />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                {/* </Wrapper> */}
              </div>
            </div>
          </div>
          {/* Navbar goes here */}

          <Footer></Footer>
          {/* Navbar goes here */}
        </div>
        <Cart isOpen={isOpen} toggleDrawer={toggleDrawer} />
      </div>
    </>
  );
};

export default LayoutSidBarLeft;
