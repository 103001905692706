import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Photo from "../../../Assets/category.jpg";
import PHOTO_URL from "../../../Utlity/Photo_url";

const CategoryLoop = ({ category }) => {
  const [dataPhoto, setDataPhoto] = useState(Photo);
  useEffect(() => {
    if (category.photo !== undefined) {
      if (category.photo !== "") {
        if (category?.photo !== "photo.jpg") {
          setDataPhoto(`${PHOTO_URL}${category.photo}`);
          console.log('photo',`${PHOTO_URL}${category.photo}`)
        }
      }
    }
  }, [category]);
  const handleImageError = () => {
    setDataPhoto(Photo);
  };
  return (
    <Link to={`/category/${category._id}`} className='hover:color-["tomato"]'>
      <div className="p-3 max-[640px]:py-3 max-[640px]:px-0 max-w-lg w-full mx-auto bg-white rounded-xl hover:shadow-md shadow-lg flex items-center space-x-2 max-[640px]:space-x-1">
        <div className="shrink-0">
          <img
            onError={handleImageError}
            className="w-14 h-14 rounded-full object-cover float-left"
            src={dataPhoto}
            width={80}
            alt={category?.name}
          />
        </div>
        <div>
          <div className="max-[640px]:text-xs text-sm  font-medium text-['tomato'] break-normal">
            {category.name}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CategoryLoop;
