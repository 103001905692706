import React from "react";
import { Modal, Progress, RadialProgress } from "react-daisyui";


const LoadingModal = ({ title, onShow, handleClose }) => {
    // console.log("loading");
    return (
        <div>
            <Modal open={onShow} onClickBackdrop={handleClose} center>
                <Modal.Header className="font-bold">
                    Please Wait
                </Modal.Header>

                <Modal.Body>
                    <div className="flex items-center justify-center">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...</span
                            >
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>


    );
};

export default LoadingModal;
