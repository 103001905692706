import React from "react";
import { isExpired, decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";

const CustomerHook = () => {
  const dispatch = useDispatch();

  const accessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    // console.log(accessToken);
    return accessToken;
  };

  const getUser = () => {
    if (accessToken() !== null) {
      const myDecodedToken = decodeToken(accessToken());
      return myDecodedToken;
    } else {
      return "No User";
    }
  };
  const isLogin = () => {
    if (accessToken() !== null) {
      const isMyTokenExpired = isExpired(accessToken());
      return isMyTokenExpired;
    } else {
      return false;
    }
  };

  const updateAuth = () => {
    if (accessToken !== null) {
      const myDecodedToken = decodeToken(accessToken());
      const isMyTokenExpired = isExpired(accessToken());
    }
  };

  return { isLogin, getUser, accessToken, updateAuth };
};

export default CustomerHook;
