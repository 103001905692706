import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import {
  BsHeart,
  BsPlus,
  BsCart,
  BsXCircle,
  BsFillCartFill,
  BsTrash,
} from "react-icons/bs";
import "react-modern-drawer/dist/index.css";
import photo from "../../../Assets/product.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import WishListHook from "../../../hooks/useWishListHook";
import PHOTO_URL from "../../../Utlity/Photo_url";

const Wishlist = ({ toggleDrawer, isWishList }) => {
  const { removeWishListFromDb, addToCartFromWishlist, deleteWishList } =
    WishListHook();
  const wishListCart = useSelector((state) => state.customer.wishList);

  // console.log("wishListCart", wishListCart);

  return (
    <>
      <Drawer
        open={isWishList}
        onClose={toggleDrawer}
        direction="right"
        style={{ width: `400px` }}
      >
        <div className="flex flex-col pb-4 pt-4  overflow-x-hidden  overflow-y-auto  bg-base-100 text-base-content">
          <header className="flex-none px-5">
            <div className="flex justify-between items-center">
              <h2 className="flex mb-5 font-bold">
                <BsCart
                  size={20}
                  className="mr-3"
                  style={{ color: "tomato" }}
                ></BsCart>
                My WishList
              </h2>
              <button
                onClick={() => deleteWishList()}
                className="ghost flex items-center mb-5 text-[14px] hover:text-red-500"
              >
                clear list <BsXCircle className="ml-1" size={20} />
              </button>
            </div>
          </header>
          <div className="grow">
            <div className="h-[80vh] bg-[#f5f5f5] px-3  pt-3 overflow-x-hidden  overflow-y-scroll">
              {wishListCart?.length > 0 ? (
                wishListCart
                  ?.slice()
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((item) => {
                    return (
                      <>
                        <div className="flex">
                          <div className="card rounded-xl flex-row w-screen mb-3 p-0 bg-white shadow-xl">
                            <figure className="flex-none">
                              <img
                                className="h-20 rounded-xl m-1 mr-2"
                                src={item.photo ? `${PHOTO_URL}${item.photo}` : photo} 

                                // src={photo}
                                alt={item?.name}
                              />
                            </figure>
                            <div className="card-body p-0 ">
                              <div className="font-normal text-[14px] m-0 pt-2">
                                {item?.name}
                              </div>
                              <div className="card-actions justify-between">
                                <h2 className="font-bold text-md pr-5 text-red-500">
                                  {item?.mrp * item?.qty}{" "}
                                  <span className="text-xs text-black">TK</span>
                                </h2>
                                <div className="flex mr-3 mb-2">
                                  <BsFillCartFill
                                    color="red"
                                    className="ml-2 cursor-pointer"
                                    onClick={() => addToCartFromWishlist(item)}
                                    size={20}
                                  />
                                  <BsTrash
                                    color="red"
                                    className="ml-2 cursor-pointer"
                                    onClick={() =>
                                      removeWishListFromDb(item.id)
                                    }
                                    size={20}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
              ) : (
                <h1>Your WishList is Empty</h1>
              )}
            </div>
          </div>
          <div className="flex-none">
            <div
              className="btn-group  flex w-full justify-center"
              // onClick={CartHide}
            >
              {/* <Link to="/check-out" className="w-grow">
                <button className="btn bg-red-500 border-red-400 grow">
                  Places Order
                </button>
              </Link> */}
              {/* <button className="btn font-bold">{cartData.total} Tk</button> */}
            </div>
          </div>
        </div>
        {/* <div className='menu py-4 px-3 overflow-y-auto w-80 bg-base-100 text-base-content'>
          <h2 className='text-center flex mb-5'>
            <BsHeart
              size={20}
              className='mr-2 mt-1'
              style={{ color: 'tomato' }}
            ></BsHeart>
            My Wishlist
          </h2>
          {wishCart
            ?.slice()
            ?.sort((a, b) => a.order - b.order)
            ?.map((item) => {
              return (
                <>
                  <div className='flex w-full'>
                    <div className='w-1/3'>
                      <img className='w-20 h-15' src={Photo} />
                    </div>
                    <div className='flex flex-col w-2/3'>
                      <div className='flex w-full'>
                        <span className='name'>
                          <p style={{ fontSize: `12px` }}>{item.name}</p>
                        </span>
                      </div>
                      <div className='flex'>
                        <div className='w-1/2'>
                          <button
                            onClick={() => {
                              addToCartItem(item.article_code)
                            }}
                            style={{ background: 'tomato' }}
                            className='shadow-md hover:shadow btn ml-5 border-0 p-0 btn-circle'
                          >
                            <BsCart className='' size={20}></BsCart>
                          </button>
                        </div>
                        <div className='flex w-full'>
                          <button
                            // onClick={() =>
                            //   dispatch({ type: 'wishListRemove', payload: item })
                            // }
                            onClick={() => {
                              removeWishItem(item.article_code)
                            }}
                            style={{ background: 'tomato' }}
                            className='shadow-md hover:shadow btn ml-5 border-0 p-0 btn-circle'
                          >
                            <MdDeleteForever />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
        </div> */}
      </Drawer>
    </>
  );
};

export default Wishlist;
