import React, { useState } from 'react'
import logo from '../../../Assets/logo.png'
import { VscBellDot, VscBell } from 'react-icons/vsc'
import {
  BsChatDotsFill,
  BsChatDots,
  BsThreeDots,
  BsCart,
  BsCartFill,
  BsHeart,
} from 'react-icons/bs'
import { RiUser3Line } from 'react-icons/ri'

import { Link } from 'react-router-dom'
import Login from '../../Auth/Login/Login'
import { useSelector, useDispatch } from 'react-redux'

const NavBar = ({ CartShow, loggedIn, notification, chat, handleSideBar }) => {
  const [showLogin, setShowLogin] = useState(false)

  // const cart = useSelector((state) => state);

  // const dispatch = useDispatch();
  // const addition = (acc, currentValue) => {
  //     return acc + currentValue.qty;

  // }

  // const totalCount = cart.reduce(addition, 0);
  // console.log(totalCount)
  // const Loginshow=()=>{
  //     setShowLogin(true);
  // }
  // const LoginHide = () => {
  //     setShowLogin(!showLogin);
  // }
  return (
    <>
      <div className='navbar bg-base-100 sticky top-0 z-20 '>
        <div className='flex-none'>
          <label for='my-drawer' className='cursor-pointer drawer-button'>
            <button
              className='cursor-pointer btn btn-ghost'
              onClick={handleSideBar}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='inline-block w-5 h-5 stroke-current'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            </button>
          </label>
          {/* <button  for="my-drawer" className="btn btn-square btn-ghost">
                </button> */}
        </div>
        <div className='flex-1'>
          <Link to='/' className='pl-5'>
            <img src={logo} width={120} alt='' />
          </Link>
        </div>
        <div className='flex-none'>
          <button className='btn btn-square btn-ghost'>
            {notification ? (
              <VscBell size={20}></VscBell>
            ) : (
              <VscBellDot color='tomato' size={20}></VscBellDot>
            )}
          </button>
          <button className='btn btn-square btn-ghost'>
            {/* {
                            chat !== "" ? <BsChatDotsFill color='tomato' size={20}></BsChatDotsFill>
                                : <BsChatDots size={20}></BsChatDots>
                        } */}

            {chat !== '' ? (
              <BsHeart size={20}></BsHeart>
            ) : (
              <BsHeart color='tomato' size={20}></BsHeart>
            )}
          </button>

          {/* <button className=""> */}
          <label
            for='my-drawer'
            className='btn btn-square btn-ghost cursor-pointer drawer-button'
          >
            {/* {
                            totalCount ? <div className='flex flex-row' onClick={CartShow}><BsCart size={20} color='tomato'></BsCart> <span className='badge badge-md bg-danger-200 text-sm'>{totalCount} </span></div>
                                : <BsCart size={20} />
                        } */}
          </label>
          {/* </button> */}
          <div className='dropdown dropdown-hover dropdown-end'>
            <button className='btn btn-square btn-ghost'>
              {loggedIn ? (
                <BsThreeDots color='tomato' size={20}/>
              ) : (
                <RiUser3Line color='tomato' size={20} />
              )}
            </button>
            <ul className='dropdown-content menu p-2 shadow bg-base-100  rounded-box w-52'>
              <li>
                <Link to='/your-profile'>Your Profile </Link>
              </li>
              <li>
                <Link to='/order'>Your Order</Link>
              </li>
              <li>
                <a>Payment History</a>
              </li>
              <li>
                <a>Logout</a>
              </li>
            </ul>
          </div>
          {/* {
                        showLogin && <Login  onRequestClose={LoginHide}/>
                    } */}
        </div>
      </div>
    </>
  )
}

export default NavBar
