import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import StickyHeader from "./StickyHeader/StickyHeader";

const TopNav = ({ handleSideBar, setIsOpen }) => {
  return (
    <>
      {/* <NavBar handleSideBar={handleSideBar}/> */}
      <StickyHeader handleSideBar={handleSideBar} />
    </>
  );
};

export default TopNav;
