import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import BASE_URL from "../Utlity/Base_url";

export const ProductApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    products: builder.query({
      query: ({ page, size, q }) => `ecom/product/${page}/${size}?q=${q}`,
      providesTags: ["Product"],
    }),
    productSearch: builder.query({
      query: ({ page, size, q }) => `ecom/product/${page}/${size}?q=${q}`,
      providesTags: ["Product"],
    }),
    productPagenation: builder.query({
      query: ({ page, size, q }) => `product/all/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/product`,
      providesTags: ["Product"],
    }),
    productSearchFull: builder.query({
      query: ({ q }) => `ecom/product/search?q=${q}`,
      providesTags: ["Product"],
    }),
    featuredProduct: builder.query({
      query: () => `ecom/featured`,
      providesTags: ["Product"],
    }),
    promoProduct: builder.query({
      query: () => `ecom/product/promo-products`,
      providesTags: ["Product"],
    }),
    comboProduct: builder.query({
      query: ({page,size}) => `ecom/product/combo/${page}/${size}`,
      providesTags: ["Product"],
    }),
    offerProduct: builder.query({
      query: () => `ecom/product/offer`,
      providesTags: ["Product"],
    }),
    popularProduct: builder.query({
      query: ({ page, size }) => `ecom/product/best-seller/${page}/${size}`,
      providesTags: ["Product"],
    })
    ,
    productByCategory: builder.query({
      query: ({ id }) => `ecom/product_category/${id}`,
      providesTags: ["Product"],
    }),
    productBySimilar: builder.query({
      query: ({ id }) => `ecom/product-similar/${id}`,
      providesTags: ["Product"],
    }),
    productById: builder.query({
      query: ({ id }) => `ecom/product/${id}`,
      providesTags: ["Product"],
    }),
  }),
});

export const {
  useProductsQuery,
  useProductSearchQuery,
  useProductSearchFullQuery,
  useFeaturedProductQuery,
  useProductPagenationQuery,
  usePromoProductQuery,
  useComboProductQuery,
  useOfferProductQuery,
  usePopularProductQuery,
  useProductByCategoryQuery,
  useProductBySimilarQuery,
  useProductByIdQuery,
} = ProductApi;

export default ProductApi;
