import axios from "axios";
import { async } from "postcss-js";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-daisyui";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useCustomerByIdEcomQuery,
  useUpdateCustomerAddressMutation,
} from "../../../services/customerApi";
import BASE_URL from "../../../Utlity/Base_url";
import "../../Auth/Model/Model.css";
import Checkout from "../Checkout/Checkout";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { selectCustomerDeliveryAddress } from "../../../features/orderSlice";

const AddressCard = ({
  phone,
  address,
  key,
  handleAddressAdd,
  handleAddressDel,
}) => {
  return (
    <Card
      onClick={() => handleAddressAdd(address)}
      key={key}
      className="bordered bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer"
    >
      <Card.Body className="p-5">
        <Card.Title
          tag="h6"
          className="flex items-center justify-between pb-2 border-b"
        >
          {" "}
          <span>{address?.type}</span>{" "}
          <Button
            size="xs"
            shape="circle"
            onClick={() => handleAddressDel(address)}
          >
            ✕
          </Button>
        </Card.Title>
        <p>
          <b>house :</b> {address?.holdingNo}, <b>Road: </b>
          {address?.street},<br />
          <b>Sector:</b> {address?.sector}, <b>Town :</b> {address?.town},{" "}
          <br />
          <b>City:</b> {address?.city}, {address?.zipCode} <br />
          <b>Phone: </b>
          {phone}
        </p>
      </Card.Body>
    </Card>
  );
};

const UseAddressModel = ({ addchange, addressDel }) => {
  const { register, handleSubmit, reset } = useForm({});
  const dispatch = useDispatch();

  // console.log('handleSubmit', handleSubmit)

  const customer = useSelector((state) => state.customer);
  const order = useSelector((state) => state.order);
  // const [customerDetails, setCustomerDetails] = useState({})
  const [openForm, setOpenForm] = useState(false);
  const [updateCustomerAddress] = useUpdateCustomerAddressMutation();
  const { data, isSuccess, refetch } = useCustomerByIdEcomQuery({
    id: customer?.userId,
  });

  // console.log(customer?.userId)
  // console.log(data)
  // useEffect(() => {
  //   axios.get(`${BASE_URL}ecom/customer/${customer.userId}`)
  //     .then(res => {
  //       // console.log(res)
  //       setCustomerDetails(res.data)
  //     })
  //     .catch(err => console.log(err))

  // }, [customer])

  // console.log("customerDetails", data);

  const onSubmit = async (address) => {
    // console.log("new address data", address);
    const uid = uuidv4();
    const newData = { ...address, id: uid };
    // console.log("customer", customer)
    // console.log("customer", data?.address)
    // console.log("new customer", [...data?.address, newData])
    const newAddress = [...data?.address, newData];
    await updateCustomerAddress({
      _id: customer?.userId,
      address: newAddress,
    })
      .then((res) => {
        // console.log(res);
        if (res.data) {
          // setAddchange(false);
          // console.log("dataAdress", res.data);
          refetch();
          setOpenForm(!openForm);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddressDel = async (address) => {
    // console.log(address);
    // console.log(data?.address);
    const newAddress = data?.address.filter((add) => add.id !== address.id);
    // console.log(newAddress);
    const confirm = window.confirm("Are you Sure? Delete this Address?");
    if (confirm) {
      await updateCustomerAddress({
        _id: customer?.userId,
        address: newAddress,
      })
        .then((res) => {
          // console.log(res);
          if (res.data) {
            // setAddchange(false);
            refetch();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleAddressAdd = (address) => {
    // console.log("hi");
    // const addressString =
    //   "House - " +
    //   address?.holdingNo +
    //   ", Road - " +
    //   address?.street +
    //   ", Sector - " +
    //   address?.sector +
    //   ", Town - " +
    //   address?.town +
    //   "," +
    //   address?.city +
    //   "-" +
    //   address?.zipCode;
    dispatch(
      selectCustomerDeliveryAddress({
        address: {
          holdingNo: address?.holdingNo,
            sector: address?.sector,
            street: address?.street,
            town: address?.town,
            city: address?.city,
            division: address?.division,
            country: address?.country,
            zipCode: address?.zipCode,
        },
        phone: customer?.phone,
      })
    );
    addressDel()
  };

  return (
    <>
      <Modal open={addchange} className="bg-white relative  w-11/12 max-w-5xl ">
        <Modal.Header className="font-bold">Your Address</Modal.Header>

        <Modal.Body>
          <div className="grid max-[640px]:grid-cols-3 grid-cols-3 gap-5 place-content-center p-7">
            {data?.address?.length > 0 ? (
              data?.address?.map((address, index) => {
                return (
                  <>
                    <AddressCard
                      phone={`983698723568249`}
                      address={address}
                      handleAddressAdd={handleAddressAdd}
                      handleAddressDel={handleAddressDel}
                      key={index}
                    />
                  </>
                );
              })
            ) : (
              <div className="flex justify-center items-center ">
                <Card className="bordered drop-shadow-sm ">
                  <Card.Body>
                    <p>No Address Found</p>
                  </Card.Body>
                </Card>
              </div>
            )}

            <div
             onClick={() =>openForm ? setOpenForm(!openForm) : setOpenForm(!openForm)}
            >
              <Card
             
                className={`cursor-pointer h-full  ${
                  openForm
                    ? "bg-red-50 hover:bg-red-100 duration-200 border-2 border-red-200"
                    : "bg-green-50 hover:bg-green-100 duration-200 border-2 border-green-200"
                }`}
              >
                <Card.Body className="p-5 flex items-center justify-center">
                  {openForm ? (
                    <div
                      className="text-4xl cursor-pointer py-12 "
                      
                    >
                      <Button className="text-xl bg-red-400  rounded-full border-none h-14 w-14   ">
                        ✕
                      </Button>
                    </div>
                  ) : (
                    <div
                      className=" text-slate-500 cursor-pointer text-center py-8  "
                     
                    >
                      <span className="text-6xl text-green-500">+</span>
                      <br />
                      <span className=" font-semibold text-green-500">
                        Add New Address
                      </span>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          {openForm && (
            <div className="card w-full bg-base-100 shadow-gray-600 drop-shadow-xl m-2">
              <div className="card-body">
                <h2 className="card-title">New Address Details</h2>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className=" grid grid-cols-3 gap-2"
                >
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Type
                    </label>
                    <select
                      {...register("type")}
                      className="input input-bordered"
                    >
                      <option selected value="Home">
                        Home
                      </option>
                      <option value="Work">Work</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Holding Number
                    </label>
                    <input
                      {...register("holdingNo")}
                      type="text"
                      className="input input-bordered"
                      placeholder="Holding Number"
                      required
                    />
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Road
                    </label>
                    <input
                      {...register("street")}
                      type="text"
                      className="input input-bordered"
                      placeholder="Road"
                      required
                    />
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Sector
                    </label>
                    <select
                      {...register("sector")}
                      className="input input-bordered"
                    >
                      <option value="1">01</option>
                      <option value="2">02</option>
                      <option value="3">03</option>
                      <option value="4">04</option>
                      <option value="5">05</option>
                      <option value="6">06</option>
                      <option selected value="7">
                        07
                      </option>
                      <option value="8">08</option>
                      <option value="9">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Town
                    </label>
                    <select
                      {...register("town")}
                      className="input input-bordered"
                    >
                      <option selected value="Uttara">
                        Uttara
                      </option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      City
                    </label>
                    <select
                      {...register("city")}
                      className="input input-bordered"
                    >
                      <option selected value="Dhaka">
                        Dhaka
                      </option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Division
                    </label>
                    <select
                      {...register("division")}
                      className="input input-bordered"
                    >
                      <option selected value="Dhaka">
                        Dhaka
                      </option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Zip Code
                    </label>
                    <select
                      {...register("zipCode")}
                      className="input input-bordered"
                    >
                      <option selected value="1230">
                        1230
                      </option>
                    </select>
                  </div>
                  <div className="form-control w-full max-w-xs mt-3">
                    <label className="mb-2" htmlFor="MCId">
                      Country
                    </label>
                    <select
                      {...register("country")}
                      className="input input-bordered"
                    >
                      <option selected value="BD">
                        Bangladesh
                      </option>
                    </select>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark col-8 col-md-8 mt-3"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}
          <Button
            size="sm"
            shape="circle"
            className="absolute right-2 top-2"
            onClick={addressDel}
          >
            ✕
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UseAddressModel;
