import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCustomerSalesQuery } from "../../../services/customerApi";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { BiCartAlt } from "react-icons/bi";
import OrderCloseModal from "../../Common/Modal/OrderCloseModal";
// import OrderCloseModal from "../../Common/Modal/OrderCloseModal";

const OrderDetails = () => {
  const { id } = useParams();
  const customer = useSelector((state) => state.customer);
  const [onShow, setOnShow] = useState(false);
  const [orderProducts, setOrderProducts] = useState([]);
    
  // console.log(customer.userId);
  const { data, isSuccess, refetch } = useCustomerSalesQuery({
    cId: customer.userId,
  });

  const handleClose = () => setOnShow(false);

  useEffect(() => {
    const matched = data?.find((item) => item._id === id);
    // console.log("matched", matched);
    setOrderProducts(matched);
  }, [data, id]);

  useEffect(() => {
    refetch();
  }, [id]);

  const handleOrderModal = () => {
    setOnShow(true);
  };

  // console.log("orderproducts:", data);
  return (
    <>
      <div className="flex justify-center mt-4 mb-5 gap-4 rounded-xl  ">
        <div className="w-2/3  col-start-2 col-span-4">
          <div className="card lg:card-side bg-base-100 shadow-xl">
            <div className="flex-1 mb-7 ">
              <div className="bg-red-500 m-2 px-4 text-white rounded-t-xl mb-8 flex justify-between items-center border-2">
                <div>
                  <h2
                    className="text-lg font-semibold uppercase"
                    id="slide-over-title"
                  >
                    Order Details
                  </h2>
                  <h2 className="text-sm font-semibold" id="slide-over-title">
                    Order ID:
                    <span className=" font-normal pl-2">
                      {orderProducts?.invoiceId}
                    </span>
                  </h2>
                </div>
                <div className="pt-7">
                  <p className="text-left text-sm">
                    <span className="font-semibold">Date: </span>{" "}
                    {/* {format(new Date(orderProducts.createdAt), "MM/dd/yyyy")} */}
                  </p>
                  <p className="-mt-7 text-sm">
                    <span className=" font-semibold">Total Amount: </span>
                    {orderProducts?.products?.reduce(
                      (total, item) => total + item.mrp,
                      0
                    )}{" "}
                    Taka
                  </p>
                </div>
              </div>

              <div className="pt-3">
                <div className="flow-root">
                  <ul role="list" className="">
                    {orderProducts?.products?.length > 0 ? (
                      orderProducts?.products?.map((item, index) => (
                        <li
                          key={index}
                          className="flex  hover:bg-gray-100 duration-200 bg-slate-50 mb-2 items-center px-4  "
                        >
                          <div className="w-16 flex-shrink-0 rounded-md  border-gray-200">
                            <img
                              src="https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-01.jpg"
                              alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
                              className="h-full w-full object-cover object-center "
                            />
                          </div>

                          <div className="ml-4 flex flex-1  flex-col pl-3">
                            <div className="flex justify-between text-base font-medium text-gray-900 pt-2">
                              <h3 className="text-sm font-semibold">
                                {item?.name}
                              </h3>
                              <p className=" text-sm font-semibold ">
                                {item?.mrp} Taka
                              </p>
                            </div>

                            <div className="flex items-end justify-between   ">
                              <p className="text-gray-500 -mt-8 font-semibold text-xs">
                                Quantity {item?.qty}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <h1 className="flex items-center justify-center text-gray-500 font-bold text-3xl">
                        Oops! You have not ordered products anything yet
                      </h1>
                    )}

                    {/* <!-- More products... --> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      onClick={()=> handleOrderModal()} className=" flex items-center justify-center mb-5">
            <button className="btn w-40 bg-red-500 border-none"><BiCartAlt size={20}/>{" "} Order Cancel</button>
        </div>
        {orderProducts.length > 0 && (
            <OrderCloseModal
              title={"Order Cancel"}
              onShow={onShow}
              handleClose={handleClose}
              products={orderProducts}
            />
          )}
    </>
  );
};

export default OrderDetails;
