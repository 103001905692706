import React from 'react';
import ProductCarousel from '../Carousel/ProductCarousel';
// import './FeaturedProduct.css';


const FeaturedProduct = () => {
    return (
        <div className='py-7 featuredProduct'>
            <h1 className='text-3xl text-center mb-5'>Featured Products </h1>
            <ProductCarousel></ProductCarousel>
        </div>
    );
};

export default FeaturedProduct;