import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
// import Reducer from '../src/Component/Common/Cart/Reducer/Reducer'
// import {createStore} from 'redux';
import { Provider } from 'react-redux'
import Store from './Store/Store.js'
import ScrollToTop from './Utlity/ScrollToTop'
// const store=createStore(Reducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <React.StrictMode>
        {/* <ScrollToTop /> */}
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
