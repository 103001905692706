import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import herobgLeft from '../../../Assets/hero-bg.jpg'
// import { setHeaderTitle } from '../../Redux/headerSlicer'

function HeroInfoPage() {
  const navigate = useNavigate()
  const [heading, setHeading] = useState()
  //   const [prevHeading, setPrevHeading] = useState(heading)
  //   useEffect(() => {
  //     setHeading(getTitile())
  //   }, [])

  const dispatch = useDispatch()

  const redirect = (url, title) => {
    // console.log(url, title)
    navigate(url)
    setHeading(title)
  }

  useEffect(() => {
    document.title = `${heading}`

    // dispatch(setHeaderTitle('Terms of Use'))
  }, [heading])

  return (
    <div
      className='hero  bg-base-100 rounded-md-lg '
      style={{ backgroundImage: `url(${herobgLeft})`, marginTop: `-3px` }}
    >
      <div className='hero-content flex-col'>
        {/* <img src="https://api.lorem.space/image/movie?w=260&h=400" className="max-w-sm rounded-lg shadow-2xl" /> */}
        <div className='py-10 md:pt-8 pb-4'>
          <h1 className='text-3xl font-bold align-left pb-5'>{heading}</h1>
        </div>
        <ul className='hidden md:flex'>
          <li className='mx-2 cursor-pointer'>
            <Link
              to={'/about-us'}
              className='hover:text-red-400'
              onClick={() => setHeading('About Us')}
            >
              About Us
            </Link>
          </li>
          <li className='mx-2 cursor-pointer'>
            <Link
              to={'/contact-us'}
              onClick={() => setHeading('Contact Us')}
              className='hover:text-red-400'
            >
              Contact Us
            </Link>
          </li>
          <li className='mx-2 cursor-pointer'>
            <Link
              to={'/career'}
              className='hover:text-red-400'
              onClick={() => setHeading('Career')}
            >
              Career
            </Link>
          </li>
          <li className='mx-2 cursor-pointer'>
            <Link
              to={'/terms-of-use'}
              onClick={() => setHeading('Terms of Use')}
              className='hover:text-red-400'
            >
              Terms of Use
            </Link>
          </li>
          {/* <Link to={'/privacy-policy/?title="Privacy Policy"'}> */}
          <li
            className='mx-2 cursor-pointer'
            onClick={() => redirect('/privacy-policy', 'Privacy Policy')}
          >
            {/* <a className='hover:text-red-400' onClick={() => setHeading('')}> */}
            Privacy Policy
            {/* </a> */}
          </li>
          {/* </Link> */}
          {/* <Link to={'/privacy-policy/?title="Privacy Policy"'}> */}
          <li
            className='mx-2 cursor-pointer'
            onClick={() => redirect('/cookie-policy', 'Cookie Policy')}
          >
            {/* <a className='hover:text-red-400' onClick={() => setHeading('')}> */}
            Cookie Policy
            {/* </a> */}
          </li>
          {/* </Link> */}
          {/* <Link to={'/cookie-policy'}>
            <li className='mx-2 cursor-pointer'>
              <a
                className='hover:text-red-400'
                onClick={() => setHeading('Cookie Policy')}
              >
                Cookie Policy
              </a>
            </li>
          </Link> */}
        </ul>
      </div>
    </div>
  )
}

export default HeroInfoPage
