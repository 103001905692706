import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import BASE_URL from "../Utlity/Base_url";

// console.log(BASE_URL)

export const CategoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    categoryByGroup: builder.query({
      query: ({ group }) => `ecom/category/group/${group}`,
      providesTags: ["Category"],
    }),
    categoryByMc: builder.query({
      query: ({ mc }) => `ecom/category/master/${mc}`,
      providesTags: ["Category"],
    }),
  }),
});
export const { useCategoryByGroupQuery, useCategoryByMcQuery } = CategoryApi;

export default CategoryApi;
