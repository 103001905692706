import React from "react";
import { BsMegaphone } from "react-icons/bs";
// import { IoFastFoodOutline } from "react-icons/io5";
// import { BiFoodMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import sales from "../../../icons/sales.png";
import popular from "../../../icons/popular.png";
import food from "../../../icons/food.png";
import toothBrush from "../../../icons/tooth-brush.png";
import shampoo from "../../../icons/shampoo.png";
import hygienic from "../../../icons/hygienic.png";
import babyCare from "../../../icons/babyCare.png";
import cleaning from "../../../icons/cleaning.png";
import kitchen from "../../../icons/kitchen.png";
import office from "../../../icons/office.png";
import toy from "../../../icons/toy.png";
import lifestyle from "../../../icons/lifestyle.png";

const SideBar = () => {
  return (
    <div
      className="sticky flex flex-col top-0  bg-[#F2F2F2] z-10 h-[100vh] sm:max-h-full"
      // style={{ marginTop: `-4px` }}
    >
      <ul className="menu flex-none bg-[#F2F2F2] p-2">
        <li>
          <Link to="/offer" className="py-0 hover:bg-transparent">
            <BsMegaphone />
            Offers
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/popular-products">
            <img src={popular} className="w-5" alt="" /> Popular
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/flash-sale">
            <img src={sales} className="w-5" alt="" /> Flash Sales
          </Link>
        </li>
        {/* <li>
          <Link to="/foods" className="py-0 hover:bg-transparent">
            <IoFastFoodOutline />
            Food Aid Hello
          </Link>
        </li>
        <li>
          <Link to="/recipes" className="py-0 hover:bg-transparent">
            <BiFoodMenu />
            Recipes
          </Link>
        </li> */}
      </ul>
      <hr></hr>
      <ul className="menu grow bg-[#FFFFFF] p-2 " style={{ height: `100%` }}>
        {/* <li>
          <Link className='py-1' to='/popular-products'>
            <img src={popular} className='w-5' alt='' /> Popular
          </Link>
        </li>
        <li>
          <Link className='py-1' to='/'>
            <img src={sales} className='w-5' alt='' /> Flash Sales
          </Link>
        </li> */}
        <li>
          <b>Top Categories</b>
        </li>
        <li>
          <Link className="py-1" to="/category/group/food">
            <img src={food} className="w-5" alt="" /> Food
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/personalCare">
            <img src={toothBrush} className="w-5" alt="personalCare" /> Personal
            Care{" "}
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/hygiene">
            <img src={hygienic} className="w-5" alt="" /> Hygiene
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/beauty">
            <img src={shampoo} className="w-5" alt="" /> Beauty & Health
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/babyCare">
            <img src={babyCare} className="w-5" alt="" /> Baby Care
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/clean">
            <img src={cleaning} className="w-5" alt="" /> Cleaning Supplies
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/home">
            <img src={kitchen} className="w-5" alt="" /> Home & Kitchen
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/stationeries">
            <img src={office} className="w-5" alt="" /> Stationery & Office
          </Link>
        </li>
        <li>
          <Link className="py-1" to="/category/group/toys">
            <img src={toy} className="w-5" alt="" /> Toys & Sports
          </Link>
        </li>
        {/* <li><Link className="py-1" to="/"><img src={sales} className="w-5" alt="" /> Vehicle Essentials</Link></li> */}
        <li>
          <Link className="py-1" to="/category/group/lifeStyle">
            <img src={lifestyle} className="w-5" alt="" /> Fashion & Lifestyle
          </Link>
        </li>

        {/* <li tabindex="0">
                    <span  className='py-0'>Parent</span>
                    <ul className="p-2 bg-base-100">
                        <li><Link className='py-0' to="/">Submenu 1</Link></li>
                        <li><Link className='py-0' to="/">Submenu 2</Link></li>
                        <li><Link className='py-0' to="/">Submenu 3</Link></li>
                    </ul>
                </li> */}
        {/* <li><Link className='py-1' to="/">Item 3</Link></li> */}
      </ul>
    </div>
  );
};

export default SideBar;
