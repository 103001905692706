import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Photo from "../../../Assets/category.jpg";
import BASE_URL from "../../../Utlity/Base_url";
import PHOTO_URL from "../../../Utlity/Photo_url";

const McCategoryLoop = ({ category }) => {
  // const photo_url = process.env.REACT_APP_PHOTO_URL || "http://localhost:5001"
  // console.log(photo_url)
  const [dataPhoto, setDataPhoto] = useState(Photo);
  useEffect(() => {
    if (category.photo !== undefined) {
      if (category.photo !== "") {
        if (category?.photo !== "photo.jpg") {
          setDataPhoto(`${PHOTO_URL}${category.photo}`);
        }
      }
    }
  }, [category]);

  console.log(category);
  console.log("dataPhoto", dataPhoto);

  console.log(category.photo);
  const handleImageError = () => {
    setDataPhoto(Photo);
  };
  return (
    <Link
      to={`/category/mc/${category.mcId}`}
      className='hover:color-["tomato"]'
    >
      <div className="p-3 max-[640px]:py-3 max-[640px]:px-0 max-w-lg w-full mx-auto bg-white rounded-xl hover:shadow-md shadow-lg flex flex-col xl:flex-row items-center space-x-2 max-[640px]:space-x-1 text-wrap">
        <div className="shrink-0">
          <img
            onError={handleImageError}
            className="w-14 h-14 rounded-full object-cover float-left "
            src={dataPhoto}
            alt={category.name}
          />
        </div>
        <div>
          <div className="max-[640px]:text-xs text-sm   font-medium text-['tomato'] break-all">
            {category.name}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default McCategoryLoop;
