import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  accessToken: null,
  isLogin: false,
  userId: "",
  userName: "",
  point: 0,
  phone: "",
  type: "",
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      return {
        ...state,
        isLogin: true,
        accessToken: action.payload.accessToken,
        userId: action.payload.userId,
        userName: action.payload.userName,
        point: action.payload.point,
        phone: action.payload.phone,
        // isLogin: action.payload.accessToken !== null && true,
      };
    },
    setUserData: (state, action) => {
      return {
        ...state,
        userId: action.payload.userId,
        userName: action.payload.userName,
        point: action.payload.point,
        phone: action.payload.phone,
      };
    },
    setAccessToken: (state, action) => {
      // console.log(action.payload);

      return {
        ...state,
        accessToken: action.payload,
        isLogin: true,
      };
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    resetAuth: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, setAccessToken, setUserData, resetAuth, setIsLogin } =
  authSlice.actions;

export default authSlice.reducer;
